import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework';

import colors from 'vuetify/lib/util/colors'
import '@mdi/font/css/materialdesignicons.min.css'

import { customThemes } from './theme';
  
Vue.use(Vuetify);

export default new Vuetify({
    iconfont: 'mdi',
    theme: {
        themes: customThemes,
        dark: false
    }
});
