<template lang="html">
  <v-expansion-panel>
    <v-expansion-panel-header>
      <template v-slot:default="{ open }">
        <v-subheader class="subtitle-1 text-uppercase primary--text" v-if="open">Descriptif technique</v-subheader>
        <v-subheader class="subtitle-1 text-uppercase" v-else>Descriptif technique</v-subheader>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <div class="row">
        <div class="col-6 col-md-2">
          <p class="text-right">Aspect :</p>
        </div>
        <div class="col-6 col-md-2">
          <p class="value tertiary">{{offer.S47}}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-6 col-md-2">
          <p class="text-right">Surface de parcelle:</p>
        </div>
        <div class="col-6 col-md-2">
          <p class="value tertiary text-right">{{offer.F15}} m<sup>2</sup></p>
        </div>
        <div class="col-6 col-md-2">
          <p class="text-right">C.O.S :</p>
        </div>
        <div class="col-6 col-md-2">
          <p class="value tertiary text-right">{{offer.F14}} %</p>
        </div>
      </div>
      <div class="row">
        <div class="col-6 col-md-2">
          <p class="text-right">Façade :</p>
        </div>
        <div class="col-6 col-md-2">
          <p class="value tertiary text-right">{{offer.F12}} m</p>
        </div>
        <div class="col-6 col-md-2">
          <p class="text-right">Profondeur:</p>
        </div>
        <div class="col-6 col-md-2">
          <p class="value tertiary text-right">{{offer.F16}} m</p>
        </div>
        <div class="col-6 col-md-2">
          <p class="text-right">Hauteur max :</p>
        </div>
        <div class="col-6 col-md-2">
          <p class="value tertiary text-right">{{offer.F17}} m</p>
        </div>
      </div>
      <div class="row align-center justify-center">
        <div class="ma-4">
          <v-icon class="success--text" v-if="offer.B6 > 0">mdi-checkbox-marked-outline</v-icon>
          <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
          Clôturé
        </div>
        <div class="ma-4">
          <v-icon class="success--text" v-if="offer.IsAscenseur > 0">mdi-checkbox-marked-outline</v-icon>
          <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
          Lotissement
        </div>
        <div class="ma-4">
          <v-icon class="success--text" v-if="offer.B2 > 0">mdi-checkbox-marked-outline</v-icon>
          <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
          Viabilité
        </div>
        <div class="ma-4">
          <v-icon class="success--text" v-if="offer.B5 > 0">mdi-checkbox-marked-outline</v-icon>
          <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
          Alignement
        </div>
        <div class="ma-4">
          <v-icon class="sucess--text" v-if="offer.IsInterphone > 0">mdi-checkbox-marked-outline</v-icon>
          <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
          Gaz
        </div>
        <div class="ma-4">
          <v-icon class="sucess--text" v-if="offer.IsDigicode > 0">mdi-checkbox-marked-outline</v-icon>
          <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
          Assainissement
        </div>
      </div>
      <div class="row">
         <v-subheader class="text-uppercase text-center">Description du terrain</v-subheader>
          <div class="pl-3 text text-left text-uppercase tertiary">
            <br/>
            {{ offer.NotesInternes }}
            <br/>
          </div> 
      </div>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
export default {
  props: ['offer'],
  data () {
    return {
      category: ['Appartement', 'Maison', 'Local', 'Parking'],
      EtatInterieur: ['', 'A restaurer', 'Bon', 'Moyen', 'Passable']
    }
  }
}
</script>
