<template lang="html">
    <v-app-bar app  v-if="connected">
        <v-app-bar-nav-icon class="hidden-md-and-up" @click.stop="setDrawer" ></v-app-bar-nav-icon>
        <div class="d-flex align-center">
            <v-img
            alt="Vuetify Logo"
            class="shrink mr-2"
            contain
            src="@/assets/img/logo-small.png"
            transition="scale-transition"
            />
            <v-toolbar-title>immorep</v-toolbar-title>
        </div>
        <v-spacer></v-spacer>
        <div v-if="connected" class="hidden-sm-and-down">
            <v-btn class="ma-2" text color="secondary" @click.prevent="_callFunction(item.ref)" v-for="item in items" :key="item.title">
                <v-icon left>{{ item.icon }}</v-icon> {{ item.title }}
            </v-btn>
        </div>
        </v-app-bar>
        
    <!-- login -->
    <v-app-bar app v-else>
        <div class="d-flex align-center">
            <v-img
            alt="Vuetify Logo"
            class="shrink mr-2"
            contain
            src="@/assets/img/logo-small.png"
            transition="scale-transition"
            />
            <v-toolbar-title>immorep</v-toolbar-title>
        </div>
    </v-app-bar>
</template>
<script>
import { mapActions } from 'vuex'
import menu from './menu'

export default {
    props: {
        connected: {
            type: Boolean,
            default: false
        }
    },
    mixins:[menu],
    methods:{
        ...mapActions([
            'logout',
            'setDrawer'
        ])
    }
}
</script>