<template lang="html">
  <v-tab-item value="descriptif-technique">
    <div class="row align-center justify-center">
      <v-checkbox name="B5" v-model.trim="offer.B5" label="Bureau" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
      <v-checkbox name="B6" v-model.trim="offer.B6" label="Commerce" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
      <v-checkbox name="B7" v-model.trim="offer.B7" label="Activité" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
      <v-checkbox name="B8" v-model.trim="offer.B8" label="Professionnel" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
    </div>
    <div class="row align-center justify-center">
      <div class="col col-md-2 col-sm-4 col-6">
        <lib-v-number id="F7" label="Surface totale" v-model="offer.F12" symbol="m2"></lib-v-number>
      </div>
      <div class="col col-md-2 col-sm-4 col-6">
        <lib-v-number id="F15" label="Surface mini" v-model="offer.F15" symbol="m2"></lib-v-number>
      </div>
      <div class="col col-md-2 col-sm-4 col-6">
        <lib-v-number id="F17" label="Longueur vitrine" v-model="offer.F17" symbol="m"></lib-v-number>
      </div>
      <div class="col col-md-2 col-sm-4 col-6">
        <v-text-field label="Type chauffage" filled v-model="offer.TypeChauffage"></v-text-field>
      </div>
      <div class="col col-md-2 col-sm-4 col-6">
        <v-text-field label="Nature chauffage" filled v-model="offer.NatureChauffage"></v-text-field>
      </div>
      <div class="col col-md-2 col-sm-4 col-6">
        <v-text-field label="Mode chauffage" filled v-model="offer.ModeChauffage"></v-text-field>
      </div>
    </div>
    <div class="row align-center justify-center">
      <div class="col col-md-2 col-sm-4 col-6">
        <lib-v-number icon="/" label="Etage" v-model="offer.Etage" ></lib-v-number>
      </div>
      <div class="col col-md-2 col-sm-4 col-6">
        <lib-v-number label="Nomre niveaux" v-model="offer.NombreNiveaux" ></lib-v-number>
      </div>
      <div class="col col-md-2 col-sm-4 col-6">
        <lib-v-number label="Box/Garages" v-model="offer.I12" ></lib-v-number>
      </div>
      <div class="col col-md-2 col-sm-4 col-6">
        <lib-v-number label="Park. intérieurs" v-model="offer.NombrePkgInter" ></lib-v-number>
      </div>
      <div class="col col-md-2 col-sm-4 col-6">
        <lib-v-number label="Park. extérieurs" v-model="offer.NombrePkgExter" ></lib-v-number>
      </div>
    </div>
    <div class="row align-center justify-center">
      <v-checkbox name="B20" v-model.trim="offer.B20" label="Climatisation" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
      <v-checkbox name="IsAscenseur" v-model.trim="offer.IsAscenseur" label="Ascenseur" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
      <v-checkbox name="B15" v-model.trim="offer.B15" label="Télésurveillance" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
      <v-checkbox name="IsAccesHandicape" v-model.trim="offer.IsAccesHandicape" label="Accès handicapé" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
      <v-checkbox name="B14" v-model.trim="offer.B14" label="Monte charge" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
      <v-checkbox name="WC" v-model.trim="offer.B16" label="WC" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
      <v-checkbox name="Cuisine" v-model.trim="offer.IsInterphone" label="Cuisine" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
      <v-checkbox name="Douche" v-model.trim="offer.B17" label="Douche" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
      <v-checkbox name="Cave" v-model.trim="offer.IsDigicode" label="Cave" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
      <v-checkbox name="Sous-sol" v-model.trim="offer.B18" label="Sous-sol relié" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
      <v-checkbox name="Rideau de faer" v-model.trim="offer.B19" label="Rideau de fer manuel" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
      <v-checkbox name="Rideau de fer" v-model.trim="offer.B21" label="Rideau de fer électrique" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
    </div>
    <div class="row">
      <v-subheader class="text-uppercase subheader--text">Description du local</v-subheader>
    </div>
    <div class="row">
      <div class="col col-12" >
        <v-textarea
          v-model="offer.NotesInternes" 
          placeholder="Description du local ..."
          auto-grow
          filled
          rows="10"
        ></v-textarea>
      </div> 
    </div>
  </v-tab-item>
</template>

<script>

export default {
  props: ['offer']
}
</script>
