<template lang="html">
  <v-expansion-panel>
    <v-expansion-panel-header>
      <template v-slot:default="{ open }">
        <v-subheader class="subtitle-1 text-uppercase primary--text" v-if="open">Informations agence</v-subheader>
        <v-subheader class="subtitle-1 text-uppercase" v-else>Informations agence</v-subheader>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-container>
        <div class="row">
          <v-subheader class="text-uppercase subheader--text">Informations bailleur</v-subheader>
          <div class="col-4 col-md-2">
            <p  class="text-right">Bailleur :</p>
          </div>
          <div class="col-8 col-md-4">
            <p class="value tertiary">{{offer.NomProprietaire}}</p>
          </div>
          <div class="col-4 col-md-2">
            <p  class="text-right">Téléphone :</p>
          </div>
          <div class="col-8 col-md-4">
            <p class="value tertiary">{{offer.TelBailleur}}</p>
          </div>
          <div class="col-4 col-md-2">
            <p  class="text-right">Email :</p>
          </div>
          <div class="col-8 col-md-6">
            <p class="value tertiary">{{offer.EmailBailleur}}</p>
          </div>
        </div>
        <div class="row" align="center" justify="center" v-if="offer.TypeBien === '11' || offer.TypeBien === '12'">
          <div class="ma-4">
            <v-icon class="success--text" v-if="offer.I28 === 0">mdi-radiobox-marked</v-icon>
            <v-icon v-else>mdi-radiobox-blank</v-icon>
            Nouvelle gestion
          </div>
          <div class="ma-4">
            <v-icon class="success--text" v-if="offer.I28 === 1">mdi-radiobox-marked</v-icon>
            <v-icon v-else>mdi-radiobox-blank</v-icon>
            Bien en gestion
          </div>
          <div class="ma-4">
            <v-icon class="success--text" v-if="offer.I28 === 2">mdi-radiobox-marked</v-icon>
            <v-icon v-else>mdi-radiobox-blank</v-icon>
            Location sèche
          </div>
        </div>
        <div class="row">
          <div class="v-subheader text-uppercase subheader--text theme--light">Notes</div>
          <div class="pl-3 text text-left text-uppercase tertiary">
            <br/>
            {{ offer.NotesProprietaire }}
            <br/>
          </div> 
        </div>
        <div class="row">
          <div class="v-subheader text-uppercase subheader--text theme--light">Consigne de visite</div>
        </div>
        <div class="row">
          <div class="col-5 col-md-2">
            <p  class="text-right">N° Clé :</p>
          </div>
          <div class="col-7 col-md-2">
            <p  class="value tertiary">{{ offer.NombreCles }}</p>
          </div>
          <div class="col-5 col-md-2">
            <p  class="text-right">Digicode :</p>
          </div>
          <div class="col-7 col-md-2">
            <p  class="value tertiary">{{ offer.Digicode }}</p>
          </div>
          <div class="col-5 col-md-2">
            <p  class="text-right">Accès :</p>
          </div>
          <div class="col-7 col-md-2">
            <p  class="value tertiary">{{ offer.AccesVisite }}</p>
          </div>
        </div>
        <div class="row">
          <div class="v-subheader text-uppercase subheader--text theme--light">Commentaire visite</div>
          <div class="pl-3 text text-left text-uppercase tertiary">
            <br/>
            {{ offer.CommentaireVisite }}
            <br/>
          </div> 
        </div>
      </v-container>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
export default {
  props: ['offer']
}
</script>
