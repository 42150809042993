<template lang="html">
  <v-tab-item value="descriptif-technique">
    <div class="row justify-center align-center">
      <div class="col col-md-2 col-sm-3 col-6">
        <lib-v-number label="Nombre de pièces" v-model="offer.NombrePieces" ></lib-v-number>
      </div>
      <div class="col col-md-2 col-sm-3 col-6">
        <lib-v-number label="Nombre de chambres" v-model="offer.NombreChambres" ></lib-v-number>
      </div>
      <div class="col col-md-2 col-sm-3 col-6">
        <lib-v-number label="Etage" v-model="offer.Etage" ></lib-v-number>
      </div>
      <div class="col col-md-2 col-sm-3 col-6">
        <lib-v-number label="Nombre d'étages" v-model="offer.NombreNiveaux" ></lib-v-number>
      </div>
    </div>
    <div class="row justify-center align-center">
      <div class="col col-md-2 col-sm-3 col-6">
        <lib-v-number id="F7" label="Surface habitable" v-model="offer.F12" symbol="m2"></lib-v-number>
      </div>
      <div class="col col-md-2 col-sm-3 col-6">
        <lib-v-number id="F15" label="Surface jardin" v-model="offer.F15" symbol="m2"></lib-v-number>
      </div>
      <div class="col col-md-2 col-sm-3 col-6">
        <lib-v-number id="F7" label="Surface séjour" v-model="offer.F16" symbol="m2"></lib-v-number>
      </div>
    </div>
    <div class="row justify-center align-center">
      <div class="col col-md-2 col-4">
        <v-text-field label="Type chauffage" filled v-model="offer.TypeChauffage"></v-text-field>
      </div>
      <div class="col col-md-2 col-4">
        <v-text-field label="Nature chauffage" filled v-model="offer.NatureChauffage"></v-text-field>
      </div>
      <div class="col col-md-2 col-4">
        <v-text-field label="Mode chauffage" filled v-model="offer.ModeChauffage"></v-text-field>
      </div>
    </div>
    <div class="row justify-center align-center">
      <div class="col col-md-offset-1 col-sm-2">
        <v-checkbox name="B15" v-model.trim="offer.B15" label="Meublé" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
      </div>
      <div class="col col-md-2 col-sm-2">
        <v-text-field label="Eau chaude" filled v-model="offer.S48"></v-text-field>
      </div>
      <div class="col col-lg-3 col-md-3 col-sm-3 col-3">
        <v-text-field label="Etat intérieur" filled v-model="offer.EtatInterieur"></v-text-field>
      </div>
    </div>
    <div class="row justify-center align-center">
      <div class="col col-md-2 col-sm-4 col-4">
        <lib-v-number label="WC" filled v-model.number="offer.I7"></lib-v-number>
      </div>
      <div class="col col-md-2 col-sm-4 col-4">
        <lib-v-number label="Park. int." v-model.number="offer.NombrePkgInter" ></lib-v-number>
      </div>
      <div class="col col-md-2 col-sm-4 col-4">
        <lib-v-number label="Caves" v-model.number="offer.NombreCaves" ></lib-v-number>
      </div>
    </div>
    <div class="row justify-center align-center">
      <div class="col col-md-2 col-4">
        <lib-v-number label="Salles de bains" filled v-model.number="offer.I8"></lib-v-number>
      </div>
      <div class="col col-md-2 col-4">
        <lib-v-number label="Park. ext." v-model.number="offer.NombrePkgExter" ></lib-v-number>
      </div>
      <div class="col col-md-2 col-4">
        <lib-v-number label="Balcons" v-model.number="offer.NombreBalcons" ></lib-v-number>
      </div>
    </div>
    <div class="row justify-center align-center">
      <div class="col col-md-2 col-4">
        <lib-v-number label="Salles d'eau" filled v-model="offer.I9"></lib-v-number>
      </div>
      <div class="col col-md-2 col-4">
        <lib-v-number label="Box" v-model="offer.I12" ></lib-v-number>
      </div>
      <div class="col col-md-2 col-4">
        <lib-v-number label="Terrasses" v-model="offer.NombreTerrasses" ></lib-v-number>
      </div>
    </div>
    <div class="row">
      <v-subheader class="text-uppercase subheader--text">Description de l'immeuble</v-subheader>
    </div>
    <div class="row">
      <div class="col col-12" >
        <v-textarea
          v-model="offer.NotesInternes" 
          placeholder="Description de l'immeuble ..."
          auto-grow
          filled
          rows="10"
        ></v-textarea>
      </div> 
    </div>
  </v-tab-item>
</template>

<script>
export default {
  props: ['offer']
}
</script>
