<template lang="html">
  <v-expansion-panel>
      <v-expansion-panel-header>
        <template v-slot:default="{ open }">
          <v-subheader class="subtitle-1 text-uppercase primary--text" v-if="open">Descriptif technique</v-subheader>
          <v-subheader class="subtitle-1 text-uppercase" v-else>Descriptif technique</v-subheader>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <div class="row">
          <div class="col-6 col-md-2">
            <p class="text-right">Nombre de pièces:</p>
          </div>
          <div class="col-6 col-md-2">
            <p class="value tertiary text-right">{{offer.NombrePieces}}</p>
          </div>
          <div class="col-6 col-md-2">
            <p class="text-right">Nombre de chambres:</p>
          </div>
          <div class="col-6 col-md-2">
            <p class="value tertiary text-right">{{offer.NombreChambres}}</p>
          </div>
          <div class="col-6 col-md-2">
            <p class="text-right">Chambre(s) service:</p>
          </div>
          <div class="col-6 col-md-2">
            <p class="value tertiary text-right">{{offer.I25}} </p>
          </div>
        </div>
        <div class="row">
          <div class="col-6 col-md-2">
            <p class="text-right">Etage :</p>
          </div>
          <div class="col-6 col-md-2">
            <p class="value tertiary text-right">{{offer.Etage}} <span v-if="offer.S57 > ''">({{ offer.S57}})</span></p>
          </div>
          <div class="col-6 col-md-2">
            <p class="text-right">Nombre de niveaux:</p>
          </div>
          <div class="col-6 col-md-2">
            <p class="value tertiary text-right">{{offer.I6}} </p>
          </div>
        </div>
        <div class="row">
          <div class="col-6 col-md-2">
            <p class="text-right">Surface habitable:</p>
          </div>
          <div class="col-6 col-md-2">
            <p class="value tertiary text-right">{{offer.F12}} m<sup>2</sup></p>
          </div>
          <div class="col-6 col-md-2">
            <p class="text-right">Surf. Carrez:</p>
          </div>
          <div class="col-6 col-md-2">
            <p class="value tertiary text-right">{{offer.F14}} m<sup>2</sup></p>
          </div>
          <div class="col-6 col-md-2">
            <p class="text-right">Surf. jardin:</p>
          </div>
          <div class="col-6 col-md-2">
            <p class="value tertiary text-right">{{offer.F15}} m<sup>2</sup></p>
          </div>
          <div class="col-6 col-md-2">
            <p class="text-right">Surf. séjour:</p>
          </div>
          <div class="col-6 col-md-2">
            <p class="value tertiary text-right">{{offer.F16}} m<sup>2</sup></p>
          </div>
        </div>
        <div class="row">
          <div class="col-6 col-md-2">
            <p class="text-right">Type chauffage:</p>
          </div>
          <div class="col-6 col-md-2">
            <p class="value tertiary">{{offer.TypeChauffage}}</p>
          </div>
          <div class="col-6 col-md-2">
            <p class="text-right">Nature chauff.:</p>
          </div>
          <div class="col-6 col-md-2">
            <p class="value tertiary">{{offer.NatureChauffage}}</p>
          </div>
          <div class="col-6 col-md-2">
            <p class="text-right">Mode chauff:</p>
          </div>
          <div class="col-6 col-md-2">
            <p class="value tertiary">{{offer.ModeChauffage}}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-6 col-md-2">
            <p class="text-right">Eau chaude:</p>
          </div>
          <div class="col-6 col-md-2">
            <p class="value tertiary">{{offer.S48}}</p>
          </div>
          <div class="col-6 col-md-2">
            <p class="text-right">Etat intérieur:</p>
          </div>
          <div class="col-6 col-md-2">
            <p class="value tertiary">{{offer.EtatInterieur}}</p>
          </div>
          <div class="col-6 col-md-2">
            <p class="text-right">Type cuisine:</p>
          </div>
          <div class="col-6 col-md-2">
            <p class="value tertiary">{{offer.TypeCuisine}}</p>
          </div>

        </div>
        <div class="row">
          <div class="col-6 col-md-3">
            <p class="text-right">WC:</p>
          </div>
          <div class="col-6 col-md-1">
            <p class="value tertiary text-right">{{offer.I7}}</p>
          </div>
          <div class="col-6 col-md-2">
            <p class="text-right">Park. int.:</p>
          </div>
          <div class="col-6 col-md-1">
            <p class="value tertiary text-right">{{offer.NombrePkgInter}}</p>
          </div>
          <div class="col-6 col-md-2">
            <p class="text-right">Caves:</p>
          </div>
          <div class="col-6 col-md-1">
            <p class="value tertiary text-right">{{offer.NombreCaves}}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-6 col-md-3">
            <p class="text-right">Salles de bains:</p>
          </div>
          <div class="col-6 col-md-1">
            <p class="value tertiary text-right">{{offer.I8}}</p>
          </div>
          <div class="col-6 col-md-2">
            <p class="text-right">Park. ext.:</p>
          </div>
          <div class="col-6 col-md-1">
            <p class="value tertiary text-right">{{offer.NombrePkgExter}}</p>
          </div>
          <div class="col-6 col-md-2">
            <p class="text-right">Balcons:</p>
          </div>
          <div class="col-6 col-md-1">
            <p class="value tertiary text-right">{{offer.NombreBalcons}}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-6 col-md-3">
            <p class="text-right">Salles d'eau:</p>
          </div>
          <div class="col-6 col-md-1">
            <p class="value tertiary text-right">{{offer.I9}}</p>
          </div>
          <div class="col-6 col-md-2">
            <p class="text-right">Box :</p>
          </div>
          <div class="col-6 col-md-1">
            <p class="value tertiary text-right">{{offer.I12}}</p>
          </div>
          <div class="col-6 col-md-2">
            <p class="text-right">Terrasses:</p>
          </div>
          <div class="col-6 col-md-1">
            <p class="value tertiary text-right">{{offer.NombreTerrasses}}</p>
          </div>
        </div>
        <div class="row align-center justify-center">
          <div class="ma-4">
            <v-icon class="success--text" v-if="offer.B15 > 0">mdi-checkbox-marked-outline</v-icon>
            <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
            Meublé
          </div>
          <div class="ma-4">
            <v-icon class="success--text" v-if="offer.B17 > 0">mdi-checkbox-marked-outline</v-icon>
            <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
            Prestige
          </div>
        </div>
        <div class="row align-center justify-center">
          <div class="ma-4">
            <v-icon class="success--text" v-if="offer.B21 > 0">mdi-checkbox-marked-outline</v-icon>
            <v-icon class="material-icons" v-else>mdi-checkbox-blank-outline</v-icon>
            Installation gaz (+15 ans)
          </div>
          <div class="ma-4">
            <v-icon class="success--text" v-if="offer.B35 > 0">mdi-checkbox-marked-outline</v-icon>
            <v-icon class="material-icons" v-else>mdi-checkbox-blank-outline</v-icon>
            Installation électrique (+15 ans)
          </div>
        </div>
        <div class="row align-center justify-center">
          <div class="ma-4">
            <v-icon class="success--text" v-if="offer.IsAscenseur > 0">mdi-checkbox-marked-outline</v-icon>
            <v-icon class="material-icons" v-else>mdi-checkbox-blank-outline</v-icon>
            Ascenseur
          </div>
          <div class="ma-4">
            <v-icon class="success--text" v-if="offer.IsInterphone > 0">mdi-checkbox-marked-outline</v-icon>
            <v-icon class="material-icons" v-else>mdi-checkbox-blank-outline</v-icon>
            Interphone
          </div>
          <div class="ma-4">
            <v-icon class="success--text" v-if="offer.IsDigicode > 0">mdi-checkbox-marked-outline</v-icon>
            <v-icon class="material-icons" v-else>mdi-checkbox-blank-outline</v-icon>
            Digicode
          </div>
          <div class="ma-4">
            <v-icon class="success--text" v-if="offer.B14 > 0">mdi-checkbox-marked-outline</v-icon>
            <v-icon class="material-icons" v-else>mdi-checkbox-blank-outline</v-icon>
            Piscine
          </div>
          <div class="ma-4">
            <v-icon class="success--text" v-if="offer.IsAccesHandicape > 0">mdi-checkbox-marked-outline</v-icon>
            <v-icon class="material-icons" v-else>mdi-checkbox-blank-outline</v-icon>
            Accès handicapés
          </div>
          <div class="ma-4">
            <v-icon class="success--text" v-if="offer.B16 > 0">mdi-checkbox-marked-outline</v-icon>
            <v-icon class="material-icons" v-else>mdi-checkbox-blank-outline</v-icon>
            Gardien
          </div>
        </div>
        <div class="row">
          <v-subheader class="text-uppercase text-center">Description d'immeuble</v-subheader>
          <div class="pl-3 text text-left text-uppercase tertiary">
            <br/>
            {{ offer.NotesInternes }}
            <br/>
          </div> 
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
export default {
  props: ['offer'],
  data () {
    return {
      EtatInterieur: ['', 'A restaurer', 'Bon', 'Moyen', 'Passable']
    }
  }
}
</script>
