<template>
  <div class="mt-5">
    <div class="row align-center justify-start flex-nowrap">
      <div class="picto col-2">
        <img src="@/assets/img/diags/DPE.jpg" alt="" />
        <span>D.P.E</span>          
      </div>
      <div class="col-2">
        <v-icon left color="success" v-if="offer.B31 === 0">mdi-radiobox-marked</v-icon>
        <v-icon left v-else>mdi-radiobox-blank</v-icon>
        DPE Vierge
      </div>
      <div class="col-2">
        <v-icon left color="success" v-if="offer.B31 === 1">mdi-radiobox-marked</v-icon>
        <v-icon left v-else>mdi-radiobox-blank</v-icon>
        Non soumis au DPE
      </div>
      <div class="col-3">
        <v-icon left color="success" v-if="offer.B31 === 2">mdi-radiobox-marked</v-icon>
        <v-icon left v-else>mdi-radiobox-blank</v-icon>
        DPE avant le O1 Juillet 2021
      </div>
      <div class="col-3">
        <v-icon left color="success" v-if="offer.B31 === 3">mdi-radiobox-marked</v-icon>
        <v-icon left v-else>mdi-radiobox-blank</v-icon>
        DPE aprés le O1 Juillet 2021
      </div>
    </div>
    <div class="diagnostic-wrapper">
      <div class="diagnostic">
        <div class="row align-center justify-start flex-nowrap">
          <div class="ml-1 caption">
            <span>Date DPE :</span>
          </div>
          <div class="ml-1">
            <p class="value tertiary">{{offer.DateDiagDPE}}</p>
          </div>
          <div class="ml-1 flex-grow-1">
            <p class="value tertiary">{{offer.PrestaDiagDPE}}</p>
          </div>
        </div>
        <div class="row align-center justify-start flex-nowrap">
          <div class="ml-1 caption">
            <span>Consommation énergie</span>
          </div>
          <div class="ml-1">
            <p class="value tertiary">{{offer.F33}}</p>
          </div>
          <div class="ml-1 caption"><span>kWh EP/m<sup>2</sup> an</span></div>
          <div class="text-center ml-2 mr-2">
            <span :class="offer.EtiquConsommation" class="font-weight-black CE">{{ offer.EtiquConsommation }}</span>
          </div>
          <div class="ml-1 flex-grow-1">
            <p class="value tertiary">{{offer.S83}}</p>
          </div>
        </div>
        <div class="row align-center justify-start flex-nowrap">
          <div class="ml-1 caption">
            <span>Taux émission GES</span>
          </div>
          <div class="ml-1">
            <p class="value tertiary">{{offer.F34}}</p>
          </div>
          <div class="ml-1 caption"><span>kg éqCO2/m<sup>2</sup> an</span></div>
          <div class="text-center ml-2 mr-2">
            <span :class="offer.EtiquEmissionGES" class="font-weight-black GES">{{ offer.EtiquEmissionGES }}</span>
          </div>
          <div class="ml-1 flex-grow-1">
            <p class="value tertiary">{{offer.S84}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['offer']
  }
</script>

<style lang="scss" scoped>

</style>