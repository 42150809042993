import Vue from 'vue'
import api from '@/api'
import { Date } from 'core-js'

const state = {
    listLoaded: false,
    action: 'update',
    offers: [],
    tabIndex: 0,
    Regimes: ['MEUBLE', 'LOI DE 89 HABITATION', 'LOI DE 89 MIXTE', 'LOI DE 48', 'COMMERCIAL', 'PROFESSIONEL', 'LOCATION SAISONNIERE'],
    category: ['Appartement', 'Maison', 'Local', 'Parking'],
    EtatInterieur: ['', 'A restaurer', 'Bon', 'Moyen', 'Passable'],
    //filterData: Vue.prototype.$localStore.getObject('filterData') || {

    filterData: {
        transaction: '',
        typeBiens: [
                { name: 'appartement', caption: 'Appartement', checked: true },
                { name: 'maison', caption: 'Maison', checked: false },
                { name: 'local', caption: 'Local', checked: false },
                { name: 'parking', caption: 'Parking', checked: false },
                { name: 'terrain', caption: 'Terrain', checked: false },
                { name: 'immeuble', caption: 'Immeuble', checked: false },
                { name: 'fdc', caption: 'Fond de C.', checked: false }
        ],
        etatBiens: {
            rents: [
                { name: 'libre', caption: 'Libre', checked: true, value: '0' },
                { name: 'occupe', caption: 'Occupé(e)', checked: false, value: '1' },
                { name: 'loue', caption: 'Loué(e)', checked: false, value: '2' },
                { name: 'option', caption: 'Option', checked: false, value: '3' },
                { name: 'reserve', caption: 'Réservé(e)', checked: false, value: '4' },
                { name: 'suspendu', caption: 'Suspendu(e)', checked: false, value: '5' }
            ],
            sales: [
                { name: 'disponible', 'caption': 'Disponible', checked: true, value: '0' },
                { name: 'offre', 'caption': 'Offre', checked: false, value: '1' },
                { name: 'compromis', 'caption': 'Compromis', checked: false, value: '2' },
                { name: 'suspendu', 'caption': 'Suspendu', checked: false, value: '3' },
                { name: 'estimation', 'caption': 'Estimation', checked: false, value: '4' }
            ]
        },
        NumericFields: [
            { name: 'Price', caption: 'Loyer C.C (min) : ', operator: '$gte', value: 0 },
            { name: 'Price', caption: 'Loyer C.C (max) : ', operator: '$lte', value: 0 }
        ],
        /*NumericFields: {
            rents: [
                { name: 'F7', caption: 'Loyer C.C (min) : ', operator: '$gte', value: 0 },
                { name: 'F7', caption: 'Loyer C.C (max) : ', operator: '$lte', value: 0 }
            ],
            sales: [
                { name: 'F1', caption: 'Loyer C.C (min) : ', operator: '$gte', value: 0 },
                { name: 'F1', caption: 'Loyer C.C (max) : ', operator: '$lte', value: 0 }
            ]
        },
        */
        TextFields: [
            //{ name: 'CodeSite', caption: 'Site : ', value: '01' },
            { name: 'Negociateur', caption: 'Négociateur', value: '' },
            { name: 'CodePostal', caption: 'Code postal : ', value: '' },
            { name: 'Ville', caption: 'Ville', value: '' },
            { name: 'NomProprietaire', caption: 'Bailleur', value: '' },
            { name: 'NomOccupant', caption: 'Occupant', value: '' }
        ],
        LogicFields: [
            { name: 'Siteweb', 'caption': 'Site web', checked: 0, value: "0" },
            { name: 'Seloger', 'caption': 'Se loger', checked: 0, value: "0" },
            { name: 'BienIci', 'caption': 'BienIci', checked: 0, value: "0" },
            { name: 'CoupCoeur', 'caption': 'Coup de coeur', checked: 0, value: "0" }
        ]
    },
    currentOffer: {},
    /* 
    etatsLocation: [
      {state:'Libre', color:'green'},
      {state:'Occupé(e)', color:'pink'},
      {state:'Loué(e)', color:'blue'},
      {state:'Option', color:'purple'},
      {state:'Réservé(e)', color:'orange'},
      {state:'Suspendu(e)', color:'red'}
    ],
    etatsVente: ['Disponible', 'Offre', 'Compromis', 'Suspendu', 'Estimation'], 
    */
    typeBiens: ['', 'Appartement', 'Maison', 'Terrain', 'Immeuble', 'Local', 'FDC', 'Parking', '', '', '', 'Appartement', 'Maison', 'Local', 'Parking'],
    etatBiens: ['rents', 'sales']['', 'Appartement', 'Maison', 'Terrain', 'Immeuble', 'Local', 'FDC', 'Parking', '', '', '', 'Appartement', 'Maison', 'Local', 'Parking'],
    pagination: { total: 0, per_page: 0, current_page: 1, last_page: 20, from: 1, to: 12 },
    orderby: 'F1'
}

// getters
const getters = {
    isListLoaded: (state) => {
        return state.listLoaded
    },
    etatsLocation: (state) => {
        return state.etatsLocation
    },
    etatsVente: (state) => {
        return state.etatsVente
    },
    getTypeBiens: (state) => {
        return state.typeBiens
    },
    getFilterData: (state) => {
        return state.filterData
    },
    regimes: (state) => {
        return state.Regimes
    },
    getCategorie: (state) => {
        return state.category
    },
    getEtatInterieur: (state) => {
        return state.EtatInterieur
    },
    offers: (state) => {
        return state.offers
    },
    getTabIndex: (state) => {
        return state.tabIndex
    },
    pagination: (state) => {
        return state.pagination
    },
    orderby: (state) => {
        return state.orderby
    },
    getCurrentOffer: (state) => {
        return state.currentOffer
    },
    getAction: (state) => {
        return state.action
    }
}

const constructFilterData = (_filterData) => {
        const filterData = {
            transaction: _filterData.transaction,
            typeBiens: [],
            etatBiens: [],
            NumericFields: [],
            TextFields: [],
            LogicFields: []
        }

        _filterData.typeBiens[_filterData.transaction].forEach(typeBien => {
            if (typeBien.checked) {
                filterData.typeBiens.push(typeBien.value)
            }
        })

        _filterData.etatBiens[_filterData.transaction].forEach(etatBien => {
            if (etatBien.checked) {
                filterData.etatBiens.push(etatBien.value)
            }
        })

        _filterData.NumericFields[_filterData.transaction].forEach(item => {
            if (item.value > 0) {
                filterData.NumericFields.push({
                    operator: item.operator,
                    value: item.value
                })
            }
        })

        _filterData.TextFields.forEach(item => {
            if (item.value.length > 0) {
                filterData.TextFields.push({
                    name: item.name,
                    value: item.value
                })
            }
        })

        _filterData.LogicFields.forEach(item => {
            if (item.checked) {
                filterData.LogicFields.push({
                    name: item.name,
                    value: item.value
                })
            }
        })

        return filterData
    }
    // actions
const actions = {
    setTabIndex({ commit }, tabIndex) {
        commit('SET_TAB_INDEX', tabIndex)
    },
    setOrderby({ commit }, orderby) {
        return commit('SET_ORDERBY', orderby)
    },
    setFilterData({ commit }, _filterData) {
        commit('SET_LIST_LOADED', false)
        return commit('SET_FILTER_DATA', _filterData)
    },
    setCurrentOffer({ commit }, offer) {
        commit('CURRENT_OFFER', offer)
    },
    fetchOffers: async({ commit }, offerInfos) => {
        try {
            const response = await api.getOffers(offerInfos)
            commit('GET_OFFERS', response)
            commit('SET_LIST_LOADED', true)
            return response
        } catch (error) {
            return error
        }
    },
    saveOffer: async({ commit }, payload) => {
        let response = null
        try {
            response = await api.saveOffer(payload)
                //commit('UPDATE_OFFER', payload.offer)
            return response
        } catch (error) {
            return error
        }
    },
    getOffer: async({ commit }, payload) => {
        try {
            const response = await api.getOffer(payload)
            commit('CURRENT_OFFER', response)
            return response
        } catch (error) {
            return error
        }
    },
    removeOffer: async({ commit }, payload) => {
        try {
            const response = await api.removeOffer(payload)
            commit('REMOVE_OFFER', payload.offer)
            return response
        } catch (error) {
            return error
        }
    },
    setAction: ({ commit }, _action) => {
        commit('UPDATE_ACTION', _action)
    },
    getTypeBien: async({ commit }, value) => {
        return state.typeBiens[value]
    }
}

// mutations
const mutations = {
    UPDATE_ACTION(state, _action) {
        state.action = _action
    },
    GET_OFFERS(state, response) {
        let data = response
            /* if (data.offers) {
              state.offers = data.offers
            } else {
              state.offers = []
            } */
        state.offers = data
        state.pagination.last_page = Math.ceil(1 * data.total / state.pagination.per_page)
    },
    NEW_OFFER(state, offer) {
        state.offers.push(offer)
    },
    CURRENT_OFFER(state, offer) {
        state.currentOffer = offer
    },
    UPDATE_OFFER(state, offer) {
        const _offers = Array.from(state.offers)
        let index = _offers.indexOf(offer)
        _offers.splice(index, 1, offer)
            // state.offers.splice(index, 1, offer)
        Vue.set(state, 'offers', _offers)
    },
    REMOVE_OFFER(state, offer) {
        let index = state.offers.indexOf(offer)
        state.offers.splice(index, 1)
    },
    SET_TAB_INDEX(state, tabIndex) {
        state.tabIndex = tabIndex
    },
    TAB_OFFER_LIST(state, _state) {
        state.tabOfferList = _state
    },
    TAB_OFFER_DETAILS(state, _state) {
        state.tabOfferDetails = _state
    },
    GET_PAGINATION(state, response) {
        state.pagination.total = response.data.total
    },
    GET_ORDERBY(state) {
        return state.orderby
    },
    SET_ORDERBY(state, orderby) {
        state.orderby = orderby
        return true
    },
    GET_FILTER_DATA(state) {
        return state.filterData
    },
    SET_FILTER_DATA(state, filterData) {
        state.filterData = filterData
        return true
    },
    SET_LIST_LOADED(state, newState) {
        state.listLoaded = newState
        return true
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}