<template lang="html">
  <v-tab-item value="informations-juridiques">
    <div class="row align-center justify-center">
      <div class="col-12 col-md-6 col-lg-4">
        <v-text-field label="Gestionnaire" filled v-model="offer.S29"></v-text-field>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <v-text-field label="Syndic" filled v-model="offer.S26"></v-text-field>
      </div>
    </div>
    <div class="row">
      <v-subheader class="text-uppercase subheader--text">Informations complémentaires</v-subheader>
    </div>
    <div class="row">
      <div class="col col-12" >
        <v-textarea
          v-model="offer.CriteresComplementaires" 
          placeholder="Description de la construction ..."
          auto-grow
          filled
          rows="10"
        ></v-textarea>
      </div> 
    </div>
  </v-tab-item>
</template>

<script>
export default {
  props: ['offer']
}
</script>
