<template>
  <v-card flat class="print-page">
    <div class="ma-auto text-center headline text-uppercase">
      Affiche vitrine
    </div>  
    <v-toolbar flat height="45">
        <v-spacer></v-spacer>
        <div class="row align-center justify-center">
          <div class="ma-4">
            <v-checkbox class="mt-0 pa-0"
              v-model="coupCoeur"
              label="Coup de coeur"
              @change="changeState()"
            ></v-checkbox>
          </div>
          <div class="ma-4">
            <v-checkbox class="mt-0 pa-0"
              v-model="exclusive"
              label="Exclusivité"
              @change="changeState()"
            ></v-checkbox>
          </div>
          <div class="ma-4" v-if="trans=='sales'">
            <v-checkbox class="mt-0 pa-0"
              v-model="vendu"
              label="Vendu"
              @change="changeState()"
            ></v-checkbox>
          </div>
          <!-- <div class="ma-4">
            <v-btn outlined  @click.prevent="html2pdf(model)">
              <v-icon>mdi-file-pdf-outline</v-icon>
              Imprimer en PDF
            </v-btn>
          </div> -->
        </div>
        <v-spacer></v-spacer>
    </v-toolbar>
    <div align="center" justify="center">
      <iframe id="iframe" class="frame"></iframe>
    </div>
        
    
  </v-card>
</template>
<script>
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
//import {API_ROOT} from '@/api/config'
import {mapGetters} from 'vuex'
import RobotoRegular from '@/assets/fonts/roboto/Roboto-Regular.js'
import RobotoBlack from '@/assets/fonts/roboto/Roboto-Black.js'
import { PdfLib } from '@/plugins/pdfLib.js'

const API_ROOT = process.env.VUE_APP_ROOT_API

window.html2canvas = html2canvas

export default {
  name: 'app',
  props:['offer','trans', 'model'],
  data:()=>({
    coupCoeur: false,
    exclusive: false,
    vendu: false,
    pdfLib: null,
    top:  new Map([
      ['A', -185], 
      ['B', -165], 
      ['C', -145], 
      ['D', -94], 
      ['E', -76],
      ['F', -60],  
      ['G', -42] 
    ]),
  }),
  mounted() {
    this.coupCoeur = (this.offer.CoupCoeur > 0)
    this.exclusive = (this.offer.MandatExclusif > 0)
    this.vendu = (this.offer.Etat === "2")
    window.html2canvas = html2canvas
    this.pdfLib = new PdfLib(this.$tools, this.offer) 
    this.html2pdf()
  },
  computed: {
    ...mapGetters([
      'getTypeBiens',
      'getAccount'
    ]),
    eneregieLevel: function () {
      return this.offer.EtiquConsommation.toLowerCase()
    }
  },
  methods: {
    changeState() {
      this.html2pdf()
    },
    async html2pdf() {
      let doc = null
      if (this.model.orientation === "l2") {
        doc = new jsPDF("l", 'pt', this.model.format);
      } else {
        if (this.model.format === "A3") {
          doc = new jsPDF(this.model.orientation, 'pt', [841.89, 1190.55]);
        } else 
          doc = new jsPDF(this.model.orientation, 'pt', this.model.format);
      }
      const myLayout = {
        x: 17,
        y: 17,
        w : doc.internal.pageSize.width - 34,
        h : doc.internal.pageSize.height - 34,
      } 

      //doc.addFont('/static/fonts/roboto/Roboto-Black.ttf', 'Roboto-Black', 'normal')
      //doc.addFont('/static/fonts/roboto/Roboto-Regular.ttf', 'Roboto-Regular', 'normal')
      doc.setFont("Roboto-Black"); // set font

      doc.setFillColor(255,255,255);
      doc.setDrawColor('#eee')
      doc.rect(myLayout.x, myLayout.y, myLayout.w, myLayout.h, 'FD');

      
      // header
      //const slogan = "Le spécialiste de l'est parisien"
      const slogan = this.$localStore.getString('slogan')
      doc.setFontSize(19);
      doc.setFillColor(184,184,184);
      doc.rect(myLayout.x, myLayout.y, myLayout.w, 50.5, 'F');
      // logo
      const logo = await this.$tools.loadImageFromURL(this.$tools.getIdentityImage(this.offer.account_id))
      //console.log('logo:', logo)
      doc.addImage(logo, "JPEG", myLayout.x, myLayout.y, 150, 50);
      // Slogan
      this.pdfLib.writeText(doc, myLayout.x +50, myLayout.y + 30 ,slogan.toUpperCase(), { width: myLayout.w, color: {r:255, g:255, b:255}, align: 'center' });

      
      // title
      // title 1
      // font faces
      doc.setFont("Roboto-Regular");
      doc.setFontSize(22);
      // TransactionText
      let transactionText = 'LOCATION '
      // Prix
      let priceText = `Loyer : ${this.pdfLib.formatPrice(this.offer.Price)} € C.C. - Honoraires : ${this.pdfLib.formatPrice(this.offer.F25)} € TTC`
      if (this.trans === 'sales') {
        transactionText = 'VENTE '
        priceText = `Prix : ${this.pdfLib.formatPrice(this.offer.Price)} €`
      }

      transactionText = (transactionText + this.offer.TypeBien + ' à').toUpperCase()
      //let image0 = `/static/img/noimage-${typeBien.toLowerCase()}.jpg` 
      let image0 = `/static/img/noimage-immorep.jpg` 
      
      //console.log('image0 :', image0)
      this.pdfLib.writeText(doc, myLayout.x, myLayout.y + 75 , transactionText, { width: myLayout.w, color: {r:0, g:0, b:0}, align: 'center' });

      // title 2
      doc.setFont("Roboto-Black"); // set font
      doc.setFontSize(40);
      this.pdfLib.writeText(doc, myLayout.x, myLayout.y + 125 ,this.offer.Ville.toUpperCase(), { width: myLayout.w, color: {r:176, g:58, b:46}, align: 'center' });
      


      doc.setFont("Roboto-Regular"); // set font

      let imagePosX = 0
      let imagePosY = 0

      // A4 Portrait
      if (this.model.format === 'A4' && this.model.orientation === 'p') {
        // A4 Portrait
        
        // Images
        const imageWidth = 450
        const imageHeight = 280
        
        imagePosX = myLayout.x + (myLayout.w - imageWidth) / 2
        imagePosY = 150
        
        let posX = imagePosX
        let posY = imagePosY
        if (this.pdfLib.offerImage(0)) {
          const _image = await this.$tools.loadImageFromURL(this.pdfLib.offerImage(0))
          doc.addImage(_image, "jpeg", posX, posY, imageWidth, imageHeight);
        }

        posY += imageHeight + 10

        // Thumbnails
        const thumbWidth = 86
        const thumbHeight = 86
        let x = 0
        let y = 0
        let inc = 0
        if (this.offer.images) {
          for (var i = 1; i < Math.min(this.offer.images.length, 4); i++) {
            const image = await this.$tools.loadImageFromURL(this.pdfLib.offerImage(i))
            if (doc.getImageProperties(image).fileType === "JPEG"){
              x = posX +  inc *(thumbWidth + 10)
              y = posY
              doc.addImage(image, "jpeg", x, y, thumbWidth, thumbHeight)
              inc += 1
            }
          }
        } 
        
        // annonce
        posX = 25
        posY += thumbHeight + 10
        
        let splitTextWidth = 0
        if (this.pdfLib.isPrintDiag()){
          splitTextWidth = 340
        } else {
          splitTextWidth = 520
        }

        // Dessiner les crochets du rectangle de l'annonce
        doc.setFillColor(255,255,255);
        doc.setDrawColor('#ccc')
        doc.rect(posX , posY, splitTextWidth + 20, 230, 'FD');
        doc.rect(posX-2 , posY + 15, splitTextWidth + 35, 200, 'F');
        doc.rect(posX + 15 , posY-2, splitTextWidth - 10, 234, 'F');

        
        // Texte de l'annonce
        doc.setFontSize(14);
        doc.setFont("Roboto-Black"); // set font
        doc.setTextColor(55, 55, 55);
        var splitTitle = ''
        if (this.offer.TexteAnnonce){
          splitTitle = doc.splitTextToSize(this.offer.TexteAnnonce, splitTextWidth);
        }
        doc.text(posX + 15, posY + 20, splitTitle);
        
        posX += 370
        posY += 10
        
        this.pdfLib.printDiag(doc, posX, posY, 0)
        // print diag

        posY = 810 
        doc.setFontSize(30);
        doc.setFont("Roboto-Black");
        doc.setTextColor(55, 55, 55);
        this.pdfLib.writeText(doc, myLayout.x, posY, priceText, { color: {r:176, g:58, b:46}, align: 'center' });
        if (this.trans === 'sales') {
          posY = 780 
          doc.setFont("Roboto-Regular");
          doc.setFontSize(8);
          this.pdfLib.printInfosComplementaires(doc, myLayout, posY)
        }
      }

      // A4 Paysage
      if (this.model.format === 'A4' && this.model.orientation === 'l') {
        // A4 landscape
        // Images
        let imageWidth = 450
        const imageHeight = 280
        if (this.offer.images){
          if (this.offer.images.length > 0) {
            imagePosX = myLayout.x + 15
            imagePosY = 150
            image0 = await this.$tools.loadImageFromURL(this.pdfLib.offerImage (0))
          }
        } else {
          imagePosX = (myLayout.w - imageWidth) / 2 - 30
          imagePosY = 150
          //doc.rect(imagePosX, imagePosY, imageWidth, imageHeight, 'F');
        } 

        let posX = imagePosX
        let posY = imagePosY
        
        if (!this.pdfLib.isPrintDiag()){
          imageWidth = 550
        }
        doc.addImage(image0, "JPEG", posX, posY, imageWidth, imageHeight);
        posX = imageWidth + 10

        // Thumbnails
        const thumbWidth = 86
        const thumbHeight = 86
        let x = posX + 30
        let y = 0
        let inc = 0
        if (this.offer.images) {
          for (var i = 1; i < Math.min(this.offer.images.length, 4); i++) {
            const image = await this.$tools.loadImageFromURL(this.pdfLib.offerImage(i))
            if (doc.getImageProperties(image).fileType === "JPEG"){
              y = posY +  inc *(thumbHeight + 10)
              doc.addImage(image, "jpeg", x, y, thumbWidth, thumbHeight)
              inc += 1
            }
          }
        }
        
        posX += thumbWidth + 40
        posY += 10

        this.pdfLib.printDiag(doc, posX, posY, 0)
        
        // annonce
        posX = myLayout.x + 15
        posY = imagePosY + imageHeight + 10
        
        // Dessiner les crochets du rectangle de l'annonce
        doc.setFont("Roboto-Regular"); // set font
        doc.setFillColor(255,255,255);
        doc.setDrawColor('#ccc')
        doc.rect(posX , posY, myLayout.w - 30, 100, 'FD');
        doc.rect(posX-2 , posY + 10, myLayout.w, 80, 'F');
        doc.rect(posX + 10 , posY -2, myLayout.w - 50, 104, 'F');

        // Texte de l'annonce
        doc.setFontSize(13);
        doc.setFont("Roboto-Black"); // set font
        doc.setTextColor(55, 55, 55);
        var splitTitle = doc.splitTextToSize(this.offer.TexteAnnonce, myLayout.w - 60);
        doc.text(posX + 15, posY + 20, splitTitle);

        // Prix
        doc.setFontSize(40);
        doc.setTextColor(55, 55, 55);
        posY = posY + 125
        this.pdfLib.writeText(doc, posX, posY, priceText, { color: {r:176, g:58, b:46}, align: 'center' });
        if (this.trans === 'sales') {
          posY = posY + 10 
          doc.setFontSize(11);
          this.pdfLib.printInfosComplementaires(doc, myLayout, posY)
        }
      }

      // A4 Paysage x2
      if (this.model.format === 'A4' && this.model.orientation === 'l2') {
        
        // Images
        const imageWidth = 480
        const imageHeight = 315
        imagePosX = myLayout.x + 15
        imagePosY = 150
        
        imagePosX = (doc.internal.pageSize.width - imageWidth) / 2
        let posX = imagePosX
        let posY = imagePosY
        
        if (this.offer.images.length > 0) {
          image0 = await this.$tools.loadImageFromURL(this.pdfLib.offerImage (0))
        }
        doc.addImage(image0, "JPEG", posX, posY, imageWidth, imageHeight);

        posY += imageHeight + 10

        // Thumbnails
        const thumbWidth = 95
        const thumbHeight = 95

        for (var i = 1; i < Math.min(this.offer.images.length, 4); i++) {
          let x = posX +  (i-1) *(thumbWidth + 10)
          let y = posY
          const image = await this.$tools.loadImageFromURL(this.pdfLib.offerImage(i))
          doc.addImage(image, "JPEG", x, y, thumbWidth, thumbHeight);
        }
        
        doc.addPage("A4", "l");
        // annonce
        posX = 85
        posY = 100
        
        let splitTextWidth = 0
        if (this.pdfLib.isPrintDiag()){
          splitTextWidth = 340
        } else {
          splitTextWidth = 650
        }

        //console.log('splitTextWidth :', splitTextWidth)

        // Dessiner les crochets du rectangle de l'annonce
        doc.setFillColor(255,255,255);
        doc.setDrawColor('#ccc')
        doc.rect(posX , posY, splitTextWidth + 20, 240, 'FD');
        doc.rect(posX-2 , posY + 15, splitTextWidth + 35, 210, 'F');
        doc.rect(posX + 15 , posY-2, splitTextWidth - 10, 244, 'F');

        // Texte de l'annonce
        doc.setFontSize(17);
        doc.setFont("Roboto-Black"); // set font
        doc.setTextColor(55, 55, 55);
        var splitTitle = doc.splitTextToSize(this.offer.TexteAnnonce, 470);
        doc.text(posX + 15, posY + 20, splitTitle);
        
        posX += 520
        posY += 10
        
        // diag
        if (this.pdfLib.isPrintDiag()){
          const diagImageWidth = 160
          const diagImageHeight = 14
          
          doc.setPage(2);
          
          // diag
          doc.setTextColor(55, 55, 55);
          doc.setFontSize(13)
          doc.text('Diagnostics énergétiques', posX, posY)
          posY += 20
          doc.setFontSize(8)
          doc.text(this.offer.B31.toString(), posX, posY+30)
          if (this.offer.B31 > 1) {
            //doc.text("Diagnostic de performance énergétique (DPE)", posX, posY)
            //doc.text("Indice d'émission de gaz à effet de serre (GES)", posX, posY+100)

            doc.setFont("Roboto-Regular"); // set font
            
            const diag_imag = await this.$tools.loadImageFromURL(`/static/img/conso/DPE-${this.offer.EtiquConsommation}.png`)
            doc.addImage(diag_imag, "PNG", posX, posY+5 , diagImageWidth, diagImageHeight, null, "FAST");
            doc.text(`Valeur : ${this.offer.F33} kWh EP/m²/an`, posX, posY+35)

            const ges_imag = await this.$tools.loadImageFromURL(`/static/img/conso/GES-${this.offer.EtiquEmissionGES}.png`)
            doc.addImage(ges_imag, "PNG", posX, posY+105 , diagImageWidth, diagImageHeight, null, "FAST");
            doc.text(`Valeur : ${this.offer.F34} kg éqCO2/m²/an`, posX, posY+130)
          }
          
          doc.setFont("Roboto-Regular"); // set font
              
              //
          switch(this.offer.B31) {
            case 0 :
              doc.setFontSize(13)
              doc.text('DPE Vierge', posX+20, posY+30)
              break
            case 1 : 
              doc.setFontSize(13)
              doc.text('Non soumis au DPE', posX+20, posY+30)
              break
            case 2 : 
              doc.text('DPE avant le 01 Juillet 2021', posX, posY+55)
              break
            case 3 : 
              doc.text('DPE après le 01 Juillet 2021', posX, posY+55)
              break
          }
        }
        
        posY = 450  
        
        doc.setFontSize(50);
        doc.setFont("Roboto-Black"); // set font
        doc.setTextColor(55, 55, 55);
        
        this.pdfLib.writeText(doc, myLayout.x + 0, posY, priceText, { color: {r:176, g:58, b:46}, align: 'center' });
        if (this.trans === 'sales') {
          posY = posY + 30 
          doc.setFontSize(10);
          this.pdfLib.printInfosComplementaires(doc, myLayout, posY)
        }

        doc.setPage(3)
      }

      // A3 Portrait
      if (this.model.format === 'A3' && this.model.orientation === 'p') {
        // Images
        const imageWidth = 660
        const imageHeight = 450
        imagePosX = myLayout.x + 15
        imagePosY = 170
        imagePosX = (doc.internal.pageSize.width - imageWidth) / 2
        let posX = imagePosX
        let posY = imagePosY
        
        if (this.offer.images.length > 0) {
          image0 = await this.$tools.loadImageFromURL(this.pdfLib.offerImage (0))
        }
        doc.addImage(image0, "JPEG", posX, posY, imageWidth, imageHeight);
       
        posY += imageHeight + 10

        // Thumbnails
        const thumbWidth = 95
        const thumbHeight = 95

        for (var i = 1; i < Math.min(this.offer.images.length, 4); i++) {
          let x = posX +  (i-1) *(thumbWidth + 10)
          let y = posY
          const image = await this.$tools.loadImageFromURL(this.pdfLib.offerImage(i))
          doc.addImage(image, "JPEG", x, y, thumbWidth, thumbHeight);
        }
        
        
        // annonce
        posX = 85
        posY += thumbHeight + 10
        
        let splitTextWidth = 0
        if (this.pdfLib.isPrintDiag()){
          splitTextWidth = 490
        } else {
          splitTextWidth = 650
        }

        // Dessiner les crochets du rectangle de l'annonce
        doc.setFillColor(255,255,255);
        doc.setDrawColor('#ccc')
        doc.rect(posX , posY, splitTextWidth + 20, 240, 'FD');
        doc.rect(posX-2 , posY + 15, splitTextWidth + 35, 210, 'F');
        doc.rect(posX + 15 , posY-2, splitTextWidth - 10, 244, 'F');

         // Texte de l'annonce
        doc.setFontSize(16);
        doc.setFont("Roboto-Black"); // set font
        
        doc.setTextColor(55, 55, 55);
        var splitTitle = doc.splitTextToSize(this.offer.TexteAnnonce, 470);
        doc.text(posX + 15, posY + 20, splitTitle);
        
        posX += 520
        posY += 10
        // diag
        this.pdfLib.printDiag(doc, posX, posY)
        
        posY = 1050 
        
        doc.setFontSize(50);
        doc.setTextColor(55, 55, 55);
        this.pdfLib.writeText(doc, myLayout.x, posY, priceText, { color: {r:176, g:58, b:46}, align: 'center' });
        if (this.trans === 'sales') {
          posY = posY + 30 
          doc.setFontSize(11);
          this.pdfLib.printInfosComplementaires(doc, myLayout, posY)
        }
      }

      // A3 Paysage
      if (this.model.format === 'A3' && this.model.orientation === 'l') {
        
        // Images
        const imageWidth = 600
        const imageHeight = 390
        imagePosX = myLayout.x + 15
        imagePosY = 180
        
        imagePosX = (doc.internal.pageSize.width - imageWidth) / 2
        let posX = imagePosX
        let posY = imagePosY
        
        if (this.offer.images.length > 0) {
          image0 = await this.$tools.loadImageFromURL(this.pdfLib.offerImage (0))
        }
        doc.addImage(image0, "JPEG", posX, posY, imageWidth, imageHeight);
  
        posX += imageWidth + 10

        // Thumbnails
        const thumbWidth = 90
        const thumbHeight = 90

        for (var i = 1; i < Math.min(this.offer.images.length, 5); i++) {
          let x = posX
          let y = posY +  (i-1) *(thumbHeight + 10)
          const image = await this.$tools.loadImageFromURL(this.pdfLib.offerImage(i))
          doc.addImage(image, "JPEG", x, y, thumbWidth, thumbHeight);
        }
        
        // annonce
        posX = myLayout.x + 30
        posY = imagePosY + imageHeight + 40
        
        // Dessiner les crochets du rectangle de l'annonce
        doc.setFillColor(255,255,255);
        doc.setDrawColor('#ccc')
        doc.rect(posX , posY, myLayout.w - 310, 150, 'FD');
        doc.rect(posX-2 , posY + 15, myLayout.w, 120, 'F');
        doc.rect(posX + 20 , posY -2, myLayout.w - 350, 154, 'F');

        // Texte de l'annonce
        doc.setFontSize(18);
        doc.setFont("Roboto-Black"); // set font
        
        doc.setTextColor(55, 55, 55);
        var splitTitle = doc.splitTextToSize(this.offer.TexteAnnonce, myLayout.w - 330);
        doc.text(posX + 15, posY + 20, splitTitle);
        
        posX = myLayout.w - 220
        posY += 10
        // diag
        doc.setFontSize(15)
        doc.text('Diagnostic énergétique', posX, posY)
        const diagImageWidth = 160
        const diagImageHeight = 146.7
        posY += 20
        const diag_imag = await this.$tools.loadImageFromURL(`/static/img/conso/${this.getEnergieLevel()}.png`)
        doc.addImage(diag_imag, "JPEG", posX, posY , diagImageWidth, diagImageHeight);
        
        posX += 10 
        posY += diagImageHeight + 20


        doc.setFontSize(10);
        doc.setTextColor(0, 0, 0);
        doc.text(`Valeur : ${this.offer.F33} kWh EP/m²/an`, posX, posY)

        doc.setFontSize(28);
        doc.setFontType("bold");
        doc.setTextColor(55, 55, 55);
        this.pdfLib.writeText(doc, myLayout.x, posY, priceText, { color: {r:176, g:58, b:46}, align: 'center' });
      }
      
      if (this.coupCoeur){
        doc.setPage(1);
        const coupcoeur_imag = await this.$tools.loadImageFromURL(`/static/img/coup-coeur.png`)
        doc.addImage(coupcoeur_imag, "JPEG", imagePosX, imagePosY, 105, 105);
      }

      if (this.exclusive){
        const exclusive_imag = await this.$tools.loadImageFromURL(`/static/img/exclusive.png`)
        doc.addImage(exclusive_imag, "JPEG", myLayout.w - 88.2, 16, 105, 105);
      }

      if (this.vendu){
        doc.setFont('Roboto-Black')
        doc.setFontSize(120)
        doc.setTextColor(255, 0, 0)
        const posX = myLayout.x + 20
        const posY = myLayout.h/ 2 + 120
        this.pdfLib.writeText(doc, posX,   posY, 'VENDU', 
        { color: {r:255, g:0, b:55}, align: 'center', orientation: 30 });
      }
      iframe.src = doc.output('datauristring');
    }
  }
}
</script>