<template lang="html">
  <v-tab-item value="informations-juridiques">
  <v-container>
    <div class="row">
      <div class="col-lg-3 col-md-3 col-sm-3 col-6">
        <v-text-field label="Régime fiscal" filled v-model="offer.S29"></v-text-field>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-3 col-6">
        <v-text-field label="Forme juridique" filled v-model="offer.FormeJuridique"></v-text-field>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-3 col-6">
        <v-text-field label="Syndic" filled v-model="offer.S26"></v-text-field>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-3 col-6">
        <v-text-field label="Notaire" filled v-model="offer.NomOccupant"></v-text-field>
      </div>
      <div class="col-12 col-lg-3 col-md-6">
        <v-text-field label="Procédure en cours" filled v-model="offer.S52"></v-text-field>
      </div>
    </div>
    <div class="row">
      <v-subheader class="text-uppercase subheader--text">Commentaire professionnel</v-subheader>
    </div>
    <div class="row">
      <div class="col-12" >
        <v-textarea
          v-model="offer.CriteresComplementaires" 
          placeholder="Commentaire professionnel ..."
          auto-grow
          filled
          rows="6"
        ></v-textarea>
      </div> 
    </div> 
    </v-container>
  </v-tab-item>
</template>

<script>
export default {
  props: ['offer']
}
</script>
