<template lang="html">
  <v-expansion-panel>
      <v-expansion-panel-header>
        <template v-slot:default="{ open }">
          <v-subheader class="subtitle-1 text-uppercase primary--text" v-if="open">Informations juridiques</v-subheader>
          <v-subheader class="subtitle-1 text-uppercase" v-else>Informations juridiques</v-subheader>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <div class="row">
          <div class="col-5 col-md-2">
            <p  class="text-right">Gestionnaire :</p>
          </div>
          <div class="col-7 col-md-2">
            <p class="value tertiary">{{offer.S29}}</p>
          </div>
          <div class="col-5 col-md-2 col-lg-1">
            <p  class="text-right">Syndic :</p>
          </div>
          <div class="col-7 col-md-6 col-lg-4">
            <p class="value tertiary">{{offer.S26}}</p>
          </div>
        </div>
        <div class="row">
          <v-subheader class="text-uppercase text-center">N° de lot</v-subheader>
          <div class="col-6 col-md-2">
            <p  class="text-right">Logement :</p>
          </div>
          <div class="col-6 col-md-1">
            <p class="value tertiary">{{offer.S60}}</p>
          </div>
          <div class="col-6 col-md-1 col-lg-1">
            <p  class="text-right">Cave :</p>
          </div>
          <div class="col-6 col-md-1">
            <p class="value tertiary">{{offer.S61}}</p>
          </div>
          <div class="col-6 col-md-2 col-lg-1">
            <p  class="text-right">Parking int. :</p>
          </div>
          <div class="col-6 col-md-1">
            <p class="value tertiary">{{offer.S62}}</p>
          </div>
          <div class="col-6 col-md-2 col-lg-1">
            <p  class="text-right">Parking ext. :</p>
          </div>
          <div class="col-6 col-md-1">
            <p class="value tertiary">{{offer.NumeroLotParkExt}}</p>
          </div>
          <div class="col-6 col-md-2 col-lg-1">
            <p  class="text-right">Garage :</p>
          </div>
          <div class="col-6 col-md-1">
            <p class="value tertiary">{{offer.NumeroLotGarage}}</p>
          </div>
        </div>
        <div class="row">
          <v-subheader class="text-uppercase text-center">Parties communes</v-subheader>
        </div>
        <div class="row align-center justify-center">
          <div class="ma-3">
            <v-icon class="success--text" v-if="offer.B16 > 0">mdi-checkbox-marked-outline</v-icon>
            <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
            Gardiennage
          </div>
          <div class="ma-3">
            <v-icon class="success--text" v-if="offer.B17 > 0">mdi-checkbox-marked-outline</v-icon>
            <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
            Interphone
          </div>
          <div class="ma-3">
            <v-icon class="success--text" v-if="offer.B18 > 0">mdi-checkbox-marked-outline</v-icon>
            <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
            Vidéophone
          </div>
          <div class="ma-3">
            <v-icon class="success--text" v-if="offer.B19 > 0">mdi-checkbox-marked-outline</v-icon>
            <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
            Digicode
          </div>
          <div class="ma-3">
            <v-icon class="success--text" v-if="offer.B20 > 0">mdi-checkbox-marked-outline</v-icon>
            <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
            Espaces verts
          </div>
          <div class="ma-3">
            <v-icon class="success--text" v-if="offer.B21 > 0">mdi-checkbox-marked-outline</v-icon>
            <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
            Ascenseur
          </div>
          <div class="ma-3">
            <v-icon class="success--text" v-if="offer.B22 > 0">mdi-checkbox-marked-outline</v-icon>
            <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
            Antenne
          </div>
          <div class="ma-3">
            <v-icon class="success--text" v-if="offer.B23 > 0">mdi-checkbox-marked-outline</v-icon>
            <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
            Cable
          </div>
          <div class="ma-3">
              <v-icon class="success--text" v-if="offer.B14 > 0">mdi-checkbox-marked-outline</v-icon>
              <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
              Piscine
          </div>
          <div class="ma-3">
              <v-icon class="success--text" v-if="offer.IsAccesHandicape > 0">mdi-checkbox-marked-outline</v-icon>
              <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
              Accès handicapés
          </div>
          <div class="ma-3">
            <v-icon class="success--text" v-if="offer.B24 > 0">mdi-checkbox-marked-outline</v-icon>
            <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
            Chauffage
          </div>
          <div class="ma-3">
            <v-icon class="success--text" v-if="offer.B25 > 0">mdi-checkbox-marked-outline</v-icon>
            <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
            Eau chaude
          </div>
          <div class="ma-3">
            <v-icon class="success--text" v-if="offer.B26 > 0">mdi-checkbox-marked-outline</v-icon>
            <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
            Eau froide
          </div>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
export default {
  props: ['offer']
}
</script>
