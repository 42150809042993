<template lang="html">
  <v-expansion-panel>
    <v-expansion-panel-header>
      <template v-slot:default="{ open }">
        <v-subheader class="subtitle-1 text-uppercase primary--text" v-if="open">Descriptif technique</v-subheader>
        <v-subheader class="subtitle-1 text-uppercase" v-else>Descriptif technique</v-subheader>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <div class="row">
        <div class="col-6 col-md-3">
          <p class="text-right">Raison sociale :</p>
        </div>
        <div class="col-6 col-md-3">
          <p class="value tertiary">{{offer.NatureChauffage}}</p>
        </div>
        <div class="col-6 col-md-3">
          <p class="text-right">Enseigne :</p>
        </div>
        <div class="col-6 col-md-3">
          <p class="value tertiary">{{offer.TypeCuisine}}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-6 col-md-3">
          <p class="text-right">Forme juridique :</p>
        </div>
        <div class="col-6 col-md-3">
          <p class="value tertiary">{{offer.FormeJuridique}}</p>
        </div>
        <div class="col-6 col-md-3">
          <p class="text-right">Activité :</p>
        </div>
        <div class="col-6 col-md-3">
          <p class="value tertiary">{{offer.TypeChauffage}}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-6 col-md-3">
          <p class="text-right">Capital :</p>
        </div>
        <div class="col-6 col-md-2">
          <p class="value tertiary text-right">{{offer.F7.toLocaleString()}} €</p>
        </div>
        <div class="col-6 col-md-2">
          <p class="text-right">Date création :</p>
        </div>
        <div class="col-6 col-md-2">
          <p class="value tertiary">{{offer.DateLiberation}}</p>
        </div>
        <div class="col-6 col-md-2"> 
          <p class="text-right">Nbre employés :</p>
        </div>
        <div class="col-6 col-md-1">
          <p class="value tertiary text-right">{{offer.I6}}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-6 col-md-3">
          <p class="text-right">Type bail :</p>
        </div>
        <div class="col-6 col-md-3">
          <p class="value tertiary">{{offer.Standing}}</p>
        </div>
        <div class="col-6 col-md-3">
          <p class="text-right">Echéance bail :</p>
        </div>
        <div class="col-6 col-md-3">
          <p class="value tertiary">{{offer.DateConge}}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-6 col-md-3">
          <p class="text-right">Surface profess. :</p>
        </div>
        <div class="col-6 col-md-1">
          <p class="value tertiary text-right">{{offer.F12}} m<sup>2</sup></p>
        </div>
        <div class="col-6 col-md-2">
          <p class="text-right">Surface annexe :</p>
        </div>
        <div class="col-6 col-md-1">
          <p class="value tertiary text-right">{{offer.F14}} m<sup>2</sup></p>
        </div>
        <div class="col-6 col-md-2">
          <p class="text-right">Surface terrain :</p>
        </div>
        <div class="col-6 col-md-1">
          <p class="value tertiary text-right">{{offer.F15}} m<sup>2</sup></p>
        </div>
      </div>
      <div class="row">
        <div class="col-6 col-md-3">
          <p class="text-right">Surface logement :</p>
        </div>
        <div class="col-6 col-md-1">
          <p class="value tertiary text-right">{{offer.F16}} m<sup>2</sup></p>
        </div>
        <div class="col-6 col-md-2">
          <p class="text-right">longueur vitrine :</p>
        </div>
        <div class="col-6 col-md-1">
          <p class="value tertiary text-right">{{offer.F17}} m</p>
        </div>
        <div class="col-6 col-md-2">
          <p class="text-right">Nbr pièces :</p>
        </div>
        <div class="col-6 col-md-1">
          <p class="value tertiary text-right">{{offer.NombrePieces}}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-6 col-md-3">
          <p class="text-right">Park. intérieurs :</p>
        </div>
        <div class="col-6 col-md-1">
          <p class="value tertiary text-right">{{offer.NombrePkgInter}}</p>
        </div>
        <div class="col-6 col-md-2">
          <p class="text-right">Park. extérieurs :</p>
        </div>
        <div class="col-6 col-md-1">
          <p class="value tertiary text-right">{{offer.NombrePkgExter}}</p>
        </div>
        <div class="col-6 col-md-2">
          <p class="text-right">Box/Garages :</p>
        </div>
        <div class="col-6 col-md-1">
          <p class="value tertiary text-right">{{offer.I12}}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-6 col-md-3">
          <p class="text-right">Masse salariale :</p>
        </div>
        <div class="col-6 col-md-2">
          <p class="value tertiary text-right">{{offer.F9.toLocaleString()}} €</p>
        </div>
        <div class="col-6 col-md-2">
          <p class="text-right">Salaire exploitant :</p>
        </div>
        <div class="col-6 col-md-2">
          <p class="value tertiary text-right">{{offer.F25.toLocaleString()}} €</p>
        </div>
      </div>
      <div class="row">
        <div class="col-6 col-md-3">
          <p class="text-right">Stock :</p>
        </div>
        <div class="col-6 col-md-2">
          <p class="value tertiary text-right">{{offer.F26.toLocaleString()}} €</p>
        </div>
        <div class="col-6 col-md-2">
          <p class="text-right">Cash flow :</p>
        </div>
        <div class="col-6 col-md-2">
          <p class="value tertiary text-right">{{offer.F27.toLocaleString()}} €</p>
        </div>
      </div>
      <div class="row">
        <div class="col-6 col-md-3">
          <p class="text-right">Taxe professionnelle :</p>
        </div>
        <div class="col-6 col-md-2">
          <p class="value tertiary text-right">{{offer.F8.toLocaleString()}} €</p>
        </div>
        <div class="col-6 col-md-2">
          <p class="text-right">Taux taxe :</p>
        </div>
        <div class="col-6 col-md-2">
          <p class="value tertiary text-right">{{offer.F18.toLocaleString()}} %</p>
        </div>
      </div>
      <div class="row">
        <v-subheader class="text-uppercase text-center">Chiffres d'affaires</v-subheader>
      </div>
      <div class="row mb-10">
        <div class="col-6 col-md-3">
          <table style="width:100%;">
            <thead>
              <tr>
                <th width="20%" class="text-center text-primary">Année</th>
                <th width="45%" class="text-center text-primary">Chiffre d'affaires</th>
                <th width="30%" class="text-center text-primary">B.I.C</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{offer.I7}}</td>
                <td><p class="value tertiary text-right">{{offer.F19.toLocaleString()}} €</p></td>
                <td><p class="value tertiary text-right">{{offer.F22.toLocaleString()}} €</p></td>
              </tr>
              <tr>
                <td>{{offer.I8}}</td>
                <td><p class="value tertiary text-right">{{offer.F20.toLocaleString()}} €</p></td>
                <td><p class="value tertiary text-right">{{offer.F23.toLocaleString()}} €</p></td>
              </tr>
              <tr>
                <td>{{offer.I9}}</td>
                <td><p class="value tertiary text-right">{{offer.F21.toLocaleString()}} €</p></td>
                <td><p class="value tertiary text-right">{{offer.F24.toLocaleString()}} €</p></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row">
        <div class="col-6 col-md-2">
          <br/>
        </div>
      </div>
      <div class="row">
        <v-subheader class="text-uppercase text-center">Notes client</v-subheader>
        <div class="pl-3 text text-left  text-uppercase tertiary">
          <br/>
          {{ offer.NotesClient }}
          <br/>
        </div>
      </div>
      <div class="row">
        <v-subheader class="text-uppercase text-center">Informations complémentaires</v-subheader>
        <div class="pl-3 text text-left  text-uppercase tertiary">
          <br/>
          {{ offer.CriteresComplementaires }}
          <br/>
        </div> 
      </div>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
export default {
  props: ['offer'],
  data () {
    return {
      category: ['Appartement', 'Maison', 'Local', 'Parking'],
      EtatInterieur: ['', 'A restaurer', 'Bon', 'Moyen', 'Passable']
    }
  }
}
</script>
