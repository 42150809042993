<template lang="html">
  <v-expansion-panel>
      <v-expansion-panel-header>
        <template v-slot:default="{ open }">
          <v-subheader class="subtitle-1 text-uppercase primary--text" v-if="open">Conditions financières</v-subheader>
          <v-subheader class="subtitle-1 text-uppercase" v-else>Conditions financières</v-subheader>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
      <div class="container">
      <div class="row align-center justify-center">
        <div class="col-12 col-md-6">
          <v-subheader class="subtitle-1 text-uppercase secondary--text text-center">Loyer H.C.</v-subheader>
          <div class="row align-center justify-center">
            <div class="col-6">
              <v-icon left color="success" v-if="offer.I15 === 0">mdi-radiobox-marked</v-icon>
              <v-icon left v-else>mdi-radiobox-blank</v-icon>
              Loyer mensuel
            </div>
            <div class="col-6">
              <v-icon left color="success" v-if="offer.I15 === 1">mdi-radiobox-marked</v-icon>
              <v-icon left v-else>mdi-radiobox-blank</v-icon>
              Loyer trimestriel
            </div>
          </div>
          <div class="row align-center justify-center">
            <div class="col-6">
              <p class="text-right">Loyer H.C/H.T :</p>
            </div>
            <div class="col-6 col-md-4">
              <p class="value tertiary text-right">{{getAmount(offer.Price)}} €</p>
            </div>
          </div>
          <div class="row align-center justify-center">
            <div class="col-6">
              <p class="text-right">Taux T.V.A.:</p>
            </div>
            <div class="col-6 col-md-4">
              <p class="value tertiary text-right">{{offer.F14}} %</p>
            </div>
          </div>
          <div class="row align-center justify-center">
            <div class="col-6">
              <p class="text-right">T.V.A :</p>
            </div>
            <div class="col-6 col-md-4">
              <p class="value tertiary text-right">{{getAmount(offer.F5)}} €</p>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <v-subheader class="subtitle-1 text-uppercase secondary--text text-center">Charges</v-subheader>
          <div class="row align-center justify-center">
            <div class="col-6">
              <v-icon left color="success" v-if="offer.I28 === 0">mdi-radiobox-marked</v-icon>
              <v-icon left v-else>mdi-radiobox-blank</v-icon>
              Charges mensuelles
            </div>
            <div class="col-6">
              <v-icon left color="success" v-if="offer.I28 === 1">mdi-radiobox-marked</v-icon>
              <v-icon left v-else>mdi-radiobox-blank</v-icon>
              Charges trimestrielles
            </div>
          </div>
          <div class="row align-center justify-center">
            <div class="col-6">
              <p class="text-right">Provisions charges</p>
            </div>
            <div class="col-6 col-md-4">
              <p class="value tertiary text-right">{{getAmount(offer.F3)}} €</p>
            </div>
          </div>
          <div class="row align-center justify-center">
            <div class="col-6">
              <p class="text-right">Dépôt de garantie:</p>
            </div>
            <div class="col-6 col-md-4">
              <p class="value tertiary text-right">{{getAmount(offer.F10)}} €</p>
            </div>
          </div>
          <div class="row align-center justify-center">
            <div class="col-6">
              <p class="text-right">Caution pour BIP :</p>
            </div>
            <div class="col-6 col-md-4">
              <p class="value tertiary text-right">{{getAmount(offer.F8)}} €</p>
            </div>
          </div>
        </div>
      </div>
      
      <div class="row align-center justify-center">
        <div class="ma-3">
          <p class="text-right">Loyer H.C/TTC :</p>
        </div>
        <div class="ma-3">
          <p class="value tertiary text-right error--text">{{getAmount(offer.Price)}} €</p>
        </div>
      </div>
      <div class="row">
        <v-subheader class="subtitle-1 text-uppercase secondary--text">Honoraire locataire</v-subheader>
      </div>
      <div class="row align-center justify-center">
        <div class="ma-3">
          <p class="text-right">Loyer TTC:</p>
        </div>
        <div class="ma-3"> 
          <p class="value tertiary text-right">{{getAmount(offer.F11)}} €</p>
        </div>
      </div>
      <div class="row align-center justify-center">
        <div class="ma-3">
          <p class="text-right">Rédaction acte TTC:</p>
        </div>
        <div class="ma-3">
          <p class="value tertiary text-right">{{getAmount(offer.F13)}} €</p>
        </div>
      </div>
      <div class="row align-center justify-center">
        <div class="ma-3">
          <p class="text-right">Total TTC:</p>
        </div>
        <div class="ma-3">
          <p class="value text-right error--text">{{getAmount(offer.F25)}} €</p>
        </div>
      </div>
      </div>
     </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
export default {
  props: ['offer'],
  methods: {
    getAmount(amount){
      let n = new Number(amount)
      let _amount = ''
      if (n) {
        _amount = n.toLocaleString("fr-FR");
      } 
      return _amount
    }
  }
}
</script>
