<template lang="html">
      <v-navigation-drawer
        v-model="_drawer"
        :color="color"
        :expand-on-hover="expandOnHover"
        :mini-variant="miniVariant"
        :right="right"
        absolute
        
      >
        <v-list
          dense
          nav
          class="py-0"
        >
          <v-list-item two-line :class="miniVariant && 'px-0'"></v-list-item>
          <v-divider></v-divider>
          <v-list-item
            v-for="item in items"
            :key="item.title"
            link
            @click.prevent="_callFunction(item.ref)"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import menu from './menu'

export default {
  data:()=>({
    color: 'white',
    colors: [
      'primary',
      'blue',
      'success',
      'error',
      'teal',
    ],
    right: false,
    miniVariant: true,
    expandOnHover: true,
    background: false,
  }),
  mixins:[menu],
  computed: {
    ...mapGetters([
      'drawer'
    ]),
    _drawer: {
      get: function () {
        return this.drawer
      },
      set: function (value) {
        // console.log('value : ', value)
      }
    }
  },
  methods:{
      ...mapActions([
          'logout'
      ])
  }
}
</script>
