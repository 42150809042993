<template lang="html">
  <div class="container">
    <div class="row">
      <div class="col-5 col-md-3">
        <p class="text-right">Création :</p>
      </div>
      <div class="col-7 col-md-3">
        <p class="value tertiary">{{ offer.DateCreation}}</p>
      </div>
      <div class="col-5 col-md-2">
        <p class="text-right">Modification :</p>
      </div>
      <div class="col-7 col-md-3">
        <p class="value tertiary">{{ offer.DateModification }}</p>
      </div>
    </div>
    <div class="row">
      <div class="col-5 col-md-3">
        <p class="text-right">Modifiée par :</p>
      </div>
      <div class="col-7 col-md-3">
        <p class="value tertiary">{{ offer.LastUpdateDataUser}}</p>
      </div>
      <div class="col-5 col-md-2">
        <p class="text-right">Négociateur :</p>
      </div>
      <div class="col-7 col-md-3">
        <p class="value tertiary">{{ offer.Negociateur}}</p>
      </div>
    </div>
    <div class="row">
      <v-divider class="mt-2"></v-divider>
    </div>
    <div class="row">
      <div class="col-5 col-md-3">
        <p class="text-right">Type de bien :</p>
      </div>
      <div class="col-7 col-md-3">
        <p class="value tertiary capitalize">{{ offer.TypeBien }}</p>
      </div>
      <div class="col-5 col-md-3">
        <p class="text-right">Année de construction :</p>
      </div>
      <div class="col-7 col-md-2">
        <p class="value tertiary">{{ offer.AnneeConstruction}}</p>
      </div>
    </div>
    <div class="row">
      <v-subheader class="text-uppercase subheader--text">Mandat</v-subheader>
    </div>
    <div class="row">
        <div class="col-5 col-md-3">
          <p class="text-right">N° Mandat :</p>
        </div>
        <div class="col-7 col-md-3">
          <p class="value tertiary">{{ offer.NumMandat}}</p>
        </div>
        <div class="col-5 col-md-3">
          <p class="text-right">Type Mandat :</p>
        </div>
        <div class="col-7 col-md-2">
          <p class="value tertiary">{{ offer.TypeMandat}}</p>
        </div>
    </div>
    <div class="row">
        <div class="col-5 col-md-3">
          <p class="text-right">Date signature :</p>
        </div>
        <div class="col-7 col-md-3">
          <p class="value tertiary">{{ offer.DateSignature}}</p>
        </div>
        <div class="col-5 col-md-3">
          <p class="text-right">Date échéance :</p>
        </div>
        <div class="col-7 col-md-2">
          <p class="value tertiary">{{ offer.DateEcheance}}</p>
        </div>
    </div>
    <div class="row">
      <v-subheader class="text-uppercase subheader--text">Occupant</v-subheader>
    </div>
    <div class="row">
        <div class="col-5 col-md-3">
          <p class="text-right">Nom :</p>
        </div>
        <div class="col-7 col-md-8">
          <p class="value tertiary">{{ offer.NomOccupant}}</p>
        </div>
      </div>
    <div class="row">
        <div class="col-5 col-md-3">
          <p class="text-right">Type de bail :</p>
        </div>
        <div class="col-7 col-md-8">
          <p class="value tertiary">{{ offer.TypeLogement}}</p>
        </div>
    </div>
    <div class="row">
        <div class="col-5 col-md-3">
          <p class="text-right">Tél. Mobile :</p>
        </div>
        <div class="col-7 col-md-3">
          <p class="value tertiary">{{ offer.MobileOccupant}}</p>
        </div>
        <div class="col-5 col-md-2">
          <p class="text-right">Tél. Fixe :</p>
        </div>
        <div class="col-7 col-md-3">
          <p class="value tertiary">{{ offer.FixeOccupant}}</p>
        </div>
    </div>
    <div class="row">
        <div class="col-5 col-md-3">
          <p class="text-right">Opérateur Télécom.:</p>
        </div>
        <div class="col-7 col-md-8">
          <p class="value tertiary">{{ offer.OperTelOccupant}}</p>
        </div>
      </div>
    <div class="row">
        <div class="col-5 col-md-3">
          <p class="text-right">Fourn. d'électricité:</p>
        </div>
        <div class="col-7 col-md-8">
          <p class="value tertiary">{{ offer.FournElecOccupant}}</p>
        </div>
    </div>
    <div class="row">
              <div class="col-5 col-md-3">
          <p class="text-right">Ancien locataire:</p>
        </div>
        <div class="col-7 col-md-8">
          <p class="value tertiary">{{ offer.ExLocataire}}</p>
        </div>
    </div>
    <div class="row">
      <div class="col-5 col-md-3">
        <p class="text-right">Date congé :</p>
      </div>
      <div class="col-7 col-md-3">
        <p class="value tertiary">{{ offer.DateEchPreavis}}</p>
      </div>
      <div class="col-5 col-md-3">
        <p class="text-right">Date disponibilité:</p>
      </div>
      <div class="col-7 col-md-2">
        <p class="value tertiary">{{ offer.DateDisponibilite}}</p>
      </div>
    </div>
    <div class="row">
      <div class="col-5 col-md-3">
        <p class="text-right">Date échéance:</p>
      </div>
      <div class="col-7 col-md-3">
        <p class="value tertiary">{{ offer.DateEcheance}}</p>
      </div>
      <div class="col-5 col-md-3">
        <p class="text-right">Date libération:</p>
      </div>
      <div class="col-7 col-md-2">
        <p class="value tertiary">{{ offer.DateLiberation}}</p>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  export default {
    props: ['offer'],
    computed: {
      ...mapGetters([
        'getTypeBiens'
      ])
    }
  }
</script>
