import Vue from 'vue'
import api from '@/api'

const state = {
  connected: Vue.prototype.$localStore.getBoolean('connected'),
  token: Vue.prototype.$localStore.getString('token'),
  user: Vue.prototype.$localStore.getObject('user'),
  drawer: false,
  counts: {

  } 
}

// getters
const getters = {
  connected: state => {
    return state.connected
  },
  getToken: state => {
    return state.token
  },
  user: state => {
    return state.user
  },
  drawer: state => {
    return state.drawer
  }
}

// actions
const actions = {
  login: async ({commit}, userInfo) => {
    try {
      const response = await api.authentification(userInfo)
      commit('LOGIN', {response})
      return response
    } catch (error) {
      throw error
    }
  },
  logout ({ commit }) {
    commit('LOGOUT')
  },
  setDrawer({ commit }){
    commit('DRAWER')
  },
  fetchCounts: async ({commit}) => {
    try {
      //console.log("state.user :", state.user.account_id)
      const _counts = await api.counts(state.user.account_id)
      return _counts  
    } catch (error) {
      console.log('error :', error)
      throw error
    }
  }
}

// mutations
const mutations = {
  LOGIN (state, response) {
    let data = response.response
    if (data.User && data.token) {
      Vue.prototype.$localStore.setObject('user', data.User)
      Vue.prototype.$localStore.setString('token', data.token)
      Vue.prototype.$localStore.setBoolean('connected', true)
      state.token = data.token
      state.user = data.User
      state.connected = true
    }
  },

  LOGOUT (state) {
    // console.log('Token:', window.localStorage.getItem('token'))
    Vue.prototype.$localStore.removeItem('token')
    Vue.prototype.$localStore.removeItem('user')
    Vue.prototype.$localStore.removeItem('connected')
    state.token = null
    state.user = null
    state.connected = false
  },

  DRAWER (state) {
    state.drawer = !state.drawer
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
