<template lang="html">
  <v-tab-item value="informations-agence">
    <v-container>
      <div class="row">
        <v-subheader class="text-uppercase subheader--text">Informations vendeur</v-subheader>
      </div>
      <div class="row align-center" >
        <div class="col col-md-3 col-sm-4 col-6">
          <v-text-field label="Vendeur" filled v-model="offer.NomProprietaire"></v-text-field>
        </div>
        <div class="col col-m-3 col-sm-4 col-6">
          <v-text-field label="Téléphone" filled v-model="offer.TelProprietaire"></v-text-field>
        </div>
        <div class="col col-sm-6 col-12">
          <v-text-field label="Email" filled v-model="offer.EmailProprietaire"></v-text-field>
        </div>
      </div>
      <div class="row">
        <v-subheader class="text-uppercase subheader--text">Notes</v-subheader>
      </div>
      <div class="row">
        <div class="col col-12" >
          <v-textarea
            v-model="offer.NotesProprietaire"
            placeholder="Renseigner les infos : Origine de l'offre, Motif, Urgence ..."
            auto-grow
            filled
            rows="6"
          ></v-textarea>
        </div> 
      </div>
      <div class="row">
        <v-subheader class="text-uppercase subheader--text">Consigne de visite</v-subheader>
      </div>
      
      <div class="row align-center" >
        <div class="col col-sm-2 col-4">
          <lib-v-number label="N° Clé" v-model="offer.NombreCles" ></lib-v-number>
        </div>
        <div class="col col-sm-2 col-8">
          <v-text-field label="Digicode" filled v-model="offer.Digicode"></v-text-field>
        </div>
        <div class="col col-sm-6 col-12">
          <v-text-field label="Accès" filled v-model="offer.AccesVisite"></v-text-field>
        </div>
      </div>
      <div class="row">
        <v-subheader class="text-uppercase subheader--text">Commentaire visite</v-subheader>
      </div>
      <div class="row">
        <div class="col col-12" >
          <v-textarea
            v-model="offer.CommentaireVisite"
            placeholder="Renseigner les consignes pour la visite ..."
            auto-grow
            filled
            rows="6"
          ></v-textarea>
        </div> 
      </div>
    </v-container>
  </v-tab-item>
</template>

<script>
import { mask } from 'vue-the-mask'

export default {
  props: ['offer'],
  directives: {
    mask
  }
}
</script>
