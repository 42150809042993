<template lang="html">
  <v-expansion-panel>
    <v-expansion-panel-header>
      <template v-slot:default="{ open }">
        <v-subheader class="subtitle-1 text-uppercase primary--text" v-if="open">Descriptif technique</v-subheader>
        <v-subheader class="subtitle-1 text-uppercase" v-else>Descriptif technique</v-subheader>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <div class="row align-center justify-center">
        <div class="ma-4">
          <v-icon class="success--text" v-if="offer.B5 > 0">mdi-checkbox-marked-outline</v-icon>
          <v-icon class="material-icons" v-else>mdi-checkbox-blank-outline</v-icon>
          Bureau
        </div>
        <div class="ma-4">
          <v-icon class="success--text" v-if="offer.B6 > 0">mdi-checkbox-marked-outline</v-icon>
          <v-icon class="material-icons" v-else>mdi-checkbox-blank-outline</v-icon>
          Commerce
        </div>
        <div class="ma-4">
          <v-icon class="success--text" v-if="offer.B7 > 0">mdi-checkbox-marked-outline</v-icon>
          <v-icon class="material-icons" v-else>mdi-checkbox-blank-outline</v-icon>
          Activité
        </div>
        <div class="ma-4">
          <v-icon class="success--text" v-if="offer.B8 > 0">mdi-checkbox-marked-outline</v-icon>
          <v-icon class="material-icons" v-else>mdi-checkbox-blank-outline</v-icon>
          Professionnel
        </div>
      </div>
      <div class="row">
        <div class="col-6 col-md-2">
          <p class="text-right">Surface totale :</p>
        </div>
        <div class="col-6 col-md-2">
          <p class="value tertiary text-right">{{offer.F12}} m<sup>2</sup></p>
        </div>
        <div class="col-6 col-md-2">
          <p class="text-right">Surface mini :</p>
        </div>
        <div class="col-6 col-md-2">
          <p class="value tertiary text-right">{{offer.F15}} m<sup>2</sup></p>
        </div>
        <div class="col-6 col-md-2">
          <p class="text-right">Surface terrain :</p>
        </div>
        <div class="col-6 col-md-2">
          <p class="value tertiary text-right">{{offer.F16}} m<sup>2</sup></p>
        </div>
        <div class="col-6 col-md-2">
          <p class="text-right">Longueur vitrine :</p>
        </div>
        <div class="col-6 col-md-2">
          <p class="value tertiary text-right">{{offer.F17}} m</p>
        </div>
      </div>
      <div class="row">
        <div class="col-6 col-md-2">
          <p class="text-right">Type chauffage :</p>
        </div>
        <div class="col-6 col-md-2">
          <p class="value tertiary">{{offer.TypeChauffage}}</p>
        </div>
        <div class="col-6 col-md-2">
          <p class="text-right">Nature chauffage :</p>
        </div>
        <div class="col-6 col-md-2">
          <p class="value tertiary">{{offer.NatureChauffage}}</p>
        </div>
        <div class="col-6 col-md-2">
          <p class="text-right">Mode chauffage :</p>
        </div>
        <div class="col-6 col-md-2">
          <p class="value tertiary">{{offer.ModeChauffage}}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-6 col-md-2">
          <p class="text-right">Etage :</p>
        </div>
        <div class="col-6 col-md-2">
          <p class="value tertiary">{{offer.Etage}} / {{ offer.NombreNiveaux}}</p>
        </div>
        <div class="col-6 col-md-2">
          <p class="text-right">Box/Garages :</p>
        </div>
        <div class="col-6 col-md-2">
          <p class="value tertiary">{{offer.I12}}</p>
        </div>
        <div class="col-6 col-md-2">
          <p class="text-right">Park. intérieurs :</p>
        </div>
        <div class="col-6 col-md-2">
          <p class="value tertiary">{{offer.NombrePkgInter}}</p>
        </div>
        <div class="col-6 col-md-2">
          <p class="text-right">Park. extérieurs :</p>
        </div>
        <div class="col-6 col-md-2">
          <p class="value tertiary">{{offer.NombrePkgExter}}</p>
        </div>
      </div>
      <div class="row align-center justify-center">
        <div class="ma-4">
          <v-icon class="success--text" v-if="offer.B16 > 0">mdi-checkbox-marked-outline</v-icon>
          <v-icon class="material-icons" v-else>mdi-checkbox-blank-outline</v-icon>
          Gardiennage
        </div>
        <div class="ma-4">
          <v-icon class="success--text" v-if="offer.IsInterphone > 0">mdi-checkbox-marked-outline</v-icon>
          <v-icon class="material-icons" v-else>mdi-checkbox-blank-outline</v-icon>
          Interphone
        </div>
        <div class="ma-4">
          <v-icon class="success--text" v-if="offer.B18 > 0">mdi-checkbox-marked-outline</v-icon>
          <v-icon class="material-icons" v-else>mdi-checkbox-blank-outline</v-icon>
          Vidéophone
        </div>
        <div class="ma-4">
          <v-icon class="success--text" v-if="offer.IsDigicode > 0">mdi-checkbox-marked-outline</v-icon>
          <v-icon class="material-icons" v-else>mdi-checkbox-blank-outline</v-icon>
          Digicode
        </div>
        <div class="ma-4">
          <v-icon class="success--text" v-if="offer.B20 > 0">mdi-checkbox-marked-outline</v-icon>
          <v-icon class="material-icons" v-else>mdi-checkbox-blank-outline</v-icon>
          Climatisation
        </div>
        <div class="ma-4">
          <v-icon class="success--text" v-if="offer.IsAscenseur > 0">mdi-checkbox-marked-outline</v-icon>
          <v-icon class="material-icons" v-else>mdi-checkbox-blank-outline</v-icon>
          Ascenseur
        </div>
        <div class="ma-4">
          <v-icon class="success--text" v-if="offer.B15 > 0">mdi-checkbox-marked-outline</v-icon>
          <v-icon class="material-icons" v-else>mdi-checkbox-blank-outline</v-icon>
          Télésurveillance
        </div>
        <div class="ma-4">
          <v-icon class="success--text" v-if="offer.IsAccesHandicape > 0">mdi-checkbox-marked-outline</v-icon>
          <v-icon class="material-icons" v-else>mdi-checkbox-blank-outline</v-icon>
          Accès handicapé
        </div>
        <div class="ma-4">
          <v-icon class="success--text" v-if="offer.B14 > 0">mdi-checkbox-marked-outline</v-icon>
          <v-icon class="material-icons" v-else>mdi-checkbox-blank-outline</v-icon>
          Monte charge
        </div>
      </div>
      <div class="row align-center justify-center">
        <div class="ma-4">
          <v-icon class="success--text" v-if="offer.B18 > 0">mdi-checkbox-marked-outline</v-icon>
          <v-icon class="material-icons" v-else>mdi-checkbox-blank-outline</v-icon>
          Immeuble collectif
        </div>
        <div class="ma-4">
          <v-icon class="success--text" v-if="offer.B19 > 0">mdi-checkbox-marked-outline</v-icon>
          <v-icon class="material-icons" v-else>mdi-checkbox-blank-outline</v-icon>
          Immeuble indépendant
        </div>
      </div>
      <div class="row">
        <v-subheader class="text-uppercase text-center">Description du local</v-subheader>
        <div class="pl-3 text text-left text-uppercase tertiary">
          <br/>
          {{ offer.NotesInternes }}
          <br/>
        </div> 
      </div>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
export default {
  props: ['offer'],
  data () {
    return {
      category: ['Appartement', 'Maison', 'Local', 'Parking'],
      EtatInterieur: ['', 'A restaurer', 'Bon', 'Moyen', 'Passable']
    }
  }
}
</script>
