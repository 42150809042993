<template lang="html">
  <div class="panel-body view-panel">
      <div class="row">
        <div class="col-5 col-md-3">
          <p class="text-right">Création :</p>
        </div>
        <div class="col-7 col-md-3">
          <p class="value tertiary">{{ offer.DateCreation}}</p>
        </div>
        <div class="col-5 col-md-2">
          <p class="text-right">Modification :</p>
        </div>
        <div class="col-7 col-md-3">
          <p class="value tertiary">{{ offer.DateModification }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-5 col-md-3">
          <p class="text-right">Modifiée par :</p>
        </div>
        <div class="col-7 col-md-3">
          <p class="value tertiary">{{ offer.LastUpdateDataUser}}</p>
        </div>
        <div class="col-5 col-md-2">
          <p class="text-right">Négociateur :</p>
        </div>
        <div class="col-7 col-md-3">
          <p class="value tertiary">{{ offer.Negociateur}}</p>
        </div>
      </div>
      <div class="row">
        <v-divider class="mt-2"></v-divider>
      </div>
      <div class="row">
        <div class="col-5 col-md-3">
          <p class="text-right">Type de bien :</p>
        </div>
        <div class="col-7 col-md-3">
          <p class="value tertiary capitalize">{{ offer.TypeBien }}</p>
        </div>
      </div>
      <div class="row">
        <v-subheader class="text-uppercase subheader--text">Mandat</v-subheader>
      </div>
      <div class="row">
        <div class="col-5 col-md-3">
          <p class="text-right">N° Mandat :</p>
        </div>
        <div class="col-7 col-md-3">
          <p class="value tertiary">{{ offer.NumMandat}}</p>
        </div>
        <div class="col-5 col-md-3">
          <p class="text-right">Type Mandat :</p>
        </div>
        <div class="col-7 col-md-3">
          <p class="value tertiary">{{ offer.TypeMandat}}</p>
        </div>
      </div>
      <div class="row">
          <div class="col-5 col-md-3">
            <p class="text-right">Date signature :</p>
          </div>
          <div class="col-7 col-md-3">
            <p class="value tertiary">{{ offer.DateSignature}}</p>
          </div>
          <div class="col-5 col-md-3">
            <p class="text-right">Date échéance :</p>
          </div>
          <div class="col-7 col-md-3">
            <p class="value tertiary">{{ offer.DateEcheance}}</p>
          </div>
      </div>
    </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  export default {
    props: ['offer'],
    computed: {
      ...mapGetters([
        'getTypeBiens'
      ])
    }
  }
</script>
