<template lang="html">
  <v-expansion-panel>
    <v-expansion-panel-header>
      <template v-slot:default="{ open }">
        <v-subheader class="subtitle-1 text-uppercase primary--text" v-if="open">Informations juridiques</v-subheader>
        <v-subheader class="subtitle-1 text-uppercase" v-else>Informations juridiques</v-subheader>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <div class="row">
        <div class="col-6 col-md-2">
          <p  class="text-right">Régime fiscal :</p>
        </div>
        <div class="col-6 col-md-2">
          <p class="value tertiary">{{offer.S29}}</p>
        </div>
        <div class="col-6 col-md-2">
          <p  class="text-right">Forme juridique :</p>
        </div>
        <div class="col-6 col-md-2">
          <p class="value tertiary">{{offer.FormeJuridique}}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-6 col-md-2">
          <p  class="text-right">Syndic :</p>
        </div>
        <div class="col-6 col-md-2">
          <p class="value tertiary">{{offer.S26}}</p>
        </div>
        <div class="col-6 col-md-2">
          <p  class="text-right">Notaire :</p>
        </div>
        <div class="col-6 col-md-2">
          <p class="value tertiary">{{offer.NomOccupant}}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-6 col-md-2">
          <p  class="text-right">Nbr lots principaux :</p>
        </div>
        <div class="col-6 col-md-2">
          <p class="value tertiary">{{offer.S40}}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-6 col-md-2">
          <p  class="text-right">Dern. Assemblée :</p>
        </div>
        <div class="col-6 col-md-2">
          <p class="value tertiary">{{offer.DateEchPreavis}}</p>
        </div>
        <div class="col-6 col-md-2">
          <p  class="text-right">Procédure en cours :</p>
        </div>
        <div class="col-6 col-md-2">
          <p class="value tertiary">{{offer.S52}}</p>
        </div>
      </div>
      <div class="row">
        <v-subheader class="text-uppercase text-center">Commentaire professionnel</v-subheader>
        <div class="pl-3 text text-left text-uppercase tertiary">
          <br/>
          {{ offer.CriteresComplementaires }}
          <br/>
        </div> 
      </div>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
export default {
  props: ['offer']
}
</script>
