<template>
  <div>
    <div class="row mt-10">
      <v-subheader class="text-uppercase subheader--text">
        DPE
      </v-subheader>
    </div>
    <div class="row align-center justify-center flex-nowrap mb-3" >
      <v-radio-group v-model.number="offer.B31" row class="radio__group"> 
        <v-radio color="primary" label="DPE Vierge" :value="0"></v-radio>
        <v-radio color="secondary" label="Non soumis au DPE" :value="1"></v-radio>
        <v-radio color="info" label="DPE avant le O1 Juillet 2021" :value="2"></v-radio>
        <v-radio color="success" label="DPE aprés le O1 Juillet 2021" :value="3"></v-radio>
      </v-radio-group>
    </div>
    <div class="row align-center justify-center flex-nowrap mb-3">
      <div :class="error ? 'block' : 'd-none'" class="red white--text font-weight-medium pa-2">
        <v-icon class="white--text">mdi-alert</v-icon>
        DPE non valide
      </div>
    </div>
    <div class="dpe disabled ml-md-10 pa-4" :class="{enabled: ((offer.B31 === 2)||(offer.B31 === 3))}">
      <div class="diagnostic ml-md-10">
        <div class="row align-center justify-start flex-nowrap">
          <div class="ml-1 caption">
            <span>Date DPE:</span>
          </div>
          <div class="ml-1">
            <div>
              <ValidationProvider name="DateDiagDPE" rules="required" v-slot="{ errors }">
                <v-text-field filled v-model="offer.DateDiagDPE" v-mask="'##/##/####'" placeholder="JJ/MM/AAAA"></v-text-field>
                <span class="error--text">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <div class="ml-1 flex-grow-1">
            <v-text-field filled name="presta_DPE" v-model.trim="offer.PrestaDiagDPE" placeholder="Commentaires"></v-text-field>
          </div>
        </div>
        <div class="row align-center justify-start flex-nowrap">
          <div class="ml-1 caption">
            <span>Consommation énergie</span>
          </div>
          <div class="ml-1">
            <lib-v-currency id="F33" label="" v-model="offer.F33" symbol=""></lib-v-currency>
          </div>
          <div class="ml-1 caption"><span>kWh EP/m<sup>2</sup>/an</span></div>
          <div class="text-center ml-2 mr-2">
            <span :class="offer.EtiquConsommation" class="font-weight-black CE">{{ offer.EtiquConsommation }}</span>
          </div>
          <div class="ml-1 flex-grow-1">
            <v-text-field filled v-model="offer.S83" placeholder="Cas particulier"></v-text-field>
          </div>
        </div>
        <div class="row align-center justify-start flex-nowrap">
          <div class="ml-1 caption">
            <span>Taux émission GES</span>
          </div>
          <div class="ml-1">
            <lib-v-currency id="F34" label="" v-model="offer.F34" symbol=""></lib-v-currency>
          </div>
          <div class="ml-1 caption"><span>kg éqCO2/m<sup>2</sup>/an</span></div>
          <div class="text-center ml-2 mr-2">
            <span :class="offer.EtiquEmissionGES" class="font-weight-black GES">{{ offer.EtiquEmissionGES }}</span>
          </div>
          <div class="ml-1 flex-grow-1">
            <v-text-field filled v-model="offer.S84" placeholder="Cas particulier"></v-text-field>
          </div>
        </div>
      </div>
    </div>
    <div>
      <span style="opacity:0">Cons : {{ classDPE }}</span>
    </div>
  </div>
</template>

<script>
  import { mask } from 'vue-the-mask'
  import { ValidationProvider} from 'vee-validate';
  export default {
    props: ['offer'],
    directives: {
      mask,
    },
    components: {
      ValidationProvider
    },
    data: () => ({
      dateMask: '##/##/####',
      error: false
    }),
    computed: {
      classDPE(){
        if (this.offer.B31 === 0){
          this.offer.EtiquConsommation = 'VI'
          this.offer.EtiquEmissionGES = 'VI'
        }
        if (this.offer.B31 === 1){
          this.offer.EtiquConsommation = 'NS'
          this.offer.EtiquEmissionGES = 'NS'
        }
        if (this.offer.B31 > 1){
          const dateDPE = this.offer.DateDiagDPE
          if ( dateDPE === undefined) this.$root.$alert("La Date de DPE n'est pas une date valide.", 'error')
          else {
            const _comp = this.$dpe.compareDPEDate(dateDPE)
            
            if (_comp) this.offer.B31=3; else this.offer.B31=2;
            const consom = this.$dpe.getDPEClass(_comp, this.offer.F33, this.offer.F34)
            
            this.error =  ((consom.E === " ") || (consom.GES === " "))
            this.offer.EtiquConsommation = consom.E
            this.offer.EtiquEmissionGES = consom.GES
          }
        }
        return 
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>