<template lang="html">
  <v-expansion-panel>
    <v-expansion-panel-header>
      <template v-slot:default="{ open }">
        <v-subheader class="subtitle-1 text-uppercase primary--text" v-if="open">Informations diagnostics</v-subheader>
        <v-subheader class="subtitle-1 text-uppercase" v-else>Informations diagnostics</v-subheader>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content class="diag__wrapper">
      <div class="diagnostic">
        <div class="row align-center justify-start flex-nowrap">
          <div class="ml-1">
            <i class="material-icons" v-if="offer.B33 > 0">check_box</i>
            <i class="material-icons" v-else>check_box_outline_blank</i>
          </div>
          <div class="ml-1 picto">
            <img src="@/assets/img/diags/amiante.jpg" alt="" />
            <span>Amiante</span>
          </div>
          <div class="ml-1">
            <p class="value tertiary">{{offer.D17}}</p>
          </div>
          <div class="ml-1 flex-grow-1">
            <p class="value tertiary">{{offer.S71}}</p>
          </div>
        </div>
        <div class="row align-center justify-start flex-nowrap">
          <div class="ml-1">
            <i class="material-icons" v-if="offer.B32 > 0">check_box</i>
            <i class="material-icons" v-else>check_box_outline_blank</i>
          </div>
          <div class="ml-1 picto">
            <img src="@/assets/img/diags/ERNMT.jpg" alt="" />
            <span>E.R.N.M.T</span>
          </div>
          <div class="ml-1">
            <p class="value tertiary">{{offer.DateDiagERNT}}</p>
          </div>
          <div class="ml-1 flex-grow-1">
            <p class="value tertiary">{{offer.PrestaDiagERNT}}</p>
          </div>
        </div>
        <div class="row align-center justify-start flex-nowrap">
          <div class="ml-1">
            <i class="material-icons" v-if="offer.B36 > 0">check_box</i>
            <i class="material-icons" v-else>check_box_outline_blank</i>
          </div>
          <div class="ml-1 picto">
            <img src="@/assets/img/diags/termites.jpg" alt="" />
            <span>Termites</span>
          </div>
          <div class="ml-1">
            <p class="value tertiary">{{offer.DateDiagElectrique}}</p>
          </div>
          <div class="ml-1 flex-grow-1">
            <p class="value tertiary">{{offer.PrestaDiagElectrique}}</p>
          </div>
        </div>
        <div class="row align-center justify-start flex-nowrap">
          <div class="ml-1">
            <i class="material-icons" v-if="offer.B38 > 0">check_box</i>
            <i class="material-icons" v-else>check_box_outline_blank</i>
          </div>
          <div class="ml-1 picto">
            <img src="@/assets/img/diags/carrez.jpg" alt="" />
            <span>Carrez</span>
          </div>
          <div class="ml-1">
            <p class="value tertiary">{{offer.D20}}</p>
          </div>
          <div class="ml-1 flex-grow-1">
            <p class="value tertiary">{{offer.S81}}</p>
          </div>
        </div>
        <div class="row align-center justify-start flex-nowrap">
          <div class="ml-1">
            <i class="material-icons" v-if="offer.B31 > 0">check_box</i>
            <i class="material-icons" v-else>check_box_outline_blank</i>
          </div>
          <div class="ml-1 picto">
            <img src="@/assets/img/diags/DPE.jpg" alt="" />
            <span>D.P.E</span>
          </div>
          <div class="ml-1">
            <p class="value tertiary">{{offer.DateDiagDPE}}</p>
          </div>
          <div class="ml-1 flex-grow-1">
            <p class="value tertiary">{{offer.PrestaDiagDPE}}</p>
          </div>
        </div>
        <component is="offers-dpe-view" :offer="offer"></component>
      </div>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
export default {
  props: ['offer']
}
</script>
