<template lang="html">
  <v-tab-item value="descriptif-technique">
    <v-container>
      <div class="row justify-center align-center">
        <div class="col col-md-2 col-sm-3 col-4">
          <lib-v-number label="Nombre d'étages" v-model="offer.I6" ></lib-v-number>
        </div>
        <div class="col col-md-2 col-sm-3 col-4">
          <lib-v-number label="Nombre de niveaux" v-model="offer.NombreNiveaux" ></lib-v-number>
        </div>
        <div class="col col-md-2 col-sm-3 col-4">
          <lib-v-number label="Nombre de lots" v-model="offer.NombrePieces" ></lib-v-number>
        </div>
      </div>
      <v-divider></v-divider>
      <div class="row justify-center align-center">
        <div class="col col-md-2 col-sm-3 col-4">
          <lib-v-currency id="F12" label="Surface totale" v-model="offer.F12" symbol="m2"></lib-v-currency>
        </div>
        <div class="col col-md-2 col-sm-3 col-4">
          <lib-v-currency id="F18" label="Surface H.O" v-model="offer.F18" symbol="m2"></lib-v-currency>
        </div>
        <div class="col col-md-2 col-sm-3 col-4">
          <lib-v-currency id="F14" label="Surface cadastrée" v-model="offer.F14" symbol="m2"></lib-v-currency>
        </div>
      </div>
      <div class="row justify-center align-center">
        <div class="col col-md-2 col-sm-3 col-4">
          <lib-v-currency id="F30" label="Surface utile" v-model="offer.F30" symbol="m2"></lib-v-currency>
        </div>
        <div class="col col-md-2 col-sm-3 col-4">
          <lib-v-currency id="F16" label="Surface habitation" v-model="offer.F16" symbol="m2"></lib-v-currency>
        </div>
        <div class="col col-md-2 col-sm-3 col-4">
          <lib-v-currency id="F31" label="Surface développée" v-model="offer.F31" symbol="m2"></lib-v-currency>
        </div>
      </div>
      <div class="row justify-center align-center">
        <div class="col col-md-2 col-sm-3 col-4">
          <lib-v-currency id="F17" label="Surface commerciale" v-model="offer.F17" symbol="m2"></lib-v-currency>
        </div>
        <div class="col col-md-2 col-sm-3 col-4">
          <lib-v-currency id="F15" label="Surface terrain" v-model="offer.F15" symbol="m2"></lib-v-currency>
        </div>
        <div class="col col-md-2 col-sm-3 col-4">
          <lib-v-currency id="F29" label="Surface jardin" v-model="offer.F29" symbol="m2"></lib-v-currency>
        </div>
      </div>
      <v-divider></v-divider>
      <div class="row justify-center align-center">
        <div class="ccol col-md-2 col-sm-3 col-4">
          <lib-v-number label="Park. ext." v-model="offer.NombrePkgExter" ></lib-v-number>
        </div>
        <div class="col col-md-2 col-sm-3 col-4">
          <lib-v-number label="Park. int." v-model="offer.NombrePkgInter" ></lib-v-number>
        </div>
        <div class="col col-md-2 col-sm-3 col-4">
          <lib-v-number label="Box/garages" v-model="offer.I12" ></lib-v-number>
        </div>
        <div class="col col-md-2 col-sm-3 col-4">
          <lib-v-number label="Caves" v-model="offer.NombreCaves" ></lib-v-number>
        </div>
      </div>
      <v-divider></v-divider>
      <div class="row justify-center align-center">
        <div class="col col-md-2 col-sm-3 col-4">
          <v-select :items="getTypeChauffage" v-model="offer.TypeChauffage" filled label="Type chauffage"></v-select>
        </div>
        <div class="col col-md-2 col-sm-3 col-4">
          <v-select :items="getNatureChauffage" v-model="offer.NatureChauffage" filled label="Nature chauffage"></v-select>
        </div>
        <div class="col col-md-2 col-sm-3 col-4">
          <v-select :items="getModeChauffage" v-model="offer.ModeChauffage" filled label="Mode chauffage"></v-select>
        </div>
        <div class="col col-md-2 col-sm-3 col-4">
          <v-text-field label="Eau chaude" filled v-model="offer.S48"></v-text-field>
        </div>
      </div>
      <div class="row justify-center align-center">
        <v-checkbox v-model.trim="offer.B15" label="Immeuble collectif" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
        <v-checkbox v-model.trim="offer.B17" label="Immeuble indépendant" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
      </div>

      <div class="row justify-center align-center">
        <v-checkbox v-model.trim="offer.IsAscenseur" label="Ascenseur" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
        <v-checkbox v-model.trim="offer.IsInterphone" label="Interphone" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
        <v-checkbox v-model.trim="offer.IsDigicode" label="Digicode" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
        <v-checkbox v-model.trim="offer.B14" label="Piscine" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
        <v-checkbox v-model.trim="offer.IsAccesHandicape" label="Accès handicapés" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
        <v-checkbox v-model.trim="offer.B16" label="Gardien" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
        <v-checkbox v-model.trim="offer.B40" label="Raccord T.A.E" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
      </div>

      <div class="row">
        <v-subheader class="text-uppercase subheader--text">Description de l'immeuble</v-subheader>
      </div>
      <div class="row">
        <div class="col col-12" >
          <v-textarea
            v-model="offer.NotesInternes" 
            placeholder="Description de l'immeuble ..."
            auto-grow
            filled
            rows="10"
          ></v-textarea>
        </div> 
      </div> 
    </v-container>
  </v-tab-item>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  props: ['offer'],
  computed: {
    ...mapGetters([
      'getTypeChauffage',
      'getNatureChauffage',
      'getModeChauffage'
    ])
  }
}
</script>
