//import Swal from 'sweetalert2'

export const FirstLetterUpper = (string) => {
  return string[0].toLocaleUpperCase + string.slice(1)
}

export const parseURL = url => {
  var parser = document.createElement('a')
  parser.href = url

  parser.protocol // => "http:"
  parser.hostname // => "example.com"
  parser.port     // => "3000"
  parser.pathname // => "/pathname/"
  parser.search   // => "?search=test"
  parser.hash     // => "#hash"
  parser.host     // => "example.com:3000"

  return parser
}

export const debug = (caption, msg) => {
  console.log('%c ' + caption, 'color: #9E9E9E; font-weight: bold', msg)
}

export const localStore = {
  setString: (item, value) => window.localStorage.setItem(item, value),
  getString: item => {
    let _str = window.localStorage.getItem(item)
    if (typeof _str !== 'undefined') {
      return _str
    } else {
      return null
    }
  },
  setObject: (item, obj) => window.localStorage.setItem(item, JSON.stringify(obj)),
  getObject: item => {
    let _object = window.localStorage.getItem(item)
    if (typeof _object !== 'undefined') {
      return JSON.parse(_object)
    } else {
      return null
    }
  },
  setBoolean: (item, _bool) => window.localStorage.setItem(item, _bool),
  getBoolean: item => {
    let _bool = window.localStorage.getItem(item)
    if (typeof _bool !== 'undefined') {
      return JSON.parse(_bool)
    } else {
      return false
    }
  }
}

export const tools = {
  getCurrentDate: () => (new Date()).toLocaleDateString(),
  getCurrentCountry: () => 'FRANCE'
}

export const Alerts = {
  infoAlert (title, text) {
    swal({
      title: title,
      text: text,
      type: 'success',
      confirmButtonClass: 'btn btn-success',
      buttonsStyling: false
    })
  },
  errorMessage (title, text) {
    swal({
      title: title,
      text: text,
      type: 'error',
      confirmButtonClass: 'btn btn-danger',
      buttonsStyling: true
    })
  },
  deleteAlert (index, title, text, confirmText, cancelText, callback) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        Swal.fire(
          'Deleted!',
          'Your file has been deleted.',
          'success'
        )
      }
    })
    /* Swal.fire({
      title: title,
      text: text,
      icon: 'error',
      showCancelButton: true,
      confirmButtonText: confirmText,
      cancelButtonText: cancelText,
      customClass: {
        confirmButton: 'btn btn-warning',
        cancelButton: 'btn btn-danger',
      }
    }).then(response => {
      console.log('  reponse ... ', response)
      //return true
    }) */
  },
  confirmAlert (title, text, confirmText) {
    return swal({
      title: title,
      text: text,
      type: 'question',
      showCancelButton: true,
      confirmButtonClass: 'btn btn-success',
      cancelButtonClass: 'btn btn-danger',
      confirmButtonText: confirmText,
      cancelButtonText: 'Non',
      buttonsStyling: false
    }).then((response) => {
      console.log('  confirmation ... ', response)
      return true
    }, (response) => {
      console.log('  Annuler ...', response)
      return false
    })
  },
  choixTypeOffre (title, text, confirmText) {
    return swal({
      title: title,
      text: text,
      imput: 'radio',
      type: 'question',
      showCancelButton: true,
      confirmButtonClass: 'btn btn-success',
      cancelButtonClass: 'btn btn-danger',
      confirmButtonText: confirmText,
      cancelButtonText: 'Non',
      buttonsStyling: false
    }).then((response) => {
      console.log('  confirmation ... ', response)
      return true
    }, (response) => {
      console.log('  Annuler ...', response)
      return false
    })
  }
}

export const showNotification = (from, align, type, title, message) => {
  // let type = ['', 'info', 'success', 'warning', 'danger', 'rose', 'primary']
  // let color = Math.floor((Math.random() * 6) + 1)
  window.$.notify({
    title: '<strong>' + title.toLocaleUpperCase() + '</strong>',
    message: message
  }, {
    type: type,
    timer: 1500,
    placement: {
      from: from,
      align: align
    }
  })
}

export const filterFloat = (value) => {
  if (/^(-|\+)?([0-9]+(\.[0-9]+)?|Infinity)$/.test(value)) {
    return Number(value)
  }
  return NaN
}

export const currencyValidator = {
  format: (number, decimals) => {
    return (Math.trunc(number * 100) / 100).toFixed(decimals)
  },
  parse: (newString, oldNumber) => {
    var CleanParse = function (value) {
      return { value: value }
    }
    var CurrencyWarning = (warning, value) => {
      return {
        warning: warning,
        value: value,
        attempt: newString
      }
    }
    var NotAValidDollarAmountWarning = function (value) {
      return new CurrencyWarning(newString + ' is not a valid dollar amount', value)
    }
    var AutomaticConversionWarning = function (value) {
      return new CurrencyWarning(newString + ' was automatically converted to ' + value, value)
    }

    var newNumber = Number(newString)
    var indexOfDot = newString.indexOf('.')
    var indexOfE = newString.indexOf('e')

    if (isNaN(newNumber)) {
      if (
        indexOfDot === -1 &&
        indexOfE > 0 &&
        indexOfE === newString.length - 1 &&
        Number(newString.slice(0, indexOfE)) !== 0
      ) {
        return new CleanParse(oldNumber)
      } else {
        return new NotAValidDollarAmountWarning(oldNumber)
      }
    }

    var newCurrencyString = currencyValidator.format(newNumber)
    var newCurrencyNumber = Number(newCurrencyString)

    if (newCurrencyNumber === newNumber) {
      if (indexOfE !== -1 && indexOfE === newString.length - 2) {
        return new AutomaticConversionWarning(newNumber)
      } else {
        return new CleanParse(newNumber)
      }
    } else {
      return new NotAValidDollarAmountWarning(
        newNumber > newCurrencyNumber
          ? newCurrencyNumber
          : oldNumber
      )
    }
  }
}
