<template lang="html">
  <v-expansion-panel>
      <v-expansion-panel-header>
        <template v-slot:default="{ open }">
          <v-subheader class="subtitle-1 text-uppercase primary--text" v-if="open">Informations juridiques</v-subheader>
          <v-subheader class="subtitle-1 text-uppercase" v-else>Informations juridiques</v-subheader>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <div class="row">
          <div class="col-5 col-md-2">
            <p  class="text-right">Gestionnaire :</p>
          </div>
          <div class="col-7 col-md-4">
            <p class="value tertiary">{{offer.S29}}</p>
          </div>
          <div class="col-5 col-md-2">
            <p  class="text-right">Syndic :</p>
          </div>
          <div class="col-7 col-md-4">
            <p class="value tertiary">{{offer.S26}}</p>
          </div>
        </div>
        <div class="row">
          <v-subheader class="text-uppercase subheader--text">Informations complémentaires</v-subheader>
          <div class="pl-3 text text-leftr text-uppercase tertiary">
            <br/>
            {{offer.CriteresComplementaires}}
            <br/>
          </div> 
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
export default {
  props: ['offer']
}
</script>
