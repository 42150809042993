<template lang="html">
  <v-tab-item value="descriptif-technique">
  <v-container>
    <div class="row align-center justify-center">
      <div class="col-6 col-md-4 col-lg-2">
        <lib-v-number label="Nombre de voitures" v-model="offer.I12" ></lib-v-number>
      </div>
    </div>
    <div class="row">
      <v-subheader class="text-uppercase subheader--text">Type de stationnement</v-subheader>
    </div>
    <div class="row align-center justify-center">
      <v-radio-group v-model.number="offer.I7" row  class="radio__group"> 
        <v-radio color="primary" label="Cote à cote" :value="0"></v-radio>
        <v-radio color="secondary" label="Superposé" :value="1"></v-radio>
        <v-radio color="success" label="Enfilade" :value="2"></v-radio>
      </v-radio-group>
    </div>

    <div class="row">
      <v-subheader class="text-uppercase subheader--text">Type d'accès</v-subheader>
    </div>
    <div class="row align-center justify-center">
      <v-radio-group v-model.number="offer.I8" row class="radio__group"> 
        <v-radio color="primary" label="Rampe" :value="0"></v-radio>
        <v-radio color="secondary" label="Ascenseur" :value="1"></v-radio>
      </v-radio-group>
    </div>
    <div class="row align-center justify-center">
      <div class="col-6 col-md-4">
        <lib-v-number label="Niveau" v-model="offer.Etage" ></lib-v-number>
      </div>
      <div class="col col-sm-2">
        <lib-v-number label="Nbre niveaux" v-model="offer.I6" ></lib-v-number>
      </div>
    </div>
    
    <div class="row">
      <v-subheader class="text-uppercase subheader--text">Accès piéton</v-subheader>
    </div>
    <div class="row align-center justify-center">
      <v-radio-group v-model.number="offer.I9" row class="radio__group"> 
        <v-radio color="primary" label="Escalier" :value="0"></v-radio>
        <v-radio color="secondary" label="Ascenseur" :value="1"></v-radio>
      </v-radio-group>
      <div class="col-12 col-m-6">
        <v-text-field label="Numéro" filled v-model="offer.S61"></v-text-field>
      </div>
    </div>
    
    
    <div class="row align-center justify-center">
      <div class="col col-md-2 col-sm-3 col-4">
        <lib-v-currency id="F7" label="Surface totale" v-model="offer.F12" symbol="m2"></lib-v-currency>
      </div>
      <div class="col col-md-2 col-sm-3 col-4">
        <lib-v-currency id="F15" label="Longueur" v-model="offer.F16" symbol="m"></lib-v-currency>
      </div>
      <div class="col col-md-2 col-sm-3 col-4">
        <lib-v-currency id="F7" label="Largeur" v-model="offer.F17" symbol="m"></lib-v-currency>
      </div>
      <div class="col col-md-2 col-sm-3 col-4">
        <lib-v-currency id="F17" label="Hauteur" v-model="offer.F18" symbol="m"></lib-v-currency>
      </div>
    </div>
    <div class="row align-center justify-center justify-center align-center">
      <v-checkbox v-model.trim="offer.B16" label="Gardiennage" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
      <v-checkbox v-model.trim="offer.IsAccesHandicape" label="Accès handicapé" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
      <v-checkbox v-model.trim="offer.B5" label="Vidéo surveillance" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
      <v-checkbox v-model.trim="offer.IsAscenseur" label="Ascenseur" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
      <v-checkbox v-model.trim="offer.IsInterphone" label="Bip" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
      <v-checkbox v-model.trim="offer.IsDigicode" label="Carte magnétique" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
    </div>

    <div class="row">
      <v-subheader class="text-uppercase subheader--text">Autres informations</v-subheader>
    </div>
    <div class="row">
      <div class="col col-12" >
        <v-textarea
          v-model="offer.NotesInternes" 
          placeholder="Informations complémentaires ..."
          auto-grow
          filled
          rows="10"
        ></v-textarea>
      </div> 
    </div>
    </v-container>
  </v-tab-item>
</template>

<script>

export default {
  props: ['offer']
}
</script>
