<template lang="html">
  <v-tab-item value="informations-agence">
    <v-container>
      <div class="row">
          <v-subheader class="text-uppercase subheader--text">Informations bailleur</v-subheader>
        </div>
      <div class="row align-center justify-center">
        <div class="col col-lg-2 col-md-2 col-sm-2 col-6">
          <v-text-field label="Bailleur" filled v-model="offer.NomProprietaire"></v-text-field>
        </div>
        <div class="col col-lg-2 col-md-2 col-sm-2 col-6">
          <v-text-field label="Téléphone" filled v-model="offer.TelProprietaire"></v-text-field>
        </div>
        <div class="col col-lg-3 col-md-6 col-12">
          <v-text-field label="Email" filled v-model="offer.EmailProprietaire"></v-text-field>
        </div>
      </div>
      <div class="row align-center justify-center" v-if="offer.TypeBien === '11' || offer.TypeBien === '12'">
        <v-radio-group v-model.number="offer.I28" row class="radio__group"> 
          <v-radio color="primary" label="Nouvelle gestion" :value="0"></v-radio>
          <v-radio color="secondary" label="Bien en gestion" :value="1"></v-radio>
          <v-radio color="success" label="Location sèche" :value="2"></v-radio>
        </v-radio-group>
      </div>
      <div class="row">
        <v-subheader class="text-uppercase subheader--text">Notes</v-subheader>
      </div>
      <div class="row">
        <div class="col col-12" >
          <v-textarea
            v-model="offer.NotesProprietaire"
            placeholder="Renseigner les infos : Origine de l'offre, Motif, Urgence ..."
            auto-grow
            filled
            rows="4"
          ></v-textarea>
        </div> 
      </div>
      <div class="row">
        <v-subheader class="text-uppercase subheader--text">Consigne de visite</v-subheader>
      </div>
      <div class="row align-center justify-center">
        <div class="col col-lg-1 col-md-2 col-sm-3 col-6">
          <lib-v-number label="N° Clé" v-model="offer.NombreCles"></lib-v-number>
        </div>
        <div class="col col-lg-1 col-md-2 col-sm-3 col-6">
          <v-text-field label="Digicode" filled v-model="offer.Digicode"></v-text-field>
        </div>
        <div class="col col-lg-1 col-md-6 col-12">
          <v-text-field label="Accès" filled v-model="offer.AccesVisite"></v-text-field>
        </div>
      </div>
      <div class="row">
        <v-subheader class="text-uppercase subheader--text">Commentaire visite</v-subheader>
      </div>
      <div class="row">
        <div class="col col-12" >
          <v-textarea
            v-model="offer.CommentaireVisite"
            placeholder="Renseigner les consignes pour la visite ..."
            auto-grow
            filled
            rows="4"
          ></v-textarea>
        </div> 
      </div>
    </v-container>
  </v-tab-item>
</template>

<script>
export default {
  props: ['offer']
}
</script>
