<template lang="html">
  <v-tab-item value="descriptif-technique">
    <v-container>
      <div class="row align-center justify-center">
        <div class="col col-md-2 col-sm-3 col-4">
          <lib-v-number label="Nombre de pièces" v-model="offer.NombrePieces" ></lib-v-number>
        </div>
        <div class="col col-md-2 col-sm-3 col-4">
          <lib-v-number label="Nombre de chambres" v-model="offer.NombreChambres" ></lib-v-number>
        </div>
        <div class="col col-md-2 col-sm-3 col-4">
          <lib-v-number label="Chambre service" v-model="offer.I25" ></lib-v-number>
        </div>
        <div class="col col-md-2 col-sm-3 col-4">
          <lib-v-number label="Nombre chambres RDC" v-model="offer.Etage" ></lib-v-number>
        </div>
      </div>
      <div class="row align-center justify-center">
        <div class="col col-md-2 col-sm-3 col-4">
          <lib-v-currency id="F7" label="Surface habitable" v-model="offer.F12" symbol="m2"></lib-v-currency>
        </div>
        <div class="col col-md-2 col-sm-3 col-4">
          <lib-v-currency id="F7" label="Surface carrez" v-model="offer.F14" symbol="m2"></lib-v-currency>
        </div>
        <div class="col col-md-2 col-sm-3 col-4">
          <lib-v-currency id="F7" label="Surface Terrain" v-model="offer.F15" symbol="m2"></lib-v-currency>
        </div>
      </div>
      <div class="row align-center justify-center">
        <div class="col col-md-2 col-sm-3 col-4">
          <lib-v-currency id="F15" label="Surface jardin" v-model="offer.F29" symbol="m2"></lib-v-currency>
        </div>
        <div class="col col-md-2 col-sm-3 col-4">
          <lib-v-currency id="F7" label="Surface séjour" v-model="offer.F16" symbol="m2"></lib-v-currency>
        </div>
        <div class="col col-md-2 col-sm-3 col-4">
          <lib-v-currency id="F7" label="Dépendance" v-model="offer.F17" symbol="m2"></lib-v-currency>
        </div>
      </div>
      <div class="row align-center justify-center">
        <div class="col col-md-2 col-sm-3 col-4">
          <v-select :items="getTypeChauffage" v-model="offer.TypeChauffage" filled label="Type chauffage"></v-select>
        </div>
        <div class="col col-md-2 col-sm-3 col-4">
          <v-select :items="getNatureChauffage" v-model="offer.NatureChauffage" filled label="Nature chauffage"></v-select>
        </div>
        <div class="col col-md-2 col-sm-3 col-4">
          <v-select :items="getModeChauffage" v-model="offer.ModeChauffage" filled label="Mode chauffage"></v-select>
        </div>
      </div>
      <div class="row align-center justify-center">
        <div class="col col-md-2 col-sm-3 col-4">
          <v-text-field label="Eau chaude" filled v-model="offer.S48"></v-text-field>
        </div>
        <div class="col col-md-2 col-sm-3 col-4">
          <v-select :items="EtatInterieur" v-model="offer.EtatInterieur" filled label="Etat intérieur"></v-select>
        </div>
        <div class="col col-md-2 col-sm-3 col-4">
          <v-select :items="getTypeCuisine" v-model="offer.TypeCuisine" filled label="Type cuisine"></v-select>
        </div>
      </div>
      <div class="row align-center justify-center">
        <div class="col col-md-2 col-sm-3 col-4">
          <!-- <v-text-field label="WC" filled v-model="offer.I7" class="vtext-right"></v-text-field> -->
          <lib-v-number label="WC" v-model="offer.I7" ></lib-v-number>
        </div>
        <div class="col col-md-2 col-sm-3 col-4">
          <lib-v-number label="Park. int." v-model="offer.NombrePkgInter" ></lib-v-number>
        </div>
        <div class="col col-md-2 col-sm-3 col-4">
          <lib-v-number label="Caves" v-model="offer.NombreCaves" ></lib-v-number>
        </div>
      </div>
      <div class="row align-center justify-center">
        <div class="col col-md-2 col-sm-3 col-4">
          <lib-v-number label="Salles de bains" v-model="offer.I8" ></lib-v-number>
        </div>
        <div class="col col-md-2 col-sm-3 col-4">
          <lib-v-number label="Park. ext." v-model="offer.NombrePkgExter" ></lib-v-number>
        </div>
        <div class="col col-md-2 col-sm-3 col-4">
          <lib-v-number label="Balcons" v-model="offer.NombreBalcons" ></lib-v-number>
        </div>
      </div>
      <div class="row align-center justify-center">
        <div class="col col-md-2 col-sm-3 col-4">
          <lib-v-number label="Salles d'eau" v-model="offer.I9" ></lib-v-number>
        </div>
        <div class="col col-md-2 col-sm-3 col-4">
          <lib-v-number label="Box" v-model="offer.I12" ></lib-v-number>
        </div>
        <div class="col col-md-2 col-sm-3 col-4">
          <lib-v-number label="Terrasses" v-model="offer.NombreTerrasses" ></lib-v-number>
        </div>
      </div>

      <div class="row align-center justify-center">
        <div class="col col-md-2 col-sm-3 col-4">
          <v-text-field label="Sous-sol" filled v-model="offer.S59" class="vtext-right"></v-text-field>
        </div>
        <div class="col col-md-2 col-sm-3 col-4">
          <lib-v-number label="Nombre niveaux" v-model="offer.NombreNiveaux" ></lib-v-number>
        </div>
        <div class="col col-md-2 col-sm-3 col-4">
          <lib-v-number label="Murs mitoyens" v-model="offer.I6" ></lib-v-number>
        </div>
      </div>
      
      <div class="row align-center justify-center justify-center align-center">
        <v-checkbox v-model.trim="offer.B15" label="Meublé" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
        <v-checkbox v-model.trim="offer.B17" label="Prestige" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
      </div>
      <div class="row align-center justify-center justify-center align-center">
        <v-checkbox v-model.trim="offer.B21" label="Installation gaz (+15 ans)" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
        <v-checkbox v-model.trim="offer.B35" label="Installation électrique (+15 ans)" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
        <v-checkbox v-model.trim="offer.B40" label="Raccord T.A.E" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
      </div>
      <div class="row align-center justify-center justify-center align-center">
        <v-checkbox v-model.trim="offer.IsInterphone" label="Interphone" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
        <v-checkbox v-model.trim="offer.IsDigicode" label="Alarme" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
        <v-checkbox v-model.trim="offer.B14" label="Piscine" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
        <v-checkbox v-model.trim="offer.IsAccesHandicape" label="Accès handicapés" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
        <v-checkbox v-model.trim="offer.IsAscenseur" label="Lotissement" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
        <v-checkbox v-model.trim="offer.B40" label="Raccord T.A.E" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
      </div>

      <div class="row">
        <v-subheader class="text-uppercase subheader--text">Description de l'immeuble</v-subheader>
      </div>
      <div class="row">
        <div class="col col-12" >
          <v-textarea
            v-model="offer.NotesInternes" 
            placeholder="Description de l'immeuble ..."
            auto-grow
            filled
            rows="10"
          ></v-textarea>
        </div> 
      </div>
    </v-container>
  </v-tab-item>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  props: ['offer'],
  computed: {
    ...mapGetters([
      'getTypeCuisine',
      'getTypeChauffage',
      'getNatureChauffage',
      'getModeChauffage'
    ])
  },
  data () {
    return {
      EtatInterieur: ['', 'A restaurer', 'Bon', 'Moyen', 'Passable'],
      EtageType: ['', 'RJ', 'RC', 'DE']
    }
  }
}
</script>
