import Vue from 'vue'
import axios from 'axios'
//import {API_ROOT} from './config'

const API_ROOT = process.env.VUE_APP_ROOT_API
const throwError = async (error) => {
  Vue.prototype.$alert(error, 'red')
  Vue.prototype.$myrouter.replace({ path: `/login`})
    
}
const serverRequest = async (method, url, data = {}) => {
  let error
  try {
    const token = Vue.prototype.$localStore.getString('token')
    if (token){
      const response = await axios({
        baseURL: API_ROOT,
        url,
        method, 
        data, 
        headers: {
          'Authorization':`Bearer ${token}`
        }
      })
      return response.data  
    } else {
      throwError('Votre session a expiré. Veuillez vous connecter de nouveau!');
    }
    
    
  } catch (_error) {
    if (_error.response && _error.response.status !== 200) {
      switch (_error.response.status) {
        case 0:
          error = ' Problème de connexion avec le serveur.\n Contactez votre administrateur. '
          break;
        case 401:
          error = ' Votre session a expiré. Veuillez vous connecter de nouveau! '
          break;
        default:
          error = _error.response.data
          break;
      }
    } else {
      error = "Problème de réseau ...Vérifiez votre connexion internet."
    }
    throwError(error)
    throw _error
  }
}

export default {
  authentification: async userInfo => {
    try {
      const response = await axios.post(API_ROOT + 'login', userInfo)
      return response.data  
    } catch (error) {
      throw error
    }
  },
  counts: async (accountId) => {
    try {
      return await serverRequest('POST', 'api/counts', {"account_id": accountId})
    } catch (error) {
      console.log('error :', error)
      throw error
    }
  },
  // Customer API service
  getCustomers: async (data) => {
    try {
      return await serverRequest('POST', 'api/customers', data)
    } catch (error) {
      throw error
    }
  },
  // Customer API service
  getCustomer: async (data) => {
    try {
      return await serverRequest('POST', 'api/customer', data)
    } catch (error) {
      throw error
    }
  },
  // User API service
  getUsers: async (data) => {
    try {
      return await serverRequest('POST', 'api/users', data)
    } catch (error) {
      throw error
    }
  },
  // User API service
  getUser: async (data) => {
    try {
      return await serverRequest('POST', 'api/user', data)
    } catch (error) {
      throw error
    }
  },
  // Account API service
  getAccount: async (data) => {
    try {
      return await serverRequest('POST', 'api/account', data)
    } catch (error) {
      throw error
    }
  },
  insertCustomer: async customer => {
    try {
      return await serverRequest('POST', 'api/newCustomer', customer)
    } catch (error) {
      throw error
    }
  },
  updateCustomer: async customer => {
    try {
      return await serverRequest('POST', 'api/updateCustomer', customer)
    } catch (error) {
      throw error
    }
  },
  removeCustomer: async payload => {
    try {
      return await serverRequest('POST', `api/deleteCustomer`, payload)
    } catch (error) {
      throw error
    }
  },
  // Offers API service
  getOffers: async data => {
    try {
      const response = await serverRequest('POST', `api/offers`, data)
      return response
    } catch (error) {
      throw error
    }
  },
  newOffer: async payload => {
    try {
      const response =  await serverRequest('POST', `api/newoffer/${payload.trans}`, payload.offer)
      return response
    } catch (error) {
      throw error
    }
  },
  getOffer: async payload => {
    try {
      return await serverRequest('POST', `api/getoffer/${payload.rubrique}`, payload.request)
    } catch (error) {
      throw error
    }
  },
  checkUpdateDateTime: async (_datetime) => {
    try {
      return await serverRequest('POST', `api/checkupdate`, _datetime)
    } catch (error) {
      throw error
    }   
  },
  saveOffer: async payload => {
    try {
      let query = `api/newOffer/${payload.trans}`
      if (payload.action === 'update') {
        query = `api/updateOffer/${payload.trans}`
      }
      
      return await serverRequest('POST', query, payload.offer)
    } catch (error) {
      console.log("saveOffer error :", error)
      alert(error.response.data)
      throw error
    }
  },
  removeOffer: async payload => {
    try {
      return await serverRequest('POST', `api/deleteoffer`, payload)
    } catch (error) {
      throw error
    }
  },
  // Get images of the offer
  getImageInfos: async offerId => {
    try {
      return await serverRequest('POST', '/api/imagesinfo/', {offerId})
    } catch (error) {
      throw error
    }
  },
  // Get images of the offer
  getImageInfoByOrder: async (offerId, order) => {
    try {
      return await serverRequest('GET', `/api/imageinfo/${offerId}/${order}`)
    } catch (error) {
      throw error
    }
  },
  getImage: async name => {
    try {
      const response = await serverRequest('GET', `/images/${name}`)
      var urlCreator = window.URL || window.webkitURL
      return urlCreator.createObjectURL(response)
    } catch (error) {
      throw error
    }
  },
  UploadImage: async data => {
    try {
      return await serverRequest('POST', `/api/upload`, data)
    } catch (error) {
      throw error
    }
  },
  // Delete image from the server
  deleteImage: async (image) => {
    // console.log('image:', image)
    try {
      return await serverRequest('POST', `/api/deleteimage`, image)
    } catch (error) {
      throw error
    } 
  },
  ReorderImages:async data => {
    try {
      return await serverRequest('POST', `/api/imagesreorder`, data)
    } catch (error) {
      throw error
    } 
  },
  geoLocalisationApi: async search => {
    // console.log('geoLocalisationApi =', search)
    try {
      const response = await axios({
        baseURL: 'https://api-adresse.data.gouv.fr',
        //baseURL: 'https://nominatim.openstreetmap.org',
        url: `/search/?q=${search}&limit=1`,
        //url: `/search?format=geojson&limit=1&q=${search}`,
        method: 'GET'
      })
      // console.log('response :', response)
      return response.data  
    } catch (_error) {
      if (_error.response && _error.response.status !== 200) {
        if (_error.response.status === 0) {
          error = ' Problème de connexion avec le serveur.\n Contactez votre administrateur. '
        } else {
          error = _error.response.data
        }
      } else {
        error = "Problème de réseau ...Vérifiez votre connexion internet."
      }
      // console.log('==> error :', error)
      throw _error
    }
  }
}
