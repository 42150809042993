<template lang="html">
      <v-form >
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <div v-show="!showUploader && !editable">
            <v-btn text class="info mr-3" @click.prevent="toggleUploader">
              <v-icon class="mr-1">mdi-camera-enhance-outline</v-icon>
              Ajouter des photos
            </v-btn>
            <v-btn text class="warning" @click.prevent="toggleReorder">
              <v-icon class="mr-1">mdi-camera-burst</v-icon>
              Réorganiser les photos
            </v-btn>
          </div>
        </v-toolbar>
        <v-card class="mx-auto grey lighten-2" flat v-show="showUploader" 
          @dragenter.prevent="OnDragEnter"
          @dragleave.prevent="OnDragLeave"
          @dragover.prevent
          @drop.prevent="OnDrop"
        >
          <div id="dropzone" class="dropzone dz-clickable" v-show="!ToUploadImages.length">
            <label class="dz-message" for="file-upload">
              <v-avatar width="100" height="100">
                <v-img src="../../assets/img/upload.png"></v-img>
              </v-avatar>
              <div class="display-1 mb-3">Faites glisser les fichiers ici</div>
              <v-chip label outlined color="blue">
                <v-icon left>mdi-paperclip </v-icon>
                Choisir des images
              </v-chip>
            </label>
          </div>
          <div v-show="ToUploadImages.length">
            <v-card-actions class="grey lighten-5">
              <v-btn color="success" outlined>
                <v-icon left>mdi-paperclip </v-icon>
                <label for="file-upload" class="success--text">
                  Choisir vos photos
                </label>
              </v-btn>
              <v-btn outlined color="info" @click.prevent="uploadToServer">
                <v-icon class="mr-1">mdi-cloud-upload-outline</v-icon>
                Envoyer au server
              </v-btn>
              <v-btn outlined color="warning" @click.prevent="toggleUploader">
                <v-icon class="mr-1">mdi-close-circle-outline</v-icon>
                Annuler
              </v-btn>
            </v-card-actions>
            <div class="upload--images" >
              <div class="img-wrapper" v-for="(image, index) in ToUploadImages" :key="index">
                <v-icon @click="deleteUploadImage(index)">mdi-close-circle-outline</v-icon>              
                <v-img :src="image" aspect-ratio="1"></v-img>
                <div class="image--details">
                  <span class= "name" v-text="files[index].name"></span>
                  <span class= "size" v-text="formatFileSize(files[index].size)"></span>
                </div>
              </div>
            </div>
          </div>
          <canvas id="canvas" width="800" height="600"></canvas> 
          <v-file-input
            ref="fileUpload"
            multiple
            id="file-upload"
            :rules="rules"
            accept="image/jpg, image/jpeg"
            label="File input"
            @change="fileUploadChange"
            style="display:none"
          >
          </v-file-input>
        </v-card>

        <v-bottom-navigation class="elevation-0" v-show="editable" color="success lighten-1">
          <v-btn value="recent" @click="saveOrders">
            <span class="success--text">Accepter</span>
            <v-icon class="success--text">mdi-checkbox-multiple-marked-outline</v-icon>
          </v-btn>
          <v-btn value="favorites" @click="editable=false">
            <span class="error--text">Abandonner</span>
            <v-icon class="error--text">mdi-close-box-multiple-outline</v-icon>
          </v-btn>
        </v-bottom-navigation>
        <draggable  v-model="images" v-bind="dragOptions" :move="onMove" :class="{ draggable: editable}" v-show="!showUploader">
          <transition-group name="no" tag="div" class="row" >
            <div
              v-for="(image, index) in images" :data-id="image.order"
              :key="image.order"
              class="d-flex child-flex col-6 col-sm-4 col-md-3 col-lg-2"
            >
              <v-hover v-slot:default="{ hover }">
                <v-card :class="{ 'on-hover': hover }" class="move-card ma-auto" >
                  Ordre : {{image.order}}
                  <v-img :src="$tools.getImage(accountId, image.name)" aspect-ratio="1" class="grey lighten-2">
                    <template v-slot:placeholder>
                      <div class="row align-center justify-center ma-0 fill-height">
                        <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                      </div>
                    </template>
                    <v-expand-transition >
                      <div  v-if="hover" class="delete-transition">
                        <v-btn text class=" white--text" @click.prevent="deleteImage(index)">
                          <v-icon class="mr-3">mdi-content-cut</v-icon>
                          Supprimer
                        </v-btn>
                      </div>
                    </v-expand-transition>
                  </v-img>
                </v-card>
              </v-hover>
            </div>
          </transition-group>
        </draggable>
      </v-form>
</template>

<script>
import api from '@/api'
//import {API_ROOT} from '@/api/config'
import Sortable from 'sortablejs'

import draggable from 'vuedraggable'

const API_ROOT = process.env.VUE_APP_ROOT_API

export default {
  name: 'upload',
  props: ['offerId', 'accountId', 'offerImages'],
  components: {
    Sortable,
    draggable
  },
  data: ()=> ({
    MAX_IMAGE_SIZE: 40000,
    rules: [
        value => !value || value.size < 40000 || `La taille de l'image ne doit pas dépasser 2 MB!`,
    ],
    showUploader: false,
    isDragging: false,
    dragCount: 0,
    files: [],
    ToUploadImages: [],
    
    dialog: false,
    showModal: false,
    loading: false,
    image: '',
    images: [],
    file: '',
    
    editable: false,
    ordering: false,
    options: {
      group: 'images',
      sort: true,
      dataIdAttr: 'data-id'
    },
    sortable: null
  }),
  methods: {
    // dragging images to dropzone
    OnDragEnter(e){
      this.dragCount++
      this.isDragging = true
      return false
    },
    OnDragLeave(e){
      this.dragCount--
      if (this.dragCount <= 0)
        this.isDragging = false
    },
    OnDrop(e){
      e.stopPropagation();
      
      this.isDragging = false

      const files = e.dataTransfer.files
      Array.from(files).forEach(file => this.addImage(file))
    },
    fileUploadChange(files){
      if (!files.length) {
        console.log('Pas de fichiers.')
        return
      } 
      Array.from(files).forEach(file => this.addImage(file))
    },
    isExists(aFile){
      return Array.from(this.files).find(file => {
        const compare = aFile.name.localeCompare(file.name)
        return (compare === 0)
      })
    },
    addImage(file){
      if (!file.type.match('image.*')){
        return
      }
      if (this.files && this.isExists(file)) {
        return
      }

      const reader = new FileReader()
      const image = new Image()

      reader.onload = (e) => {
        image.src = e.target.result
      }

      reader.onloadend = (e) => {
        if (file.size > this.MAX_IMAGE_SIZE){
          this.setCanvasImage(image)
          this.canvas.toBlob(blob => {
            blob.name = file.name
            blob.lastModified = Date.now()
            this.ToUploadImages.push(this.canvas.toDataURL('image/jpeg')) 
            this.files.push(blob)
          }, 'image/jpeg')
          
        } else {
          this.ToUploadImages.push(e.target.result)
          this.files.push(file)
        }
      }
      reader.readAsDataURL(file)
    },
    formatFileSize(size) {
      const fSExt = ['Octets', 'Ko', 'Mo', 'Go'];
      let i = 0;
      
      while(size > 900) {
          size /= 1024;
          i++;
      }
      return `${(Math.round(size * 100) / 100)} ${fSExt[i]}`;
    },
    getImageWidthHeight (image) {
      // define canvas
      let dimensions = {
        width: image.naturalWidth,
        height: image.naturalHeight
      }
      if (image.naturalWidth > image.naturalHeight) {
        if (image.naturalWidth > this.canvas.width) {
          let ratio = this.canvas.width / image.naturalWidth
          dimensions.height *= ratio
          dimensions.width = this.canvas.width
        }
      } else {
        if (image.naturalHeight > this.canvas.height) {
          let ratio = this.canvas.height / image.naturalHeight
          dimensions.width *= ratio
          dimensions.height = this.canvas.height
        }
      }
      return dimensions
    },
    setCanvasImage (image) {
      this.canvas = document.getElementById('canvas')
      this.ctx = this.canvas.getContext('2d')

      let dimensions = this.getImageWidthHeight(image)
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)
      var x = (this.canvas.width - dimensions.width) / 2
      var y = (this.canvas.height - dimensions.height) / 2
      this.ctx.drawImage(image, x, y, dimensions.width, dimensions.height)
    },
    deleteUploadImage(index) {
      this.files.splice(index, 1)
      this.ToUploadImages.splice(index, 1)
    },
    onMove({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext.element;
      const draggedElement = draggedContext.element;
      return (
        (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
      );
    },
    closeImageManager(){
      this.$parent.closeImageManager()
    },
    toggleUploader () {
      this.showUploader = !this.showUploader
    },
    toggleReorder () {
      this.editable = !this.editable
    },
    getImages () {
      let vm = this
      api.getImageInfos(this.offerId).then((response) => {
        vm.images = response
      }, (response) => {
        console.log(' Erreur :', response)
      })
    },
    sortImages(images){
      images.sort((a,b) => {
        return a.order - b.order
      })
      this.images = images

    },
    async uploadToServer () {
      const formData = new FormData()
      const _images = []
      formData.append('_id',  this.offerId)
      formData.append('account_id',  this.accountId)
      
      this.files.forEach(file => {
        formData.append('files', file, file.name)     
      })
      
      this.loading = true
      var config = {
        onUploadProgress: function(progressEvent) {
          var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
          document.getElementById('output').innerHTML = percentCompleted;
        }
      };
      try {
        const _images = await api.UploadImage(formData)
        this.sortImages(_images)
        this.showUploader = false
        this.loading = false
      } catch (error) {
        console.log(' Erreur :', error)
      }
    },
    async deleteImage (index) {
      let vm = this
      try {
        const _images = await api.deleteImage(this.images[index])
        if (_images.length > 0){
          this.sortImages(_images)
        }
      } catch (error) {
        console.log(' Erreur :', error)
      }
    },
    async saveOrders(){
      let _order = 0
      this.images.forEach(image => {
        _order++
        image.order = _order 
      })
      
      try {
        const response =  await api.ReorderImages(this.images)
        this.editable=false
      } catch (error) {
        throw error
      }
    },
    getImageById (_id) {
      let _index = _.findIndex(this.images, {'_id': _id})
      return _index
    }
  }, 
  computed:{
    dragOptions() {
      return {
        disabled: !this.editable,
        ghostClass: "ghost"
      };
    },
  },
  created(){
    this.images = this.offerImages
  }
}
</script>
<style lang="scss">
  .move-card {
    cursor: move;
    .delete-transition {
      position: absolute; 
      bottom: 0px; 
      height: 15%; 
      width:100%;
      background: rgba(0, 0, 0, .3);
      color: #fff;
    }
  }

  .select__image {
    &, * {
      cursor: pointer;
    }
  }

  .draggable {
    background-color: beige;
    .move-card {
      border:1px dashed #2196F3;
      .delete-transition {
        opacity: 0;
      }
      &:hover {
        border: 3px solid #2196F3;
      }
    }
  }

  .sortable-chosen {
    border: 3px solid #2196F3;
  }
  .ghost {
    opacity: 0.5;
    background: #c8ebfb;
  }

  .dropzone {
    font-family: Arial,sans-serif;
    letter-spacing: .2px;
    color: #777;
    background: white;
    min-height: 150px;
    border: 2px solid #e5e5e5;
    padding: 20px 20px;

    -webkit-transition: .2s linear;
    transition: .2s linear;

    &, & * {
      box-sizing: border-box;
    }

    &.dropzone.dz-clickable {
      cursor: pointer;
    }

    .dz-message {
      display: block;
      text-align: center;
      margin: 2em 0;
    }
  }

  .upload--images {
    display: flex;
    flex-wrap: wrap;
    padding: 20px;

    .img-wrapper{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 160px;
      padding: 5px;
      margin: 5px;

      background-color: #fff;
      box-shadow: 5px 5px 20px #3e3737;
    
      .image--details {
        font-size: 12px;
        color: #333;
        display: flex;
        flex-direction: column;
        align-items: self-start;
        padding: 3px 6px;
        margin-top: 5px; 
        border-top: 1px solid #3e3737;
        .name {
          text-align: justify;
        }
        .size {
          font-weight: 800;
        }
      }
      .v-icon {
        opacity: 0;
        position: relative;
        top:0px;
        left: 125px;
        width: 25px;
        z-index: 10000;
        color: #000000;
      }
      &:hover {
        border: 1px solid #999;
        .v-icon {
          opacity: 0.8;
          cursor: pointer;
        }
      }
    }
  }

  canvas {
    display: none;
  }
</style>