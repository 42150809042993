
class PdfLib {
  offer = []
  $tools = null
  constructor(tools, offer){
    this.offer = offer
    this.$tools = tools
  }

  offerImage (index) {
    let result = '/static/img/noimage.jpg'
    //console.log('offer :', this.offer)
    if (this.offer.images) {
      const imagesNumber = this.offer.images.length
      if (index < imagesNumber) {
        result = this.$tools.getImage(this.offer.account_id, this.offer.images[index].name) // Important : CORS issue
      }
    }
    return result
  }

  getEnergieLevel(){
    let level = 'a'
    if (this.offer.F33 && this.offer.F34) {
      level = this.$dpe(this.offer.F33, this.offer.F34).toLowerCase()
    }
    return level
  }

  isPrintDiag(){
    //console.log('this.offer.TypeBien =', this.offer.TypeBien)
    return (!['3', '6', '7', '14'].includes(this.offer.TypeBien))
  }

  async printDiag(doc, posX, posY, idPage){
    const diagImageWidth = 160
    const diagImageHeight = 14
    if ((idPage > 0) && (this.offer.B31 > 1)) {
      const diag_imag = await this.$tools.loadImageFromURL(`/static/img/conso/DPE-${this.offer.EtiquConsommation}.png`)
    }
    doc.setPage(idPage+1);
    if (this.isPrintDiag()){
        // diag
        doc.setTextColor(55, 55, 55);
        doc.setFontSize(13)
        doc.text('Diagnostics énergétiques :' + this.offer.EtiquConsommation , posX, posY)
        posY += 20
        if (this.offer.EtiquConsommation === " "){
          doc.setTextColor(250, 0, 0);
          doc.text("ATTENTION: DPE NON VALIDE", posX, posY+5)
        } else {
          if (this.offer.B31 > 1) {
            doc.setFontSize(8)
            doc.text("Diagnostic de performance énergétique (DPE)", posX, posY)
            doc.text("Indice d'émission de gaz à effet de serre (GES)", posX, posY+100)

            doc.setFont("Roboto-Regular"); // set font
            
            // DPE
            doc.addImage(`/static/img/conso/DPE-${this.offer.EtiquConsommation}.png`, "PNG", posX,  posY+5, diagImageWidth, diagImageHeight);
            doc.text(`Valeur : ${this.offer.F33} kWh EP/m²/an`, posX, posY+35)

            // GES
            doc.addImage(`/static/img/conso/GES-${this.offer.EtiquEmissionGES}.png`, "PNG", posX, posY+105 , diagImageWidth, diagImageHeight, null, "FAST");
            doc.text(`Valeur : ${this.offer.F34} kg éqCO2/m²/an`, posX, posY+130)
          } 
          
          doc.setFont("Roboto-Regular"); // set font
          
          //
          switch(this.offer.B31) {
            case 0 :
              doc.setFontSize(13)
              doc.text('DPE Vierge', posX+20, posY+30)
              break
            case 1 : 
              doc.setFontSize(13)
              doc.text('Non soumis au DPE', posX+20, posY+30)
              break
            case 2 : 
              doc.text('DPE avant le 01 Juillet 2021', posX, posY+55)
              break
            case 3 : 
              doc.text('DPE après le 01 Juillet 2021', posX, posY+55)
              break
          }
        }
      }
  }

  formatPrice(x) {
    //return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ");
    return x.toString()
  }

  printInfosComplementaires(doc, myLayout, posY){
    doc.setFont("Roboto-Regular");
    let honoraires = "Honoraires à la charge du vendeur"
    if (this.offer.I29 === 1) {
      honoraires = "Honoraires à la charge de l'aquéreur"
    }

    let procedure = `Procédure en cours: ${this.offer.S52}`
    const info_complementaires = `Charges annuelles: ${this.formatPrice(this.offer.F10)} €, Nombre de lots principaux : ${this.offer.S40},  ${honoraires},  ${procedure}`
    //doc.text(info_complementaires, posX, posY)
    this.writeText(doc, myLayout.x, posY , info_complementaires, { width: myLayout.w, color: {r:0, g:0, b:0}, align: 'center' });
  }

  writeText (doc, x, y, text, options) {
    options = options || {};

    var defaults = {
      align: 'left',
      width: doc.internal.pageSize.width
    }

    var settings = {...defaults, ...options};

    //console.log('settings:', settings, 'text:', text)
    // Get current font size
    var fontSize = doc.internal.getFontSize();

    // Get the actual text's width
    var txtWidth = doc.getStringUnitWidth(text) * fontSize / doc.internal.scaleFactor;

    
    if (settings.align === 'center')
      x += (settings.width - txtWidth) / 2;
    else if (settings.align === 'right')
      x += (settings.width - txtWidth);

    doc.setTextColor(settings.color.r, settings.color.g, settings.color.b)
    const textArray = text.split('-')
    if (textArray.length > 1) {
        doc.text(textArray[0], x + 30, y, null, settings.orientation);
        doc.setFontSize(20)
        doc.setTextColor('black')
        doc.text(x + (txtWidth/2) + 10 , y, textArray[1]);
    } else 
      doc.text(text, x, y, null, settings.orientation);
  }
  
}

export { PdfLib }