<template lang="html">
  <v-tab-item value="informations-diagnostics" class="pa-2 diag__wrapper">
    <div class="diagnostic">
      <div class="row align-center justify-start flex-nowrap">
        <div class="ml-1">
          <v-checkbox name="amiante" v-model.trim="offer.B33" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
        </div>
        <div class="ml-1 picto">
          <img src="@/assets/img/diags/amiante.jpg" alt="" />
          <span>Amiante</span>
        </div>
        <div class="ml-1">
          <v-text-field filled name="date_amiante" v-model="offer.D17" v-mask="'##/##/####'" placeholder="JJ/MM/AAAA"></v-text-field>
        </div>
        <div class="ml-1 flex-grow-1">
          <v-text-field filled name="prestat_amiante" v-model.trim="offer.S71" placeholder="Commentaires"></v-text-field>
        </div>
      </div>
      <div class="row align-center justify-start flex-nowrap">
        <div class="ml-1">
            <v-checkbox name="ERNMT" v-model.trim="offer.B32" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
        </div>
        <div class="ml-1 picto">
          <img src="@/assets/img/diags/ERNMT.jpg" alt="" />
          <span>E.R.N.M.T</span>
        </div>
        <div class="ml-1">
          <v-text-field filled name="date_ERNMT" v-model="offer.DateDiagERNT" v-mask="'##/##/####'" placeholder="JJ/MM/AAAA"></v-text-field>
        </div>
        <div class="ml-1 flex-grow-1">
          <v-text-field filled name="presta_ERNT" v-model.trim="offer.PrestaDiagERNT" placeholder="Commentaires"></v-text-field>
        </div>
      </div>
      <div class="row align-center justify-start flex-nowrap">
        <div class="ml-1">
          <v-checkbox name="Termites" v-model.trim="offer.B36" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
        </div>
        <div class="ml-1 picto">
          <img src="@/assets/img/diags/termites.jpg" alt="" />
          <span>Termites</span>
        </div>
        <div class="ml-1">
          <v-text-field filled name="date_termites" v-model="offer.DateDiagElectrique" v-mask="'##/##/####'" placeholder="JJ/MM/AAAA"></v-text-field>
        </div>
        <div class="ml-1 flex-grow-1">
          <v-text-field filled name="presta_termites" v-model.trim="offer.PrestaDiagElectrique" placeholder="Commentaires"></v-text-field>
        </div>
      </div>
      <div class="row align-center justify-start flex-nowrap">
        <div class="ml-1">
          <v-checkbox name="Carrez" v-model.trim="offer.B38" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
        </div>
        <div class="ml-1 picto">
          <img src="@/assets/img/diags/carrez.jpg" alt="" />
          <span>Carrez</span>
        </div>
        <div class="ml-1">
          <v-text-field filled name="date_carrez" v-model="offer.D20" v-mask="'##/##/####'" placeholder="JJ/MM/AAAA"></v-text-field>
        </div>
        <div class="ml-1 flex-grow-1">
          <v-text-field filled name="presta_carrez" v-model.trim="offer.S81" placeholder="Commentaires"></v-text-field>
        </div>
      </div>
    </div>
    <component is="offers-dpe-update" :offer="offer"></component>
  </v-tab-item>
</template>

<script>
import { mask } from 'vue-the-mask'
export default {
  props: ['offer'],
  directives: {
    mask
  }
}
</script>
