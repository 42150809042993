<template lang="html">
  <v-expansion-panel>
      <v-expansion-panel-header>
        <template v-slot:default="{ open }">
          <v-subheader class="subtitle-1 text-uppercase primary--text" v-if="open">Conditions financières</v-subheader>
          <v-subheader class="subtitle-1 text-uppercase" v-else>Conditions financières</v-subheader>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
      <div class="row">
        <div class="col-7 col-md-2 pl-0">
          <p class="text-right">Loyer mensuel C.C/TTC:</p>
        </div>
        <div class="col-5 col-md-2">
          <p class="value number text-right error--text">{{offer.Price.toLocaleString()}} €</p>
        </div>
        <div class="col-7 col-md-2">
          <p class="text-right">Montant reprise.:</p>
        </div>
        <div class="col-5 col-md-2">
          <p class="value text-right tertiary">{{offer.F13.toLocaleString()}} €</p>
        </div>
      </div>
      <div class="row">
        <div class="col-7 col-md-2">
          <p class="text-right">Loyer mensuel H.C/H.T:</p>
        </div>
        <div class="col-5 col-md-2">
          <p class="value text-right tertiary">{{offer.F1.toLocaleString()}} € /m<sup>2</sup></p>
        </div>
        <div class="col-7 col-md-2">
          <p class="text-right">Loyer annuel H.C/H.T:</p>
        </div>
        <div class="col-5 col-md-2">
          <p class="value text-right tertiary">{{offer.F2.toLocaleString()}} € /m<sup>2</sup></p>
        </div>
      </div>
      <div class="row">
        <div class="col-7 col-md-2">
          <p class="text-right">Prov. charges mensuelle:</p>
        </div>
        <div class="col-5 col-md-2">
          <p class="value text-right tertiary">{{offer.F5.toLocaleString()}} € /m<sup>2</sup>/an</p>
        </div>
        <div class="col-7 col-md-2">
          <p class="text-right">Prov. charges annuelle:</p>
        </div>
        <div class="col-5 col-md-2">
          <p class="value text-right tertiary">{{offer.F6.toLocaleString()}} € /m<sup>2</sup>/an</p>
        </div>
      </div>
      <div class="row">
        <div class="col-7 col-md-2">
          <p class="text-right">Parking intérieur :</p>
        </div>
        <div class="col-5 col-md-2">
          <p class="value text-right tertiary">{{offer.F10.toLocaleString()}} € /unité/an</p>
        </div>
        <div class="col-7 col-md-2">
          <p class="text-right">Parking extérieur :</p>
        </div>
        <div class="col-5 col-md-2">
          <p class="value text-right tertiary">{{offer.F11.toLocaleString()}} € /unité/an</p>
        </div>
      </div>
      <div class="row">
        <div class="col-7 col-md-2">
          <p class="text-right">Cession droit bail :</p>
        </div>
        <div class="col-5 col-md-2">
          <p class="value text-right tertiary">{{offer.F20.toLocaleString()}} €</p>
        </div>
        <div class="col-7 col-md-2">
          <p class="text-right">Pas de cession de bail :</p>
        </div>
        <div class="col-5 col-md-2">
            <v-icon left color="success" v-if="offer.B25 > 0">mdi-checkbox-marked-outline</v-icon>
            <v-icon left v-else>mdi-checkbox-blank-outline </v-icon>
        </div>
      </div>
      <div class="row">
        <v-subheader class="subtitle-1 text-uppercase text-center">Conditions de bail</v-subheader>
      </div>
      <div class="row">
        <div class="col-7 col-md-2">
          <p class="text-right">Dépôt de garantie :</p>
        </div>
        <div class="col-5 col-md-2">
          <p class="value text-right tertiary">{{offer.Proximite}} €</p>
        </div>
        <div class="col-7 col-md-2">
          <p class="text-right">Taux honoraires :</p>
        </div>
        <div class="col-5 col-md-2">
          <p class="value text-right tertiary">{{offer.TauxHonoraires}} % loy. annuel HT/HC</p>
        </div>

      </div>
      <div class="row">
        <div class="col-7 col-md-2">
          <p class="text-right">Forfait honoraires H.T :</p>
        </div>
        <div class="col-5 col-md-2">
          <p class="value text-right tertiary">{{offer.F3}} €</p>
        </div>
        <div class="col-7 col-md-2">
          <p class="text-right">Règlement :</p>
        </div>
        <div class="col-5 col-md-2">
          <p class="value text-right tertiary">{{offer.S52}}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-7 col-md-2">
          <p class="text-right">Charge honoraires :</p>
        </div>
        <div class="col-5 col-md-6">
          <p class="value number tertiary">{{offer.S48}}</p>
        </div>
      </div>
      <div class="row">
        <v-subheader class="subtitle-1 text-uppercase text-center">Fiscalité</v-subheader>
      </div>
      <div class="row">
        <div class="col-6 col-md-2">
          <p class="text-right">Régime fiscal :</p>
        </div>
        <div class="col-6 col-md-6">
          <p class="value text-right tertiary">{{offer.S29}}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-6 col-md-2">
          <p class="text-right">Taux Taxe prof. :</p>
        </div>
        <div class="col-6 col-md-2">
          <p class="value text-right tertiary">{{offer.F16}} %</p>
        </div>
        <div class="col-6 col-md-2">
          <p class="text-right">Taux TVA :</p>
        </div>
        <div class="col-6 col-md-2">
          <p class="value text-right tertiary">{{offer.F14}} %</p>
        </div>
        <div class="col-12 col-md-2">
          <div class="ml-4">
              <v-icon left v-if="offer.B24 > 0">mdi-checkbox-marked-outline</v-icon>
              <v-icon left v-else>mdi-checkbox-blank-outline </v-icon>
              Net de taxe.
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6 col-md-2">
          <p class="text-right">Impôt foncier :</p>
        </div>
        <div class="col-6 col-md-2">
          <p class="value text-right tertiary">{{offer.F9}} € /m<sup>2</sup>/an</p>
        </div>
        <div class="col-6 col-md-2">
          <p class="text-right">Taxe bureau :</p>
        </div>
        <div class="col-6 col-md-2">
          <p class="value text-right tertiary">{{offer.F14}} € /m<sup>2</sup>/an</p>
        </div>
        <div class="col-12 col-md-12">
          <div class="ml-4">
              <v-icon left color="success" v-if="offer.B23 > 0">mdi-checkbox-marked-outline</v-icon>
              <v-icon left v-else>mdi-checkbox-blank-outline </v-icon>
              Impôt foncier à la charge du bailleur.
          </div>
        </div>
      </div>
      </div>
     </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
import { currencyValidator } from '@/utils/utils'

export default {
  props: ['offer'],
  computed: {
    TotalTTC: function () {
      return currencyValidator.format(1 * this.offer.F25 + 1 * this.offer.F26, 2)
    }
  }
}
</script>
