<template>
  <v-card flat class="print-page">
    <div class="ma-auto text-center headline text-uppercase">
      Imprimer une offre
    </div>  
    <div align="center" justify="center">
      <iframe id="iframe" class="frame"></iframe>
    </div>
        
    
  </v-card>
</template>
<script>
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
//import {API_ROOT} from '@/api/config'
import {mapGetters} from 'vuex'
import RobotoRegular from '@/assets/fonts/roboto/Roboto-Regular.js'
import RobotoBlack from '@/assets/fonts/roboto/Roboto-Black.js'

const API_ROOT = process.env.VUE_APP_ROOT_API

window.html2canvas = html2canvas

export default {
  name: 'app',
  props:['offer','trans'],
  data:()=>({
    interligne: 20,
    disponibilite: ['Libre', 'Loué', 'Occupé']
  }),
  mounted() {
    window.html2canvas = html2canvas
    this.html2pdf()
  },
  computed: {
    ...mapGetters([
      'getTypeBiens',
      'getFilterData',
      'getAccount'
    ])
  },
  methods: {
     offerImage (index) {
      let result = '/static/img/white.png'
      if (this.offer.images) {
        const imagesNumber = this.offer.images.length
        if (index < imagesNumber) {
          result = this.$tools.getPdfImage(this.offer.account_id, this.offer.images[index].name) // Important : CORS issue
        }
      }
      return result
    },
    getEnergieLevel(){
      let level = 'a'
      if (this.offer.EtiquConsommation) {
        level = this.offer.EtiquConsommation.toLowerCase()
      }
      return level
    },
    
    formatPrice(x) {
      var formatter = new Intl.NumberFormat('fr-FR', {
        style: 'currency',
        currency: 'EUR',

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });

      //return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ");
      //return x.toString()
      return formatter.format(x)
    },
    writeTitle(doc, x, y, titleText){
      doc.setFont("Roboto-Black");
      doc.setFontSize(12);
      doc.text(x, y, titleText.toUpperCase())
      doc.setFont("Roboto-Regular");
      doc.setFontSize(11);
      
    },
    writeText (doc, x, y, text, options) {
      options = options || {};

      var defaults = {
        align: 'left',
        width: doc.internal.pageSize.width
      }

      var settings = {...defaults, ...options};

      // Get current font size
      var fontSize = doc.internal.getFontSize();

      // Get the actual text's width
      /* You multiply the unit width of your string by your font size and divide
      * by the internal scale factor. The division is necessary
      * for the case where you use units other than 'pt' in the constructor
      * of jsPDF.
      */
      var txtWidth = doc.getStringUnitWidth(text) * fontSize / doc.internal.scaleFactor;

      if (settings.align === 'center')
        x += (settings.width - txtWidth) / 2;
      else if (settings.align === 'right')
        x += (settings.width - txtWidth);

      doc.setTextColor(settings.color.r, settings.color.g, settings.color.b)
      //default is 'left' alignment
      doc.text(text, x, y, null, settings.orientation);

    },
    writeFieldText(doc, posX, posY, fieldName, fieldValue){
      var fontSize = doc.internal.getFontSize();
      doc.setFont("Roboto-Regular");
      doc.text(posX, posY, fieldName);
      var fielNameWidth = doc.getStringUnitWidth(fieldName) * fontSize / doc.internal.scaleFactor;
      doc.setFont("Roboto-Black");
      if (fieldValue !== undefined)
        doc.text(posX + fielNameWidth, posY, fieldValue);
      
    },
    writeCheckBox(doc, posX, posY, fieldName, isDispaly, offsetX){
      if (isDispaly){
        //const image0 = await this.loadImageFromURL()
        let image = new Image()
        image.src = '/static/img/icons/checkbox-marked-outline.jpg'
        doc.addImage(image, "jpg", posX, posY-10, 12, 12);
        doc.text(posX+15, posY, fieldName)
        return offsetX
      } else {
        return 0
      }
    },
    async print_rents_appartement(doc, origX, origY) {
      // Informations générales
      let posX = origX
      let posY = origY + 1.5 * this.interligne
      this.writeTitle(doc, posX, posY, 'Informations générales'.toUpperCase())
      posY += this.interligne
      this.writeFieldText(doc, posX, posY, 'Catégorie : ', this.offer.Category)
      posX += 180
      this.writeFieldText(doc, posX, posY, 'Date libération : ', this.offer.DateLiberation)
      if (this.offer.B15){
        posX += 180
        posX += this.writeCheckBox(doc, posX, posY, 'Meublé', (this.offer.B15 > 0), 0)
      }
      // Conditions financières
      posX = origX
      posY += 1.5 * this.interligne
      this.writeTitle(doc, posX, posY, 'Conditions financières')

      posY += this.interligne
      this.writeFieldText(doc, posX, posY, 'Loyer charges comprises : ', `${this.formatPrice(this.offer.Price)}`)
      posX += 210
      this.writeFieldText(doc, posX, posY, 'Loyer hors charges  : ', `${this.formatPrice(this.offer.F1)}`)
      
      posX = origX
      posY += this.interligne
      this.writeFieldText(doc, posX, posY, 'Provision sur charges : ', `${this.formatPrice(this.offer.F3)}`)
      posX += 210
      this.writeFieldText(doc, posX, posY, 'Dépôt de garantie : ', `${this.formatPrice(this.offer.F10)}`)

      posX = origX
      posY += this.interligne
      this.writeFieldText(doc, posX, posY, 'Honoraires agence : ', `${this.formatPrice(this.offer.F25)}`)

      // Descriptif technique
      posX = origX
      posY += 1.5 * this.interligne
      this.writeTitle(doc, posX, posY, 'Descriptif technique'.toUpperCase())
      posY += this.interligne
      this.writeFieldText(doc, posX, posY, 'Nombre de pièces : ', `${this.offer.NombrePieces}`)
      posX += 115
      this.writeFieldText(doc, posX, posY, '/  Nombre de chambres : ', `${this.offer.NombreChambres}`)
      posX += 140
      this.writeFieldText(doc, posX, posY, '/  Etage : ', `${this.offer.Etage} sur ${this.offer.NombreNiveaux}`)
      
      // Surface
      posX = origX
      posY += this.interligne
      this.writeFieldText(doc, posX, posY, 'Surface habitable : ', `${this.offer.F12} m2`)
      posX += 155
      this.writeFieldText(doc, posX, posY, '/  Surface jardin : ', `${this.offer.F15} m2`)
      posX += 140
      this.writeFieldText(doc, posX, posY, '/  Surface séjour : ', `${this.offer.F16} m2`)
      
      // Chauffage
      posX = origX
      posY += this.interligne
      this.writeFieldText(doc, posX, posY, 'Type chauffage : ', `${this.offer.TypeChauffage}`)
      posX += 220
      this.writeFieldText(doc, posX, posY, '/  Nature chauffage : ', `${this.offer.NatureChauffage}`)
      posX += 170
      this.writeFieldText(doc, posX, posY, '/  Mode chauffage : ', `${this.offer.ModeChauffage}`)
      
      // Eau chaude / type cuisine
      posX = origX
      posY += this.interligne
      this.writeFieldText(doc, posX, posY, 'Eau chaude : ', `${this.offer.S48}`)
      posX += 220
      this.writeFieldText(doc, posX, posY, '/  Type cuisine : ', `${this.offer.TypeCuisine}`)
      
      // WC / Salles de bain etc...
      posX = origX
      posY += this.interligne
      this.writeFieldText(doc, posX, posY, 'WC : ', `${this.offer.I7}`)
      posX += 60
      this.writeFieldText(doc, posX, posY, '/  Salles de bains : ', `${this.offer.I8}`)
      
      // Balcons / Terasses...
      posX = origX
      posY += this.interligne
      this.writeFieldText(doc, posX, posY, 'Balcons : ', `${this.offer.NombreBalcons}`)
      posX += 70
      this.writeFieldText(doc, posX, posY, '/  Terrasses : ', `${this.offer.NombreTerrasses}`)
      posX += 80
      this.writeFieldText(doc, posX, posY, '/  Caves : ', `${this.offer.NombreCaves}`)
      
      // Parkings...
      posX = origX
      posY += this.interligne
      this.writeFieldText(doc, posX, posY, 'Parkings intérieurs : ', `${this.offer.NombrePkgInter}`)
      posX += 120
      this.writeFieldText(doc, posX, posY, '/  Parkings extérieurs : ', `${this.offer.NombrePkgExter}`)
      posX += 140
      this.writeFieldText(doc, posX, posY, '/  Box : ', `${this.offer.I12}`)
      
      // Options
      doc.setFont("Roboto-Regular");
      posX = origX
      posY += 1.5 * this.interligne
      posX += this.writeCheckBox(doc, posX, posY, 'Gardiennage', (this.offer.B16 > 0), 95)
      
      posX += this.writeCheckBox(doc, posX, posY, 'Interphone', (this.offer.B17 > 0), 80)
      
      posX += this.writeCheckBox(doc, posX, posY, 'Vidéophone', (this.offer.B18 > 0), 90)

      posX += this.writeCheckBox(doc, posX, posY, 'Digicode', (this.offer.B19 > 0), 80)

      posX += this.writeCheckBox(doc, posX, posY, 'Espaces verts', (this.offer.B20 > 0), 95)

      posX += this.writeCheckBox(doc, posX, posY, 'Ascenseur', (this.offer.B21 > 0), 0)
      
      posX = origX
      posY += 1.5 * this.interligne
      posX += this.writeCheckBox(doc, posX, posY, 'Antenne', (this.offer.B22 > 0), 75)

      posX += this.writeCheckBox(doc, posX, posY, 'Cable', (this.offer.B23 > 0), 60)

      posX += this.writeCheckBox(doc, posX, posY, 'Chauffage', (this.offer.B24 > 0), 80)

      posX += this.writeCheckBox(doc, posX, posY, 'Eau chaude', (this.offer.B25 > 0), 80)

      posX += this.writeCheckBox(doc, posX, posY, 'Eau froide', (this.offer.B26 > 0), 80)

      posX += this.writeCheckBox(doc, posX, posY, 'Piscine', (this.offer.B14 > 0), 60)
      
      posX += this.writeCheckBox(doc, posX, posY, 'Accès handicapés', (this.offer.IsAccesHandicape > 0), 0)

      posX = origX
      posY += 1.5 * this.interligne
      this.writeTitle(doc, posX, posY, 'Texte annonce'.toUpperCase())
      posY += this.interligne
      doc.setFontSize(12);
      doc.setLineHeightFactor(2)
      var splitText = doc.splitTextToSize(this.offer.TexteAnnonce, 545);
      doc.text(posX, posY, splitText);
      
    },
    async print_rents_maison(doc, origX, origY) {
      let posX = origX
      let posY = origY
      
      posY += 1.5 * this.interligne
      this.writeTitle(doc, posX, posY, 'Informations générales'.toUpperCase())
      posY += this.interligne
      this.writeFieldText(doc, posX, posY, 'Catégorie : ', this.offer.Category)
      posX += 180
      this.writeFieldText(doc, posX, posY, 'Date libération : ', this.offer.DateLiberation)
      posX += 180
      posX += this.writeCheckBox(doc, posX, posY, 'Meublé', (this.offer.B15 > 0), 0)
      
      // Conditions financières
      posX = origX
      posY += 1.5 * this.interligne
      this.writeTitle(doc, posX, posY, 'Conditions financières')

      posY += this.interligne
      this.writeFieldText(doc, posX, posY, 'Loyer charges comprises : ', `${this.formatPrice(this.offer.Price)}`)
      posX += 210
      this.writeFieldText(doc, posX, posY, 'Loyer hors charges  : ', `${this.formatPrice(this.offer.F1)}`)
      
      posX = origX
      posY += this.interligne
      this.writeFieldText(doc, posX, posY, 'Provision sur charges : ', `${this.formatPrice(this.offer.F3)}`)
      posX += 210
      this.writeFieldText(doc, posX, posY, 'Dépôt de garantie : ', `${this.formatPrice(this.offer.F10)}`)

      posX = origX
      posY += this.interligne
      this.writeFieldText(doc, posX, posY, 'Honoraires agence : ', `${this.formatPrice(this.offer.F25)}`)

      // Descriptif technique
      posX = origX
      posY += 1.5 * this.interligne
      this.writeTitle(doc, posX, posY, 'Descriptif technique'.toUpperCase())
      posY += this.interligne
      this.writeFieldText(doc, posX, posY, 'Nombre de pièces : ', `${this.offer.NombrePieces}`)
      posX += 115
      this.writeFieldText(doc, posX, posY, '/  Nombre de chambres : ', `${this.offer.NombreChambres}`)
      posX += 140
      this.writeFieldText(doc, posX, posY, '/  Etage : ', `${this.offer.Etage} sur ${this.offer.NombreNiveaux}`)
      
      // Surface
      posX = origX
      posY += this.interligne
      this.writeFieldText(doc, posX, posY, 'Surface habitable : ', `${this.offer.F12} m2`)
      posX += 155
      this.writeFieldText(doc, posX, posY, '/  Surface terrain : ', `${this.offer.F15} m2`)
      posX += 140
      this.writeFieldText(doc, posX, posY, '/  Surface jardin : ', `${this.offer.F29} m2`)
      
      // Chauffage
      posX = origX
      posY += this.interligne
      this.writeFieldText(doc, posX, posY, 'Type chauffage : ', this.offer.TypeChauffage)
      posX += 220
      this.writeFieldText(doc, posX, posY, '/  Nature chauffage : ', this.offer.NatureChauffage)
      posX += 170
      this.writeFieldText(doc, posX, posY, '/  Mode chauffage : ', this.offer.ModeChauffage)
      
      // Eau chaude / type cuisine
      posX = origX
      posY += this.interligne
      this.writeFieldText(doc, posX, posY, 'Eau chaude : ', this.offer.S48)
      posX += 220
      this.writeFieldText(doc, posX, posY, '/  Type cuisine : ', this.offer.TypeCuisine)
      
      // WC / Salles de bain etc...
      posX = origX
      posY += this.interligne
      this.writeFieldText(doc, posX, posY, 'WC : ', `${this.offer.I7}`)
      posX += 60
      this.writeFieldText(doc, posX, posY, '/  Salles de bains : ', `${this.offer.I8}`)
      
      // Balcons / Terasses...
      posX = origX
      posY += this.interligne
      this.writeFieldText(doc, posX, posY, 'Balcons : ', `${this.offer.NombreBalcons}`)
      posX += 70
      this.writeFieldText(doc, posX, posY, '/  Terrasses : ', `${this.offer.NombreTerrasses}`)
      posX += 80
      this.writeFieldText(doc, posX, posY, '/  Caves : ', `${this.offer.NombreCaves}`)
      
      // Parkings...
      posX = origX
      posY += this.interligne
      this.writeFieldText(doc, posX, posY, 'Parkings intérieurs : ', `${this.offer.NombrePkgInter}`)
      posX += 120
      this.writeFieldText(doc, posX, posY, '/  Parkings extérieurs : ', `${this.offer.NombrePkgExter}`)
      posX += 140
      this.writeFieldText(doc, posX, posY, '/  Box : ', `${this.offer.I12}`)
      
      // Options
      doc.setFont("Roboto-Regular");
      posX = origX
      posY += 1.5 * this.interligne
      posX += this.writeCheckBox(doc, posX, posY, 'Gardiennage', (this.offer.B16 > 0), 95)
      
      posX += this.writeCheckBox(doc, posX, posY, 'Interphone', (this.offer.B17 > 0), 80)
      
      posX += this.writeCheckBox(doc, posX, posY, 'Vidéophone', (this.offer.B18 > 0), 90)
      
      posX += this.writeCheckBox(doc, posX, posY, 'Digicode', (this.offer.B19 > 0), 80)
      
      posX += this.writeCheckBox(doc, posX, posY, 'Espaces verts', (this.offer.B20 > 0), 95)
      
      posX += this.writeCheckBox(doc, posX, posY, 'Ascenseur', (this.offer.B21 > 0), 0)

      posX = origX
      posY += 1.5 * this.interligne
      posX += this.writeCheckBox(doc, posX, posY, 'Antenne', (this.offer.B22 > 0), 75)
      
      posX += this.writeCheckBox(doc, posX, posY, 'Cable', (this.offer.B23 > 0), 60)
      
      posX += this.writeCheckBox(doc, posX, posY, 'Chauffage', (this.offer.B24 > 0), 80)
      
      posX += this.writeCheckBox(doc, posX, posY, 'Eau chaude', (this.offer.B25 > 0), 80)
      
      posX += this.writeCheckBox(doc, posX, posY, 'Eau froide', (this.offer.B26 > 0), 80)
      
      posX += this.writeCheckBox(doc, posX, posY, 'Piscine', (this.offer.B14 > 0), 60)
      
      posX += this.writeCheckBox(doc, posX, posY, 'Accès handicapés', (this.offer.IsAccesHandicape > 0), 0)

      posX = origX
      posY += 1.5 * this.interligne
      this.writeTitle(doc, posX, posY, 'Texte annonce'.toUpperCase())
      posY += 20
      doc.setFontSize(12);
      doc.setLineHeightFactor(2)
      var splitText = doc.splitTextToSize(this.offer.TexteAnnonce, 545);
      doc.text(posX, posY, splitText);
      
    },
    async print_rents_local(doc, origX, origY) {
      let posX = origX
      let posY = origY + 1.5 * this.interligne
      this.writeTitle(doc, posX, posY, 'Informations générales'.toUpperCase())
      posY += this.interligne
      this.writeFieldText(doc, posX, posY, 'Catégorie : ', this.offer.Category)
      posX += 200
      this.writeFieldText(doc, posX, posY, 'Date libération : ', this.offer.DateLiberation)
      doc.setFont("Roboto-Regular");
      posX = origX
      posY += this.interligne
      posX += this.writeCheckBox(doc, posX, posY, 'Bureau', (this.offer.B5 > 0), 60)
      
      posX += this.writeCheckBox(doc, posX, posY, 'Commerce', (this.offer.B6 > 0), 80)
      
      posX += this.writeCheckBox(doc, posX, posY, 'Activité', (this.offer.B7 > 0), 60)
      
      posX += this.writeCheckBox(doc, posX, posY, 'Professionnel', (this.offer.B8 > 0), 0)
      // Conditions financières
      posX = origX
      posY += 1.5 * this.interligne
      this.writeTitle(doc, posX, posY, 'Conditions financières')

      posY += this.interligne
      this.writeFieldText(doc, posX, posY, 'Loyer annuel H.C/H.T : ', `${this.formatPrice(this.offer.Price)}`)
      posX += 210
      this.writeFieldText(doc, posX, posY, 'Montant reprise  : ', `${this.formatPrice(this.offer.F13)}`)
      
      posX = origX
      posY += this.interligne
      this.writeFieldText(doc, posX, posY, 'Prov. charges (min) : ', `${this.formatPrice(this.offer.F5)} /m<sup>2</sup>/an`)
      posX += 260
      this.writeFieldText(doc, posX, posY, 'Prov. charges (max) : ', `${this.formatPrice(this.offer.F6)} /m<sup>2</sup>/an`)
      
      posX = origX
      posY += this.interligne
      this.writeFieldText(doc, posX, posY, 'Honoraires agence : ', `${this.formatPrice(this.offer.F25)}`)

      // Descriptif technique
      posX = origX
      posY += 1.5 * this.interligne
      this.writeTitle(doc, posX, posY, 'Descriptif technique'.toUpperCase())
      posY += this.interligne
      this.writeFieldText(doc, posX, posY, 'Etage : ', `${this.offer.Etage} sur ${this.offer.NombreNiveaux}`)
      
      // Surface
      posX = origX
      posY += this.interligne
      this.writeFieldText(doc, posX, posY, 'Surface totale : ', `${this.offer.F12} m2`)
      posX += 130
      this.writeFieldText(doc, posX, posY, '/  Surface mini : ', `${this.offer.F15} m2`)
      posX += 130
      this.writeFieldText(doc, posX, posY, '/  Surface terrain : ', `${this.offer.F16} m2`)
      posX += 130
      this.writeFieldText(doc, posX, posY, '/  Longueur vitrine : ', `${this.offer.F17} m`)
      
      // Chauffage
      posX = origX
      posY += this.interligne
      this.writeFieldText(doc, posX, posY, 'Type chauffage : ', this.offer.TypeChauffage)
      posX += 205
      this.writeFieldText(doc, posX, posY, '/  Nature chauffage : ', this.offer.NatureChauffage)
      posX += 170
      this.writeFieldText(doc, posX, posY, '/  Mode chauffage : ', this.offer.ModeChauffage)
      
      // Parkings...
      posX = origX
      posY += this.interligne
      this.writeFieldText(doc, posX, posY, 'Parkings intérieurs : ', `${this.offer.NombrePkgInter}`)
      posX += 120
      this.writeFieldText(doc, posX, posY, '/  Parkings extérieurs : ', `${this.offer.NombrePkgExter}`)
      posX += 140
      this.writeFieldText(doc, posX, posY, '/  Box/Garages : ', `${this.offer.I12}`)
      
      // Options
      doc.setFont("Roboto-Regular");
      posX = origX
      posY += 1.5* this.interligne
      posX += this.writeCheckBox(doc, posX, posY, 'Gardiennage', (this.offer.B16 > 0), 95)
      
      posX += this.writeCheckBox(doc, posX, posY, 'Interphone', (this.offer.IsInterphone > 0), 80)
      
      posX += this.writeCheckBox(doc, posX, posY, 'Vidéophone', (this.offer.B18 > 0), 90)
      
      posX += this.writeCheckBox(doc, posX, posY, 'Digicode', (this.offer.IsDigicode > 0), 80)
      
      posX += this.writeCheckBox(doc, posX, posY, 'Climatisation', (this.offer.B20 > 0), 95)
      
      posX += this.writeCheckBox(doc, posX, posY, 'Ascenseur', (this.offer.IsAscenseur > 0), 0)

      posX = origX
      posY += 1.5*this.interligne
      posX += this.writeCheckBox(doc, posX, posY, 'Télésurveillance', (this.offer.B15 > 0), 100)
      
      posX += this.writeCheckBox(doc, posX, posY, 'Accès handicapé', (this.offer.IsAccesHandicape > 0), 110)
      
      posX += this.writeCheckBox(doc, posX, posY, 'Monte charge', (this.offer.B14 > 0), 90)
      
      posX += this.writeCheckBox(doc, posX, posY, 'Immeuble collectif', (this.offer.B18 > 0), 115)
      
      posX += this.writeCheckBox(doc, posX, posY, 'Immeuble indépendant', (this.offer.B19 > 0),  0)
      
      posX = origX
      posY += 1.5 * this.interligne
      this.writeTitle(doc, posX, posY, 'Texte annonce'.toUpperCase())
      posY += this.interligne
      doc.setFontSize(12);
      doc.setLineHeightFactor(2)
      var splitText = doc.splitTextToSize(this.offer.TexteAnnonce, 545);
      doc.text(posX, posY, splitText);
      
    },
    async print_rents_parking(doc, origX, origY) {
      let posX = origX
      let posY = origY + 1.5 * this.interligne
      this.writeTitle(doc, posX, posY, 'Informations générales'.toUpperCase())
      posY += this.interligne
      this.writeFieldText(doc, posX, posY, 'Catégorie : ', this.offer.Category)
      posX += 200
      this.writeFieldText(doc, posX, posY, 'Date libération : ', this.offer.DateLiberation)
      doc.setFont("Roboto-Regular");
      // Conditions financières
      posX = origX
      posY += 1.5 * this.interligne
      this.writeTitle(doc, posX, posY, 'Conditions financières')

      posX = origX
      posY += this.interligne
      if (this.offer.I15 === 0){
        posX += this.writeCheckBox(doc, posX, posY, 'Loyer mensuel', (this.offer.I15 === 0), 140)
      } else {
        posX += this.writeCheckBox(doc, posX, posY, 'Loyer trimestriel', (this.offer.I15 === 1), 140)
      }
      
      if (this.offer.I28 === 0){
        posX += this.writeCheckBox(doc, posX, posY, 'Charges mensuelles', (this.offer.I28 === 0), 0)
      } else {
        posX += this.writeCheckBox(doc, posX, posY, 'Charges trimestrielles', (this.offer.I28 === 1), 0)
      }
      posX = origX
      posY += this.interligne
      
      this.writeFieldText(doc, posX, posY, 'Loyer annuel H.C : ', `${this.formatPrice(this.offer.Price)}`)
      posX += 180
      this.writeFieldText(doc, posX, posY, 'Provisions charges  : ', `${this.formatPrice(this.offer.F3)}`)
      posX += 180
      this.writeFieldText(doc, posX, posY, 'Dépôt de garantie  : ', `${this.formatPrice(this.offer.F10)}`)
      
      posX = origX
      posY += this.interligne
      this.writeFieldText(doc, posX, posY, 'Honoraires agence : ', `${this.formatPrice(this.offer.F25)}`)

      // Descriptif technique
      posX = origX
      posY += 1.5 * this.interligne
      this.writeTitle(doc, posX, posY, 'Descriptif technique'.toUpperCase())
      posY += this.interligne
      // Tyep de stationnement
      doc.text(posX, posY, 'Type de stationnement : '.toUpperCase())
      posX += 155
      posX += this.writeCheckBox(doc, posX, posY, 'Côte à côte', (this.offer.I7 === 0), 115)
      
      posX += this.writeCheckBox(doc, posX, posY, 'Superposé', (this.offer.I7 === 1), 140)
      
      posX += this.writeCheckBox(doc, posX, posY, 'Enfilade', (this.offer.I7 === 2), 0)
      
      // Type d'accès
      posX = origX
      posY += this.interligne
      doc.text(posX, posY, 'Type d\'accès : '.toUpperCase())
      posX += 125
      posX += this.writeCheckBox(doc, posX, posY, 'Rampe', (this.offer.I8 === 0), 95)
      
      posX += this.writeCheckBox(doc, posX, posY, 'Ascenseur', (this.offer.I8 === 1), 120)
      
      this.writeFieldText(doc, posX, posY, 'Niveau : ', `${this.offer.Etage} sur ${this.offer.I6} `)
      doc.setFont("Roboto-Regular");
      // Accès piéton
      posX = origX
      posY += this.interligne
      doc.text(posX, posY, 'Accès piéton : '.toUpperCase())
      posX += 125
      posX += this.writeCheckBox(doc, posX, posY, 'Escalier', (this.offer.I9 === 0), 95)
      
      posX += this.writeCheckBox(doc, posX, posY, 'Ascenseur', (this.offer.I9 === 1), 0)
      
      // Eau chaude / type cuisine
      posX = origX
      posY += this.interligne
      this.writeFieldText(doc, posX, posY, 'Eau chaude : ', this.offer.S48)
      posX += 220
      this.writeFieldText(doc, posX, posY, '/  Type cuisine : ', this.offer.TypeCuisine)
      
      // Surfaces...
      posX = origX
      posY += this.interligne
      this.writeFieldText(doc, posX, posY, 'Surface totale : ', `${this.offer.F12} m2`)
      posX += 120
      this.writeFieldText(doc, posX, posY, '/  Longueur : ', `${this.offer.F16} m`)
      posX += 140
      this.writeFieldText(doc, posX, posY, '/  Largeur : ', `${this.offer.F17} m`)
      posX += 140
      this.writeFieldText(doc, posX, posY, '/  Hauteur : ', `${this.offer.F18} m`)
      
      // Options
      doc.setFont("Roboto-Regular");
      posX = origX
      posY += 1.5* this.interligne
      posX += this.writeCheckBox(doc, posX, posY, 'Gardiennage', (this.offer.B16 > 0), 85)
      
      posX += this.writeCheckBox(doc, posX, posY, 'Accès handicapés', (this.offer.IsAccesHandicape > 0), 115)
      
      posX += this.writeCheckBox(doc, posX, posY, 'Vidéo surveillance', (this.offer.B5 > 0), 115)
      
      posX += this.writeCheckBox(doc, posX, posY, 'Ascenseur', (this.offer.IsAscenseur > 0), 75)
      
      posX += this.writeCheckBox(doc, posX, posY, 'Bip', (this.offer.IsInterphone > 0), 40)
      
      posX += this.writeCheckBox(doc, posX, posY, 'Carte magnétique', (this.offer.IsDigicode > 0), 0)
      
      posX = origX
      posY += 1.5 * this.interligne
      this.writeTitle(doc, posX, posY, 'Texte annonce'.toUpperCase())
      posY += this.interligne
      doc.setFontSize(12);
      doc.setLineHeightFactor(2)
      var splitText = doc.splitTextToSize(this.offer.TexteAnnonce, 545);
      doc.text(posX, posY, splitText);
      
    },
    async print_sales_appartement(doc, origX, origY) {
      let posX = origX
      let posY = origY + 1.5 * this.interligne
      this.writeTitle(doc, posX, posY, 'Informations générales'.toUpperCase())
      posY += this.interligne
      let _str = ` ${this.offer.Category}`
      if (this.offer.I15 >  0) _str += ` / ${this.disponibilite[this.offer.I15-1].toUpperCase()}`
      this.writeFieldText(doc, posX, posY, 'Categorie : ', _str)
      
      posX += 200
      this.writeFieldText(doc, posX, posY, 'Date libération : ', this.offer.DateLiberation)
      posX += 200
      this.writeFieldText(doc, posX, posY, 'Montant loyer : ', `${this.formatPrice(this.offer.F11)}`)
      
      // Conditions financières
      posX = origX
      posY += 1.5 * this.interligne
      this.writeTitle(doc, posX, posY, 'Conditions financières')

      posY += this.interligne
      this.writeFieldText(doc, posX, posY, 'Prix de vente FAI : ', `${this.formatPrice(this.offer.Price)}`)
      posX += 170
      this.writeFieldText(doc, posX, posY, 'Charges : ', `${this.formatPrice(this.offer.F10)}`)
      posX += 110
      this.writeFieldText(doc, posX, posY, 'Taxe foncière : ', `${this.formatPrice(this.offer.F9)}`)
      
      // Descriptif technique
      posX = origX
      posY += 1.5 * this.interligne
      this.writeTitle(doc, posX, posY, 'Descriptif technique'.toUpperCase())
      posY += this.interligne
      this.writeFieldText(doc, posX, posY, 'Nombre de pièces : ', `${this.offer.NombrePieces}`)
      posX += 115
      this.writeFieldText(doc, posX, posY, '/  Nombre de chambres : ', `${this.offer.NombreChambres}`)
      posX += 140
      this.writeFieldText(doc, posX, posY, '/  Etage : ', `${this.offer.Etage} sur ${this.offer.NombreNiveaux}`)
      
      // Surface
      posX = origX
      posY += this.interligne
      this.writeFieldText(doc, posX, posY, 'Surface habitable : ', `${this.offer.F12} m2`)
      posX += 155
      this.writeFieldText(doc, posX, posY, '/  Surface jardin : ', `${this.offer.F15} m2`)
      posX += 140
      this.writeFieldText(doc, posX, posY, '/  Surface séjour : ', `${this.offer.F16} m2`)
      
      // Chauffage
      posX = origX
      posY += this.interligne
      this.writeFieldText(doc, posX, posY, 'Type chauffage : ', `${this.offer.TypeChauffage}`)
      posX += 220
      this.writeFieldText(doc, posX, posY, '/  Type cuisine : ', `${this.offer.TypeCuisine}`)
      
      // WC / Salles de bain etc...
      posX = origX
      posY += this.interligne
      this.writeFieldText(doc, posX, posY, 'WC : ', `${this.offer.I7}`)
      posX += 60
      this.writeFieldText(doc, posX, posY, '/  Salles de bains : ', `${this.offer.I8}`)
      posX += 120
      this.writeFieldText(doc, posX, posY, '/  Salles d\'eau : ', `${this.offer.I9}`)
      
      // Balcons / Terasses...
      posX = origX
      posY += this.interligne
      this.writeFieldText(doc, posX, posY, 'Balcons : ', `${this.offer.NombreBalcons}`)
      posX += 70
      this.writeFieldText(doc, posX, posY, '/  Terrasses : ', `${this.offer.NombreTerrasses}`)
      posX += 80
      this.writeFieldText(doc, posX, posY, '/  Caves : ', `${this.offer.NombreCaves}`)
      
      // Parkings...
      posX = origX
      posY += this.interligne
      this.writeFieldText(doc, posX, posY, 'Parkings intérieurs : ', `${this.offer.NombrePkgInter}`)
      posX += 120
      this.writeFieldText(doc, posX, posY, '/  Parkings extérieurs : ', `${this.offer.NombrePkgExter}`)
      posX += 140
      this.writeFieldText(doc, posX, posY, '/  Box : ', `${this.offer.I12}`)
      
      // Options
      doc.setFont("Roboto-Regular");
      posX = origX
      posY += 1.5 * this.interligne
      posX += this.writeCheckBox(doc, posX, posY, 'Ascenseur', (this.offer.IsAscenseur > 0), 90)
      
      posX += this.writeCheckBox(doc, posX, posY, 'Interphone', (this.offer.IsInterphone > 0), 90)
      
      posX += this.writeCheckBox(doc, posX, posY, 'Digicode', (this.offer.IsDigicode > 0), 80)
      
      posX += this.writeCheckBox(doc, posX, posY, 'Piscine', (this.offer.B14 > 0), 80)
      
      posX += this.writeCheckBox(doc, posX, posY, 'Accès handicapés', (this.offer.IsAccesHandicape > 0), 125)
      
      posX += this.writeCheckBox(doc, posX, posY, 'Gardien', (this.offer.B16 > 0), 0)

      posX = origX
      posY += 1.5 * this.interligne
      this.writeTitle(doc, posX, posY, 'Texte annonce'.toUpperCase())
      posY += this.interligne
      doc.setFontSize(12);
      doc.setLineHeightFactor(2)
      var splitText = doc.splitTextToSize(this.offer.TexteAnnonce, 545);
      doc.text(posX, posY, splitText);
      
    },
    async print_sales_maison(doc, origX, origY) {
      let posX = origX
      let posY = origY + 1.5 * this.interligne
      this.writeTitle(doc, posX, posY, 'Informations générales'.toUpperCase())
      posY += this.interligne
      let _str = ` ${this.offer.Category}`
      if (this.offer.I15 >  0) _str += ` / ${this.disponibilite[this.offer.I15-1].toUpperCase()}`
      this.writeFieldText(doc, posX, posY, 'Categorie : ', _str)
      
      
      posX += 200
      this.writeFieldText(doc, posX, posY, 'Date libération : ', this.offer.DateLiberation)
      
      posX += 150
      this.writeFieldText(doc, posX, posY, 'Montant loyer : ', `${this.formatPrice(this.offer.F11)}`)
      
      // Conditions financières
      posX = origX
      posY += 1.5 * this.interligne
      this.writeTitle(doc, posX, posY, 'Conditions financières')

      posY += this.interligne
      this.writeFieldText(doc, posX, posY, 'Prix de vente FAI : ', `${this.formatPrice(this.offer.Price)}`)
      posX += 170
      this.writeFieldText(doc, posX, posY, 'Charges : ', `${this.formatPrice(this.offer.F10)}`)
      posX += 110
      this.writeFieldText(doc, posX, posY, 'Taxe foncière : ', `${this.formatPrice(this.offer.F9)}`)
      
      // Descriptif technique
      posX = origX
      posY += 1.5 * this.interligne
      this.writeTitle(doc, posX, posY, 'Descriptif technique'.toUpperCase())
      posY += this.interligne
      this.writeFieldText(doc, posX, posY, 'Nombre de pièces : ', `${this.offer.NombrePieces}`)
      posX += 115
      this.writeFieldText(doc, posX, posY, '/  Nombre de chambres : ', `${this.offer.NombreChambres}`)
      posX += 140
      this.writeFieldText(doc, posX, posY, '/  Etage : ', `${this.offer.Etage} sur ${this.offer.NombreNiveaux}`)
      
      // Surface
      posX = origX
      posY += this.interligne
      this.writeFieldText(doc, posX, posY, 'Surface habitable : ', `${this.offer.F12} m2`)
      posX += 155
      this.writeFieldText(doc, posX, posY, '/  Surface jardin : ', `${this.offer.F15} m2`)
      posX += 140
      this.writeFieldText(doc, posX, posY, '/  Surface séjour : ', `${this.offer.F16} m2`)
      
      // Chauffage
      posX = origX
      posY += this.interligne
      this.writeFieldText(doc, posX, posY, 'Type chauffage : ', `${this.offer.TypeChauffage}`)
      posX += 220
      this.writeFieldText(doc, posX, posY, '/  Type cuisine : ', `${this.offer.TypeCuisine}`)
      
      // WC / Salles de bain etc...
      posX = origX
      posY += this.interligne
      this.writeFieldText(doc, posX, posY, 'WC : ', `${this.offer.I7}`)
      posX += 60
      this.writeFieldText(doc, posX, posY, '/  Salles de bains : ', `${this.offer.I8}`)
      posX += 120
      this.writeFieldText(doc, posX, posY, '/  Salles d\'eau : ', `${this.offer.I9}`)
      
      // Balcons / Terasses...
      posX = origX
      posY += this.interligne
      this.writeFieldText(doc, posX, posY, 'Balcons : ', `${this.offer.NombreBalcons}`)
      posX += 70
      this.writeFieldText(doc, posX, posY, '/  Terrasses : ', `${this.offer.NombreTerrasses}`)
      posX += 80
      this.writeFieldText(doc, posX, posY, '/  Caves : ', `${this.offer.NombreCaves}`)
      
      // Parkings...
      posX = origX
      posY += this.interligne
      this.writeFieldText(doc, posX, posY, 'Parkings intérieurs : ', `${this.offer.NombrePkgInter}`)
      posX += 120
      this.writeFieldText(doc, posX, posY, '/  Parkings extérieurs : ', `${this.offer.NombrePkgExter}`)
      posX += 140
      this.writeFieldText(doc, posX, posY, '/  Box : ', `${this.offer.I12}`)
      
      // Options
      doc.setFont("Roboto-Regular");
      posX = origX
      posY += 1.5 * this.interligne
      posX += this.writeCheckBox(doc, posX, posY, 'Interphone', (this.offer.IsInterphone > 0), 80)
      
      posX += this.writeCheckBox(doc, posX, posY, 'Alarme', (this.offer.IsDigicode > 0), 65)
      
      posX += this.writeCheckBox(doc, posX, posY, 'Lotissement', (this.offer.IsAscenseur > 0), 90)
      
      posX += this.writeCheckBox(doc, posX, posY, 'Piscine', (this.offer.B14 > 0), 70)
      
      posX += this.writeCheckBox(doc, posX, posY, 'Accès handicapés', (this.offer.IsAccesHandicape > 0), 0)
      
      posX = origX
      posY += 1.5 * this.interligne
      this.writeTitle(doc, posX, posY, 'Texte annonce'.toUpperCase())
      posY += this.interligne
      doc.setFontSize(12);
      doc.setLineHeightFactor(2)
      var splitText = doc.splitTextToSize(this.offer.TexteAnnonce, 545);
      doc.text(posX, posY, splitText);
      
    },
    async print_sales_local(doc, origX, origY) {
      let posX = origX
      let posY = origY + 1.5 * this.interligne
      this.writeTitle(doc, posX, posY, 'Informations générales'.toUpperCase())
      posY += this.interligne
      let _str = ` ${this.offer.Category}`
      if (this.offer.I15 >  0) _str += ` / ${this.disponibilite[this.offer.I15-1].toUpperCase()}`
      this.writeFieldText(doc, posX, posY, 'Categorie : ', _str)
      
      posX += 220
      this.writeFieldText(doc, posX, posY, 'Date libération : ', this.offer.DateLiberation)
      
      posX += 150
      this.writeFieldText(doc, posX, posY, 'Loyer annuel : ', `${this.formatPrice(this.offer.F11)}`)
      
      doc.setFont("Roboto-Regular");
      posX = origX
      posY += this.interligne
      posX += this.writeCheckBox(doc, posX, posY, 'Bureau', (this.offer.B5 > 0), 60)
      
      posX += this.writeCheckBox(doc, posX, posY, 'Commerce', (this.offer.B6 > 0), 80)
      
      posX += this.writeCheckBox(doc, posX, posY, 'Activité', (this.offer.B7 > 0), 60)
      
      posX += this.writeCheckBox(doc, posX, posY, 'Professionnel', (this.offer.B8 > 0), 0)
      
      // Conditions financières
      posX = origX
      posY += 1.5 * this.interligne
      this.writeTitle(doc, posX, posY, 'Conditions financières')

      posY += this.interligne
      this.writeFieldText(doc, posX, posY, 'Prix de vente FAI : ', `${this.formatPrice(this.offer.Price)}`)
      posX += 170
      this.writeFieldText(doc, posX, posY, 'Charges : ', `${this.formatPrice(this.offer.F10)}`)
      posX += 110
      this.writeFieldText(doc, posX, posY, 'Taxe foncière : ', `${this.formatPrice(this.offer.F9)}`)
      
      // Descriptif technique
      posX = origX
      posY += 1.5 * this.interligne
      this.writeTitle(doc, posX, posY, 'Descriptif technique'.toUpperCase())
      posY += this.interligne
      this.writeFieldText(doc, posX, posY, 'Etage : ', `${this.offer.Etage} sur ${this.offer.NombreNiveaux}`)
      
      // Surface
      posX = origX
      posY += this.interligne
      this.writeFieldText(doc, posX, posY, 'Surface totale : ', `${this.offer.F12} m2`)
      posX += 150
      this.writeFieldText(doc, posX, posY, '/  Surface mini : ', `${this.offer.F15} m2`)
      posX += 130
      this.writeFieldText(doc, posX, posY, '/  Surface terrain : ', `${this.offer.F16} m2`)
      posX += 130
      this.writeFieldText(doc, posX, posY, '/  Longueur vitrine  : ', `${this.offer.F17} m`)
      
      // Chauffage
      posX = origX
      posY += this.interligne
      this.writeFieldText(doc, posX, posY, 'Type chauffage : ', `${this.offer.TypeChauffage}`)
      posX += 190
      this.writeFieldText(doc, posX, posY, '/  Nature chauffage : ', `${this.offer.NatureChauffage}`)
      posX += 190
      this.writeFieldText(doc, posX, posY, '/  Mode chauffage : ', `${this.offer.ModeChauffage}`)
      
      // Parkings...
      posX = origX
      posY += this.interligne
      this.writeFieldText(doc, posX, posY, 'Parkings intérieurs : ', `${this.offer.NombrePkgInter}`)
      posX += 120
      this.writeFieldText(doc, posX, posY, '/  Parkings extérieurs : ', `${this.offer.NombrePkgExter}`)
      posX += 140
      this.writeFieldText(doc, posX, posY, '/  Box/Garages : ', `${this.offer.I12}`)
      
      // Options
      doc.setFont("Roboto-Regular");
      posX = origX
      posY += 1.5 * this.interligne
      posX += this.writeCheckBox(doc, posX, posY, 'Gardiennage', (this.offer.B16 > 0), 90)
      
      posX += this.writeCheckBox(doc, posX, posY, 'Interphone', (this.offer.IsInterphone > 0), 80)
      
      posX += this.writeCheckBox(doc, posX, posY, 'Vidéophone', (this.offer.B18 > 0), 85)
      
      posX += this.writeCheckBox(doc, posX, posY, 'Digicode', (this.offer.IsDigicode > 0), 70)
      
      posX += this.writeCheckBox(doc, posX, posY, 'Climatisation', (this.offer.B20 > 0), 90)
      
      posX += this.writeCheckBox(doc, posX, posY, 'Ascenseur', (this.offer.IsAscenseur > 0), 0)
      
      posX = origX
      posY += 1.5 * this.interligne
      posX += this.writeCheckBox(doc, posX, posY, 'Télésurveillance', (this.offer.B15 > 0), 100)
      
      posX += this.writeCheckBox(doc, posX, posY, 'Accès handicapés', (this.offer.IsAccesHandicape > 0), 110)
      
      posX += this.writeCheckBox(doc, posX, posY, 'Monte charge', (this.offer.B14 > 0), 0)
      
      posX = origX
      posY += 1.5 * this.interligne
      posX += this.writeCheckBox(doc, posX, posY, 'Immeuble collectif', (this.offer.B18 > 0), 120)
      
      posX += this.writeCheckBox(doc, posX, posY, 'Immeuble indépendant', (this.offer.B19 > 0), 0)
      
      posX = origX
      posY += 1.5 * this.interligne
      this.writeTitle(doc, posX, posY, 'Texte annonce'.toUpperCase())
      posY += this.interligne
      doc.setFontSize(12);
      doc.setLineHeightFactor(2)
      var splitText = doc.splitTextToSize(this.offer.TexteAnnonce, 545);
      doc.text(posX, posY, splitText);
      
    },
    async print_sales_parking(doc, origX, origY) {
      doc.setFont("Roboto-Black");
      doc.setFontSize(14);
      let posX = origX
      let posY = origY
      this.interligne = 23

      // TransactionText
      let transactionText = ('VENTE ' + this.offer.TypeBien + ' à ' + this.offer.Ville).toUpperCase()
      this.writeText(doc, posX, posY, transactionText, { color: {r:0, g:0, b:0}, align: 'center' });
      
      // Informations générales
      posX = origX
      posY += 1.5 * this.interligne
      this.writeTitle(doc, posX, posY, 'Informations générales'.toUpperCase())
      posY += this.interligne
      let _str = ` ${this.offer.Category}`
      if (this.offer.I15 >  0) _str += ` / ${this.disponibilite[this.offer.I15-1].toUpperCase()}`
      this.writeFieldText(doc, posX, posY, 'Categorie : ', _str)
      
      posX += 200
      this.writeFieldText(doc, posX, posY, 'Date libération : ', this.offer.DateLiberation)
      
      posX += 150
      this.writeFieldText(doc, posX, posY, 'Montant loyer : ', `${this.formatPrice(this.offer.F11)}`)
      
      
      doc.setFont("Roboto-Regular");
      // Conditions financières
      posX = origX
      posY += 1.5 * this.interligne
      this.writeTitle(doc, posX, posY, 'Conditions financières')

      
      posX = origX
      posY += this.interligne
      
      this.writeFieldText(doc, posX, posY, 'Prix de vente FAI : ', `${this.formatPrice(this.offer.Price)}`)
      posX += 180
      this.writeFieldText(doc, posX, posY, 'Charges  : ', `${this.formatPrice(this.offer.F10)}`)
      posX += 100
      this.writeFieldText(doc, posX, posY, 'Taxe professionnelle  : ', `${this.formatPrice(this.offer.F9)}`)
      
      // Descriptif technique
      posX = origX
      posY += 1.5 * this.interligne
      this.writeTitle(doc, posX, posY, 'Descriptif technique'.toUpperCase())
      posY += this.interligne
      // Tyep de stationnement
      doc.text(posX, posY, 'Type de stationnement : '.toUpperCase())
      posX += 155
      posX += this.writeCheckBox(doc, posX, posY, 'Côte à côte', (this.offer.I7 === 0), 115)
      
      posX += this.writeCheckBox(doc, posX, posY, 'Superposé', (this.offer.I7 === 1), 140)
      
      posX += this.writeCheckBox(doc, posX, posY, 'Enfilade', (this.offer.I7 === 2), 0)
      
      // Type d'accès
      posX = origX
      posY += this.interligne
      doc.text(posX, posY, 'Type d\'accès : '.toUpperCase())
      posX += 125
      posX += this.writeCheckBox(doc, posX, posY, 'Rampe', (this.offer.I8 === 0), 95)
      
      posX += this.writeCheckBox(doc, posX, posY, 'Ascenseur', (this.offer.I8 === 1), 120)
      
      this.writeFieldText(doc, posX, posY, 'Niveau : ', `${this.offer.Etage} sur ${this.offer.I6} `)
      doc.setFont("Roboto-Regular");
      // Accès piéton
      posX = origX
      posY += this.interligne
      doc.text(posX, posY, 'Accès piéton : '.toUpperCase())
      posX += 125
      posX += this.writeCheckBox(doc, posX, posY, 'Escalier', (this.offer.I9 === 0), 95)
      
      posX += this.writeCheckBox(doc, posX, posY, 'Ascenseur', (this.offer.I9 === 1), 0)
      
      // Surfaces...
      posX = origX
      posY += this.interligne
      this.writeFieldText(doc, posX, posY, 'Surface totale : ', `${this.offer.F12} m2`)
      posX += 120
      this.writeFieldText(doc, posX, posY, '/  Longueur : ', `${this.offer.F16} m`)
      posX += 120
      this.writeFieldText(doc, posX, posY, '/  Largeur : ', `${this.offer.F17} m`)
      posX += 120
      this.writeFieldText(doc, posX, posY, '/  Hauteur : ', `${this.offer.F18} m`)
      
      // Options
      doc.setFont("Roboto-Regular");
      posX = origX
      posY += 1.5* this.interligne
      posX += this.writeCheckBox(doc, posX, posY, 'Gardiennage', (this.offer.B16 > 0), 85)
      
      posX += this.writeCheckBox(doc, posX, posY, 'Accès handicapés', (this.offer.IsAccesHandicape > 0), 115)
      
      posX += this.writeCheckBox(doc, posX, posY, 'Vidéo surveillance', (this.offer.B5 > 0), 115)
      
      posX += this.writeCheckBox(doc, posX, posY, 'Ascenseur', (this.offer.IsAscenseur > 0), 75)
      
      posX += this.writeCheckBox(doc, posX, posY, 'Bip', (this.offer.IsInterphone > 0), 40)
      
      posX += this.writeCheckBox(doc, posX, posY, 'Carte magnétique', (this.offer.IsDigicode > 0), 0)
      
      posX = origX
      posY += 1.5 * this.interligne
      this.writeTitle(doc, posX, posY, 'Texte annonce'.toUpperCase())
      posY += this.interligne
      doc.setFontSize(12);
      doc.setLineHeightFactor(2)
      var splitText = doc.splitTextToSize(this.offer.TexteAnnonce, 545);
      doc.text(posX, posY, splitText);
      
    },
    async html2pdf() {
      const doc = new jsPDF('p', 'pt', 'A4');

      const myLayout = {
        x:17,
        y: 17,
        w : doc.internal.pageSize.width - 34,
        h : doc.internal.pageSize.height - 34,
      } 
      doc.setFont("Roboto-Black"); // set font

      doc.setFillColor(255,255,255);
      doc.setDrawColor('#eee')
      doc.rect(myLayout.x, myLayout.y, myLayout.w, myLayout.h, 'FD');

      // header
      //const slogan = "Le spécialiste de l'est parisien"
      const slogan = this.$localStore.getString('slogan')
      
      doc.setFontSize(19);
      doc.setFillColor(184,184,184);
      doc.rect(myLayout.x, myLayout.y, myLayout.w, 50.5, 'F');
      // logo
      //const logo = await this.$tools.loadImageFromURL(this.$tools.getLogo(this.offer.account_id)) // A changer pour prendre en compte le accountId
      const logo = await this.$tools.loadImageFromURL(this.$tools.getIdentityImage(this.offer.account_id))
      doc.addImage(logo, "JPEG", myLayout.x, myLayout.y, 150, 50);
      // Slogan
      this.writeText(doc, myLayout.x +50, myLayout.y + 30 ,slogan.toUpperCase(), { color: {r:255, g:255, b:255}, align: 'center' });

      let posY =  myLayout.y + 75
      let posX = myLayout.x

      doc.setFont("Roboto-Black");
      doc.setFontSize(14);
      
      // TransactionText
      let transactionText = 'LOCATION '
      if (this.trans === 'sales') {
        transactionText = 'VENTE '
      }
      transactionText += this.offer.TypeBien + ' à ' + this.offer.Ville
      this.writeText(doc, posX, posY, transactionText.toUpperCase(), { width: myLayout.w, color: {r:0, g:0, b:0}, align: 'center' });

      // Images
      const imageWidth = 224
      const imageHeight = 140
      
      let imagePosX = myLayout.x + (myLayout.w - imageWidth) / 2
      
      posX = imagePosX
      posY += 10
      //let image0 = `/static/img/noimage-${this.typeBien.toLowerCase()}.jpg` 
      /* let image0 = `/static/img/noimage-immorep.jpg` 
      //console.log('image0 :', image0)
      if (this.offer.images){
        if (this.offer.images.length > 0) {
          image0 = this.offerImage(0)
        }
      }  */

      let _image = await this.$tools.loadImageFromURL(this.offerImage(0))
      doc.addImage(_image, "JPEG", posX, posY, imageWidth, imageHeight);

      posX = myLayout.x + 10
      posY += imageHeight 
      this[`print_${this.trans}_${this.offer.TypeBien}`](doc, posX, posY); // Impression de l'offre
      iframe.src = doc.output('datauristring');
      
    
    }
  }
}
</script>