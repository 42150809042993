import api from '@/api'

const state = {
  customers: [],
  customer: {},
  currentCustomer: {},
  tabCustomerList: true,
  tabCustomerDetails: false
}

// getters
const getters = {
  getAlphabet: (state) => {
    return state.alphabet
  },
  getCustomers: (state) => {
    return state.customers
  },
  getCustomer: (state) => {
    return state.customer
  },
  getFilteredCustomers: (state) => {
    return state.filteredCustomersState
  },
  getFirstLetters: (state) => {
    return state.firstLetters
  },
  getTabCustomerList: (state) => {
    return state.tabCustomerList
  },
  getTabCustomerDetails: (state) => {
    return state.tabCustomerDetails
  },
  getCurrentCustomer: (state) => {
    return state.currentCustomer
  }
}

// actions
const actions = {
  newCustomer: async ({commit}, customer) => {
    try {
      const response = await api.insertCustomer(customer)
      commit('ADD_CUSTOMER', response)
      return response
    } catch (error) {
      return error
    }
  },
  updateCustomer: ({commit}, customer) => {

    return api.updateCustomer(customer).then((response) => {
      commit('UPDATE_CUSTOMER', customer)
      return response
    }, (response) => {
      return response
    })
  },
  removeCustomer: async ({commit}, payload) => {
    try {
      const response = await api.removeCustomer(payload)
      commit('REMOVE_CUSTOMER', payload.offer)
      return response
    } catch (error) {
      return error
    }
  },
  getCustomer: async({ commit }, payload) => {
      try {
        payload['collection'] ='customers'
          const response = await api.getCustomer(payload)
          commit('GET_CUSTOMER', response)
          return response
      } catch (error) {
          return error
      }
  },
  fetchCustomers: async ({commit}, payload) => {
    try {
      const response = await api.getCustomers(payload) 
      commit('GET_CUSTOMERS', response)
      return response
    } catch (error) {
      return error
    }
  },
  switchTab ({commit}, tabIndex) {
    // console.log('tabIndex ', tabIndex)
    if (tabIndex === 0) {
      commit('TAB_CUSTOMER_LIST', true)
      commit('TAB_CUSTOMER_DETAILS', false)
    } else {
      commit('TAB_CUSTOMER_LIST', false)
      commit('TAB_CUSTOMER_DETAILS', true)
    }
  },
  setCurrentCustomer: ({commit}, customer) => {
    // console.log('commit update - customer :', customer)
    commit('CURRENT_CUSTOMER', customer)
  },
  
}

// mutations
const mutations = {
  GET_CUSTOMERS (state, response) {
    let data = response
    state.customers = data
  },
  GET_CUSTOMER (state, customer) {
    state.customer = customer
  },
  ADD_CUSTOMER (state, customer) {
    state.customers.push(customer)
  },
  UPDATE_CUSTOMER (state, customer) {
    let index = state.customers.indexOf(customer)
    state.customers.splice(index, 1, customer)
  },
  REMOVE_CUSTOMER (state, customer) {
    let index = state.customers.indexOf(customer)
    state.customers.splice(index, 1)
  },
  TAB_CUSTOMER_LIST (state, _state) {
    state.tabCustomerList = _state
  },
  TAB_CUSTOMER_DETAILS (state, _state) {
    state.tabCustomerDetails = _state
  },
  CURRENT_CUSTOMER(state, customer){
    state.currentCustomer = customer
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
