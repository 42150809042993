<template lang="html">
  <v-tab-item value="informations-generales">
    <v-container>
      <div class="row align-center justify-center">
        <v-radio-group v-model="offer.Etat" row class="radio__group"> 
          <v-radio color="primary" label="Disponible" :value="'0'"></v-radio>
          <v-radio color="secondary" label="Offre" :value="'1'"></v-radio>
          <v-radio color="secondary" label="Compromis" :value="'2'"></v-radio>
          <v-radio color="success" label="Suspendu" :value="'3'"></v-radio>
          <v-radio color="info" label="Estimation" :value="'4'"></v-radio>
        </v-radio-group>
      </div>
      <div class="row align-center justify-center">
          <div class="col col-md-3 col-sm-6 col-6">
            <v-text-field label="Date création" filled v-model="offer.DateCreation" disabled></v-text-field>
          </div>
          <div class="col col-md-3 col-sm-6 col-6">
            <v-text-field label="Date modification" filled v-model="offer.DateModification" disabled></v-text-field>
          </div>
          <div class="col col-md-3 col-sm-6 col-6">
            <v-text-field label="Modifiée par" filled v-model="offer.LastUpdateDataUser" disabled></v-text-field>
          </div>
          <div class="col col-md-3 col-sm-6 col-6">
            <v-text-field label="Négociateur" filled v-model="offer.Negociateur" disabled></v-text-field>
          </div>
      </div>
      <div class="row align-center justify-center">
        <div class="col col-md-3 col-sm-6 col-6">
          <v-text-field label="Type de bien" filled v-model="offer.TypeBien" disabled></v-text-field>
        </div>
        <div class="col col-md-3 col-sm-6 col-6">
          <v-text-field label="Année de construction" filled v-model="offer.AnneeConstruction" v-mask="yearMask"></v-text-field>
        </div>
      </div>
      <div class="row align-center justify-center">
        <div class="col col-md-3 col-sm-3 col-6">
          <v-text-field label="Date congé" filled v-model="offer.DateConge" v-mask="dateMask" placeholder="JJ/MM/AAAA"></v-text-field>
        </div>
        <div class="col col-md-3 col-sm-3 col-6">
          <v-text-field label="Date disponibilité" filled v-model="offer.DateDisponibilite" v-mask="dateMask" placeholder="JJ/MM/AAAA"></v-text-field>
        </div>
        <div class="col col-md-3 col-sm-3 col-6">
          <v-text-field label="Date éch. préavis" filled v-model="offer.DateEchPreavis" v-mask="dateMask" placeholder="JJ/MM/AAAA"></v-text-field>
        </div>
        <div class="col col-md-3 col-sm-3 col-6">
          <v-text-field label="Date libération" filled v-model="offer.DateLiberation" v-mask="dateMask" placeholder="JJ/MM/AAAA"></v-text-field>
        </div>
      </div>
      <div class="row">
        <v-subheader class="text-uppercase subheader--text">Adresse</v-subheader>
      </div>
      <div class="row">
        <div class="col col-4 col-sm-2">
          <v-text-field label="Numéro" filled v-model="offer.NumRue"></v-text-field>
        </div>
        <div class="col col-8 col-sm-4">
          <v-text-field label="Type" filled v-model="offer.TypeRue"></v-text-field>
        </div>
        <div class="col col-12 col-sm-6">
          <v-text-field label="Adresse" filled v-model="offer.Adresse"></v-text-field>
        </div>
      </div>
      <div class="row">
        <div class="col col-12 ">
          <v-text-field label="Suite adresse" filled v-model="offer.SuiteAdresse"></v-text-field>
        </div>
        <div class="col col-12 ">
          <v-text-field label="Quartier" filled v-model="offer.Quartier"></v-text-field>
        </div>
      </div>
      <div class="row">
        <div class="col col-4 col-md-2 ">
          <ValidationProvider v-slot="{ errors }" name="Code Postal" rules="required">
            <v-text-field label="Code postal *" filled v-model.trim="offer.CodePostal" v-mask="codePostalMask" :error-messages="errors" required></v-text-field>
          </ValidationProvider>
        </div>
        <div class="col col-8 col-md-8 ">
          <ValidationProvider v-slot="{ errors }" name="Ville" rules="required">
            <v-text-field label="Ville *" filled v-model.trim="offer.Ville" :error-messages="errors" required></v-text-field>
          </ValidationProvider>
        </div>
        <div class="col col-8 col-md-2">
          <ValidationProvider v-slot="{ errors }" name="Pays" rules="required">
            <v-text-field label="Pays *" filled v-model.trim="offer.Pays" :error-messages="errors" required></v-text-field>
          </ValidationProvider>
        </div>
      </div>
      <div class="row">
        <v-subheader class="text-uppercase subheader--text">Mandat</v-subheader>
      </div>
      <div class="row">
        <div class="col col-md-3 col-6">
          <v-text-field label="N° Mandat" filled v-model="offer.NumMandat"></v-text-field>
        </div>
        <div class="col col-md-3 col-6">
          <v-text-field label="Type Mandat" filled v-model="offer.TypeMandat"></v-text-field>
        </div>
        <div class="col col-md-3 col-6">
          <v-text-field label="Date signature" filled v-model="offer.DateSignature" v-mask="dateMask" placeholder="JJ/MM/AAAA"></v-text-field>
        </div>
        <div class="col col-md-3 col-6">
          <v-text-field label="Date échéance" filled v-model="offer.DateEcheance" v-mask="dateMask" placeholder="JJ/MM/AAAA"></v-text-field>
        </div>
      </div>  
      <div class="row">
        <v-subheader class="text-uppercase subheader--text">Disponibilité</v-subheader>
      </div>
      <div class="row align-center justify-center">
        <v-radio-group v-model.number="offer.I15" row> 
          <v-radio color="primary" label="Libre" :value="1"></v-radio>
          <v-radio color="secondary" label="Loué" :value="2"></v-radio>
          <v-radio color="success" label="Occupé" :value="3"></v-radio>
        </v-radio-group>
      </div>
      <div class="row">
        <div class="col col-md-3 col-6">
          <v-text-field label="Date libération" filled v-model="offer.DateLiberation" v-mask="dateMask" placeholder="JJ/MM/AAAA"></v-text-field>
        </div>
        <div class="col col-md-3 col-6">
          <lib-v-currency id="F11" label="Montant loyer" v-model="offer.F11" ></lib-v-currency>
        </div>
      </div>
      <div class="row">
        <v-subheader class="text-uppercase subheader--text">Annonce</v-subheader>
      </div>
      <div class="row">
        <div class="col col-12" >
          <v-textarea
            v-model="offer.TexteAnnonce"
            placeholder="Rédiger une annonce ..."
            auto-grow
            filled
            rows="10"
          ></v-textarea>
        </div> 
      </div>
    </v-container>
  </v-tab-item>
</template>

<script>
import { mapGetters } from 'vuex'
import {  ValidationProvider } from 'vee-validate';
import { mask } from 'vue-the-mask'


export default {
  props: ['offer', 'action'],
  components: {
    ValidationProvider
  },
  directives: {
    mask,
  },
  data: () => ({
    dateMask: '##/##/####',
    yearMask: '####',
    codePostalMask: '#####'
  }),
  computed: {
    ...mapGetters(['getFilterData'])
  }
}
</script>
