const state = {
  TypeCuisine: ['AUCUNE', 'AMÉRICAINE', 'SÉPARÉE', 'INDÉPENDANTE', 'INDUSTRIELLE', 'COIN CUISINE', 'AMÉRICAINE EQUIPÉE', 'SÉPARÉE EQUIPÉE', 'INDÉPENDANTE EQUIPÉE', 'COIN CUISINE EQUIPÉ', 'EQUIPÉE'],
  TypeChauffage: ['', 'INDIVIDUEL', 'COLLECTIF', 'MIXTE'],
  NatureChauffage: ['', 'ELECTRICITÉ', 'GAZ', 'FUEL'],
  ModeChauffage: ['', 'RADIATEUR', 'SOL', 'MIXTE'],
  EauChaude: ['', 'INDIVIDUEL', 'SOL', 'MIXTE']
}

// getters
const getters = {
  getTypeCuisine: (state) => {
    return state.TypeCuisine
  },
  getTypeChauffage: (state) => {
    return state.TypeChauffage
  },
  getNatureChauffage: (state) => {
    return state.NatureChauffage
  },
  getModeChauffage: (state) => {
    return state.ModeChauffage
  }
}

export default {
  state,
  getters
}
