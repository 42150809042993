import colors from 'vuetify/lib/util/colors'

export const customThemes =
  { 
    light : {
        primary     : colors.pink,
        secondary   : colors.purple,
        accent      : colors.cyan,
        error       : colors.red.accent2,
        warning     : colors.orange.darken1,
        info        : colors.lightBlue.darken1,
        success     : colors.green.accent4,
        tertiary    : colors.grey.lighten3,
        subtitle    : colors.purple.darken1,
        filterBtn   : colors.blueGrey,
        //customer
        civilite    : colors.grey.darken3,
        nom         : colors.indigo, 
        adresse     : colors.purple.darken2,
        // type bien
        appartement : colors.pink,
        maison      : colors.purple,
        local       : colors.teal,
        parking     : colors.blueGrey,
        terrain     : colors.green,
        immeuble    : colors.orange,
        fdc         : colors.brown,

        // etat bien
        libre       : colors.green,
        occupe      : colors.pink,
        loue        : colors.blue,
        option      : colors.purple,
        reserve     : colors.orange,
        suspendu    : colors.red.lighten2,
     
        disponible  : colors.green,
        offre       : colors.pink,
        compromis   : colors.blue,
        estimation  : colors.orange,

        // v-navigation
        tools : colors.blue.darken3

    },
    dark: {
        primary: colors.pink.lighten3,
        secondary:  colors.yellow,
    }
}