<template lang="html">
  <v-tab-item value="conditions-financieres">
    <v-container>
      <div class="row justify-center align-center">
        <div class="text-center subtitle-1 text-uppercase secondary--text"><strong>Informations financières</strong></div>
      </div>  
      <div class="row justify-center align-center">
        <div class="col col-lg-2 col-md-3 col-sm-6 col-12">
          <!-- offer.F1 -->
          <lib-v-currency id="Price" label="Loyer menusel C.C / TTC" v-model="offer.Price" ></lib-v-currency>
        </div>
        <div class="col col-lg-2 col-md-3 col-sm-6 col-12">
          <!-- offer.F7 -->
          <lib-v-currency id="F7" label="Valeur du Font de commerce" v-model="offer.F13" number></lib-v-currency>
        </div>
        <div class="col col-lg-2 col-md-3 col-sm-6 col-12">
          <!-- offer.F9 -->
          <lib-v-currency id="F9" label="Loyer mensuel H.C/H.T" v-model="offer.F1" ></lib-v-currency>
        </div>
        <div class="col col-lg-2 col-md-3 col-sm-6 col-12">
          <!-- offer.F3 -->
          <lib-v-currency id="F3" label="Loyer annuel H.C/H.T" v-model="offer.F2"></lib-v-currency>
        </div>
      </div>
      <div class="row justify-center align-center">
        <div class="col col-lg-2 col-md-3 col-sm-6 col-12">
          <!-- offer.F2 -->
          <lib-v-currency id="F2" label="Prov. charges mensuelle" v-model="offer.F5" ></lib-v-currency>
        </div>
        <div class="col col-lg-2 col-md-3 col-sm-6 col-12">
          <!-- offer.F6 -->
          <lib-v-currency id="F6" label="Prov. charges annuelle" v-model="offer.F6"></lib-v-currency>
        </div>
        <div class="col col-lg-2 col-md-3 col-sm-6 col-12">
          <!-- offer.F2 -->
          <lib-v-currency id="F2" label="Parking intérieur /unité/an" v-model="offer.F10" ></lib-v-currency>
        </div>
        <div class="col col-lg-2 col-md-3 col-sm-6 col-12">
          <!-- offer.F6 -->
          <lib-v-currency id="F6" label="Parking extérieur /unité/an" v-model="offer.F11"></lib-v-currency>
        </div>
      </div>
      <div class="row">
        <div class="col offset-md-3 col-md-3 col-sm-6 col-12">
          <div class="subtitle-1 text-uppercase secondary--text mb-3"><strong>Conditions du bail</strong></div>
          <div class="row justify-center align-center">
            <div class="col col-12">
              <!-- offer.S21 (Proximite)-->
              <lib-v-currency id="S21" label="Dépôt de garantie" v-model="offer.S21" ></lib-v-currency>
            </div>
          </div>
          <div class="row justify-center align-center">
            <div class="col col-12">
              <!-- offer.F4 -->
              <lib-v-currency id="TauxHonoraires" label="Taux honoraires" v-model="offer.TauxHonoraires" symbol="% loy. ann. H.T/H.C"></lib-v-currency>
            </div>
          </div>
          <div class="row justify-center align-center">
            <div class="col col-12">
              <!-- offer.F3 -->
              <lib-v-currency id="F3" label="Forfait honoraires H.T" v-model="offer.F3" ></lib-v-currency>
            </div>
          </div>
          <div class="row justify-center align-center">
            <div class="col col-12">
              <!-- offer.S52 -->
              <lib-v-currency id="F52" label="Règlement" v-model="offer.F52" ></lib-v-currency>
            </div>
          </div>
          <div class="row justify-center align-center">
            <div class="col col-12">
              <!-- offer.S48 -->
              <v-text-field name="S48" label="Charge honoraires" filled id="FS48" v-model="offer.S48"></v-text-field>
            </div>
          </div>
        </div>
        <div class="col col-sm-6 col-12">
          <div class="subtitle-1 text-uppercase secondary--text mb-3"><strong>Taxes</strong></div>
          <div class="row">
            <div class="col col-md-6 col-12">
              <!-- offer.S29 -->
              <lib-v-currency id="S29" label="Régime fiscal" v-model="offer.S29" ></lib-v-currency>
            </div>
          </div>
          <div class="row">
            <div class="col col-md-6 col-12">
              <!-- offer.F16 -->
              <lib-v-currency id="F16" label="Taux Taxe prof." v-model="offer.F16" symbol="%"></lib-v-currency>
            </div>
          </div>
          <div class="row">
            <div class="col col-md-6 col-12">
              <!-- offer.F14 -->
              <lib-v-currency id="F14" label="Taux TVA" v-model="offer.F14" symbol="%"></lib-v-currency>
            </div>
          </div>
          <div class="row">
            <div class="col col-md-6 col-12">
              <!-- offer.F9 -->
              <lib-v-currency id="F9" label="Impôt foncier (/m2/an)" v-model="offer.F9" symbol="€"></lib-v-currency>
            </div>
          </div>
          <div class="row">
            <div class="col col-md-6 col-12">
              <!-- offer.F14 -->
              <lib-v-currency id="F8" label="Taxe Bureau (/m2/an)" v-model="offer.F8" symbol="%"></lib-v-currency>
            </div>
          </div>

        </div>
      </div>
      <div class="row justify-center align-center">
          <v-checkbox
            name="B24"
            v-model.trim="offer.B24"
            label="Net de taxe"
            :true-value="'1'" 
            :false-value="'0'"
            class="mx-2"
          ></v-checkbox>
          <v-checkbox
            name="B23"
            v-model.trim="offer.B23"
            label="Impôt foncier à la charge du bailleur"
            :true-value="'1'" 
            :false-value="'0'"
            class="mx-2"
          ></v-checkbox>
      </div>
    </v-container>
  </v-tab-item>
</template>

<script>
  export default {
    props: ['offer']
  }
</script>
