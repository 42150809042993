<template lang="html">
  <v-tab-item value="descriptif-technique">
    <v-container>
      <div class="row justify-center align-center">
        <v-checkbox v-model.trim="offer.B5" label="Bureau" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
        <v-checkbox v-model.trim="offer.B6" label="Commerce" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
        <v-checkbox v-model.trim="offer.B7" label="Activité" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
        <v-checkbox v-model.trim="offer.B8" label="Professionnel" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
      </div>
      <div class="row align-center justify-center">
        <div class="col col-md-2 col-sm-3 col-4">
          <lib-v-currency id="F7" label="Surface totale" v-model="offer.F12" symbol="m2"></lib-v-currency>
        </div>
        <div class="col col-md-2 col-sm-3 col-4">
          <lib-v-currency id="F15" label="Surface mini" v-model="offer.F15" symbol="m2"></lib-v-currency>
        </div>
        <div class="col col-md-2 col-sm-3 col-4">
          <lib-v-currency id="F17" label="Longueur vitrine" v-model="offer.F17" symbol="m"></lib-v-currency>
        </div>
      </div>
      <div class="row justify-center align-center">
        <div class="col col-md-2 col-sm-3 col-4">
          <v-select :items="getTypeChauffage" v-model="offer.TypeChauffage" filled label="Type chauffage"></v-select>
        </div>
        <div class="col col-md-2 col-sm-3 col-4">
          <v-select :items="getNatureChauffage" v-model="offer.NatureChauffage" filled label="Nature chauffage"></v-select>
        </div>
        <div class="col col-md-2 col-sm-3 col-4">
          <v-select :items="getModeChauffage" v-model="offer.ModeChauffage" filled label="Mode chauffage"></v-select>
        </div>
        <div class="col col-md-2 col-sm-3 col-4">
          <v-text-field label="Eau chaude" filled v-model="offer.S48"></v-text-field>
        </div>
      </div>
      <div class="row align-center justify-center">
        <div class="col col-md-2 col-sm-3 col-6">
          <lib-v-number label="Etage" v-model="offer.Etage" ></lib-v-number>
        </div>
        <div class="col col-md-2 col-sm-3 col-6">
          <lib-v-number label="Nombre Niveaux" v-model="offer.NombreNiveaux" ></lib-v-number>
        </div>
        <div class="col col-md-2 col-sm-3 col-6">
          <lib-v-number label="Box/Garages" v-model="offer.I12" ></lib-v-number>
        </div>
        <div class="col col-md-2 col-sm-3 col-6">
          <lib-v-number label="Park. intérieurs" v-model="offer.NombrePkgInter" ></lib-v-number>
        </div>
        <div class="col col-md-2 col-sm-3 col-6">
          <lib-v-number label="Park. extérieurs" v-model="offer.NombrePkgExter" ></lib-v-number>
        </div>
      </div>
      <div class="row align-center justify-center">
        <v-checkbox v-model.trim="offer.B16" label="Gardiennage" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
        <v-checkbox v-model.trim="offer.IsInterphone" label="Interphone" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
        <v-checkbox v-model.trim="offer.B18" label="Vidéophone" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
        <v-checkbox v-model.trim="offer.IsDigicode" label="Digicode" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
      </div>
      <div class="row align-center justify-center">
        <v-checkbox v-model.trim="offer.B20" label="Climatisation" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
        <v-checkbox v-model.trim="offer.IsAscenseur" label="Ascenseur" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
        <v-checkbox v-model.trim="offer.B15" label="Télésurveillance" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
        <v-checkbox v-model.trim="offer.IsAccesHandicape" label="Accès handicapé" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
      </div>
      <div class="row align-center justify-center">
        <v-checkbox v-model.trim="offer.B14" label="Monte charge" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
        <v-checkbox v-model.trim="offer.B18" label="Immeuble collectif" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
        <v-checkbox v-model.trim="offer.B19" label="Immeuble indépendant" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
      </div>

      <div class="row">
        <v-subheader class="text-uppercase subheader--text">Description du local</v-subheader>
      </div>
      <div class="row">
        <div class="col col-12" >
          <v-textarea
            v-model="offer.NotesInternes" 
            placeholder="Description du local ..."
            auto-grow
            filled
            rows="10"
          ></v-textarea>
        </div> 
      </div> 
    </v-container>
  </v-tab-item>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  props: ['offer'],
  computed: {
    ...mapGetters([
      'getTypeChauffage',
      'getNatureChauffage',
      'getModeChauffage'
    ])
  }
}
</script>
