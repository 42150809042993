<template lang="html">
  <ul class="nav">
      <li>
        <router-link :to="{name:'home'}" class="nav--hover">
          <i class="material-icons">home</i>
          <p>Accueil</p>
        </router-link>
      </li>
      <li>
        <router-link :to="{name:'customers'}" class="nav--hover">
          <i class="material-icons">people</i>
          <p>Liste des clients</p>
        </router-link>
      </li>
      <li>
        <router-link :to="{name:'offers', params : {transaction : 'rents'}}" class="nav--hover">
          <i class="material-icons">domain</i>
          <p> Locations </p>
        </router-link>
      </li>
      <li>
        <router-link :to="{name:'offers', params : {transaction : 'sales'}}" class="nav--hover">
          <i class="material-icons">home</i>
          <p> Ventes </p>
        </router-link>
      </li>
      <li>
          <a href="#" class="nav--hover" @click.prevent="logout">
              <i class="material-icons">power_settings_new</i>
              <p>Quitter</p>
          </a>
      </li>
  </ul>
</template>

<script>
export default {
  methods: {
    logout () {
      this.$store.commit('LOGOUT')
      this.$router.replace(this.$route.query.redirect || '/login')
    }
  }
}
</script>
