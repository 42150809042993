const routes = [{
        path: '/login',
        name: 'login',
        component: () =>
            import ( /* webpackChunkName: "Login" */ '@/views/Login/Login')
    },
    {
        path: '/home',
        name: 'home',
        component: () =>
            import ( /* webpackChunkName: "Home" */ '@/views/Home/Home'),
        meta: { needGuard: true }
    },
    {
        path: '/profile',
        name: 'profile',
        component: () =>
            import ( /* webpackChunkName: "Profile" */ '@/views/Settings/Profile'),
        meta: { needGuard: true }
    },
    {
        path: '/settings',
        name: 'settings',
        component: () =>
            import ( /* webpackChunkName: "Settings" */ '@/views/Settings/Settings'),
        meta: { needGuard: true }
    },
    {
        path: '/customers',
        name: 'customers',
        component: () =>
            import ( /* webpackChunkName: "Customers" */ '@/views/Customers/Customers'),
        meta: { needGuard: true }
    },
    {
        path: '/customer/view/:id',
        name: 'customer',
        component: () =>
            import ( /* webpackChunkName: "CustomerView" */ '@/views/Customers/CustomerView'),
        meta: { needGuard: true }
    },
    {
        path: '/customer/:action',
        name: 'customer',
        component: () =>
            import ( /* webpackChunkName: "CustomerForm" */ '@/views/Customers/CustomerForm'),
        meta: { needGuard: true }
    },
    {
        path: '/newOffer/:transaction/:typeBien/:action',
        name: 'newOffer',
        component: () =>
            import ( /* webpackChunkName: "OfferUpdate" */ '@/views/Offers/OfferUpdate'),
        meta: { needGuard: true }
    },
    {
        path: '/offers/:transaction',
        name: 'offers',
        component: () =>
            import ( /* webpackChunkName: "OffersList" */ '@/views/Offers/OffersList'),
        meta: { needGuard: true }
    },
    {
        path: '/offer-details/:transaction/:id',
        name: 'offer-details',
        component: () =>
            import ( /* webpackChunkName: "OfferDetails" */ '@/views/Offers/OfferDetails'),
        meta: { needGuard: true }
    },
    {
        path: '/offer-update/:transaction/:id/:action',
        name: 'offer-update',
        component: () =>
            import ( /* webpackChunkName: "OfferUpdate" */ '@/views/Offers/OfferUpdate'),
        meta: { needGuard: true }
    },
    { path: '*', redirect: '/home' }

]

export default routes