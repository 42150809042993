import api from '@/api'

const state = {
  markers: []
}

// getters
const getters = {
  getMarkers: (state) => {
    return state.markers
  }
}

// actions
const actions = {
  fetchGeo: async ({commit}, offers) => {
    try {
      offers.forEach(offer => {
        setTimeout(async () => {
          console.log('getGeolocalisation :', offer)
    
          let _address = ''
          
          if (offer.Adresse.length > 0) {
            _address = offer.NumRue.trim() + ' ' + offer.TypeRue.trim() + ' ' + offer.Adresse.trim() 
          }
    
        /*  if (this.offer.SuiteAdresse.length > 0) {
            _address = '+' + offer.SuiteAdresse.trim() 
          }
          */
        _address = encodeURI(_address)
          if (offer.CodePostal.length > 0) {
            _address += ' ' + offer.CodePostal.trim() 
          }
          /*
          if (offer.Ville.length > 0) {
            _address += '&city=' + offer.Ville.trim() 
          } */
    
          /* if (this.offer.Pays.length > 0) {
            _address += '&country' + offer.Pays.trim() 
          } */
    
          console.log('Adresse :', _address)
          const response = await api.geoLocalisationApi(_address)
          //this.loading = true;
          //const response = await fetch(`https://nominatim.openstreetmap.org/search?format=geojson&limit=1&q=${_address}`)
          //const data = await response.json();
          console.log('===> response :', response)
          //this.geoLoc = data;
          //this.loading = false;
          if (response.features){
            commit('ADD_MARKER', {offerId: offer._id, marker: response.features[0]})
          }
        }, 400)
      })
    } catch (error) {
      return error
    }
  },
  regionGeoJson: async ({commit}) => {
    const response = await fetch("https://rawgit.com/gregoiredavid/france-geojson/master/regions/ile-de-france/region-ile-de-france.geojson")
    const data = await response.json();
    return data;
  }
}

// mutations
const mutations = {
  ADD_MARKER (state, marker) {
    state.markers.push(marker)
    console.log('state.markers :', state.markers)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
