<template lang="html">
    <v-tab-item value="informations-images">
        <div class="row">
            <v-subheader class="text-uppercase subheader--text">Images</v-subheader>
        </div>
        <lib-v-upload :offerId="offer._id" :offerImages="offer.images"></lib-v-upload>
    </v-tab-item>
</template>
<script>
export default {
  props: ['offer']
}
</script>
