<template lang="html">
  <v-expansion-panel>
      <v-expansion-panel-header>
        <template v-slot:default="{ open }">
          <v-subheader class="subtitle-1 text-uppercase primary--text" v-if="open">Descriptif technique</v-subheader>
          <v-subheader class="subtitle-1 text-uppercase" v-else>Descriptif technique</v-subheader>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <div class="row">
          <div class="col-6 col-sm-3 col-md-2">
            <p class="text-right">Nombre de pièces:</p>
          </div>
          <div class="col-6 col-sm-3 col-md-2">
            <p class="value tertiary text-right">{{offer.NombrePieces}}</p>
          </div>
          <div class="col-6 col-sm-3 col-md-2">
            <p class="text-right">Nombre de chambres:</p>
          </div>
          <div class="col-6 col-sm-3 col-md-2">
            <p class="value tertiary text-right">{{offer.NombreChambres}}</p>
          </div>
          <div class="col-6 col-sm-3 col-md-2">
            <p class="text-right">Etage:</p>
          </div>
          <div class="col-6 col-sm-3 col-md-2">
            <p class="value tertiary text-right">{{offer.Etage}} / {{offer.NombreNiveaux}} </p>
          </div>
        </div>
        <div class="row">  
          <div class="col-6 col-sm-3 col-md-2">
            <p class="text-right">Surface habitable:</p>
          </div>
          <div class="col-6 col-sm-3 col-md-2">
            <p class="value tertiary text-right">{{offer.F12}} m<sup>2</sup></p>
          </div>
          <div class="col-6 col-sm-3 col-md-2">
            <p class="text-right">Surface jardin:</p>
          </div>
          <div class="col-6 col-sm-3 col-md-2">
            <p class="value tertiary text-right">{{offer.F15}} m<sup>2</sup></p>
          </div>
          <div class="col-6 col-sm-3 col-md-2">
            <p class="text-right">Surface séjour:</p>
          </div>
          <div class="col-6 col-sm-3 col-md-2">
            <p class="value tertiary text-right">{{offer.F16}} m<sup>2</sup></p>
          </div>
        </div>
        <div class="row">
          <div class="col-6 col-sm-3 col-md-2">
            <p class="text-right">Type chauffage:</p>
          </div>
          <div class="col-6 col-sm-3 col-md-2">
            <p class="value tertiary">{{offer.TypeChauffage}}</p>
          </div>
          <div class="col-6 col-sm-3 col-md-2">
            <p class="text-right">Nature chauffage:</p>
          </div>
          <div class="col-6 col-sm-3 col-md-2">
            <p class="value tertiary">{{offer.NatureChauffage}}</p>
          </div>
          <div class="col-6 col-sm-3 col-md-2">
            <p class="text-right">Mode chauffage:</p>
          </div>
          <div class="col-6 col-sm-3 col-md-2">
            <p class="value tertiary">{{offer.ModeChauffage}}</p>
          </div>
        </div>
        <div class="row">         
          <div class="col-6 col-sm-3 col-md-2">
            <p class="text-right">Eau chaude:</p>
          </div>
          <div class="col-6 col-sm-3 col-md-2">
            <p class="value tertiary">{{offer.S48}}</p>
          </div>
          <div class="col-6 col-sm-3 col-md-2">
            <p class="text-right">Etat intérieur:</p>
          </div>
          <div class="col-6 col-sm-3 col-md-2">
            <p class="value tertiary">{{offer.EtatInterieur}}</p>
          </div>
          <div class="col-6 col-sm-3 col-md-2">
            <p class="text-right">Type cuisine:</p>
          </div>
          <div class="col-6 col-sm-3 col-md-2">
            <p class="value tertiary">{{offer.TypeCuisine}}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-6 col-sm-3 col-md-2">
            <p class="text-right">WC:</p>
          </div>
          <div class="col-6 col-sm-3 col-md-1">
            <p class="value tertiary text-right">{{offer.I7}}</p>
          </div>
          <div class="col-6 col-sm-3 col-md-2">
            <p class="text-right">Park. int.:</p>
          </div>
          <div class="col-6 col-sm-3 col-md-1">
            <p class="value tertiary text-right">{{offer.NombrePkgInter}}</p>
          </div>
          <div class="col-6 col-sm-3 col-md-2">
            <p class="text-right">Caves:</p>
          </div>
          <div class="col-6 col-sm-3 col-md-1">
            <p class="value tertiary text-right">{{offer.NombreCaves}}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-6 col-sm-3 col-md-2">
            <p class="text-right">Salles de bains:</p>
          </div>
          <div class="col-6 col-sm-3 col-md-1">
            <p class="value tertiary text-right">{{offer.I8}}</p>
          </div>
          <div class="col-6 col-sm-3 col-md-2">
            <p class="text-right">Park. ext.:</p>
          </div>
          <div class="col-6 col-sm-3 col-md-1">
            <p class="value tertiary text-right">{{offer.NombrePkgExter}}</p>
          </div>
          <div class="col-6 col-sm-3 col-md-2">
            <p class="text-right">Balcons:</p>
          </div>
          <div class="col-6 col-sm-3 col-md-1">
            <p class="value tertiary text-right">{{offer.NombreBalcons}}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-6 col-sm-3 col-md-2">
            <p class="text-right">Salles d'eau:</p>
          </div>
          <div class="col-6 col-sm-3 col-md-1">
            <p class="value tertiary text-right">{{offer.I9}}</p>
          </div>
          <div class="col-6 col-sm-3 col-md-2">
            <p class="text-right">Box :</p>
          </div>
          <div class="col-6 col-sm-3 col-md-1">
            <p class="value tertiary text-right">{{offer.I12}}</p>
          </div>
          <div class="col-6 col-sm-3 col-md-2">
            <p class="text-right">Terrasses:</p>
          </div>
          <div class="col-6 col-sm-3 col-md-1">
            <p class="value tertiary text-right">{{offer.NombreTerrasses}}</p>
          </div>
        </div>
        <div class="row" align="center" justify="center">
          <div class="ma-3 ml-md-10">
              <v-icon class="success--text" v-if="offer.B15 > 0">mdi-checkbox-marked-outline</v-icon>
              <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
              Meublé
          </div>
        </div>
        <div class="row">
          <v-subheader class="text-uppercase subheader--text">Description d'immeuble</v-subheader>
          <div class="pl-3 text text-left text-uppercase tertiary">
            <br/>
            {{ offer.NotesInternes }}
            <br/>
          </div> 
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
export default {
  props: ['offer'],
  data () {
    return {
      EtatInterieur: ['', 'A restaurer', 'Bon', 'Moyen', 'Passable']
    }
  }
}
</script>
