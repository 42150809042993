import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import profiles from './modules/profiles'
import customers from './modules/customers'
import offers from './modules/offers'
import geo from './modules/geo'
import referentiels from './modules/referentiels'
import createLogger from 'vuex/dist/logger'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

const store = new Vuex.Store({
  modules: {
    auth,
    profiles,
    customers,
    offers,
    geo,
    referentiels
  },
  // strict: debug,
  plugins: debug ? [createLogger()] : []
})

export default store
