import { render, staticRenderFns } from "./LF.vue?vue&type=template&id=46c4672e&lang=html&"
import script from "./LF.vue?vue&type=script&lang=js&"
export * from "./LF.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
installComponents(component, {VExpansionPanel,VExpansionPanelContent,VExpansionPanelHeader,VSubheader})
