<template lang="html">
  <v-text-field
    class="v-number--text"
    :label="label"
    :suffix="symbol"
    :disabled="disabled"
    :filled="!disabled"
    type="number"
    v-bind:value="value"
    v-on:input="updateValue($event)"></v-text-field>
</template>

<script>
import { currencyValidator } from '../../utils/CurrencyValidator'

export default {
  props: {
    value: {
      type: Number,
      default: 0
    },
    label: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: ''
    },
    symbol: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    updateValue (value) {
      var result = currencyValidator.parse(value, this.value)
      if (result.warning) {
        this.$refs.input.value = result.value
      }
      this.$emit('input', result.value)
    }
  }
}
</script>
<style lang="scss">
  $red : #ff5252;
  $green : #4CAF50;

  .v-number--text {
    &.v-input--is-disabled {
      font-weight: bold;
      input, .v-text-field__suffix {
        color: $red;
      }
      .v-label {
        color: $green !important;
      }

      & > .v-input__control > .v-input__slot:before {
        border: 1px dotted $green;
      }
    
    }

    input {
      text-align: right;
      /* Chrome, Safari, Edge, Opera */
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      &[type=number] {
        -moz-appearance: textfield;
      }
    }
  }
</style>
