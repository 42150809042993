
const menu = {
    data:()=>({
    items: [
        { title: 'Accueil', icon: 'mdi-home', ref:'home' },
        { title: 'Profil', icon: 'mdi-card-account-details-outline', ref:'profile' },
        { title: 'Déconnecter', icon: 'mdi-power', ref:'quit' },
        ]
    }),
    methods: {
        home(){
            if (this.$router.currentRoute.path !== '/home')
                this.$router.push({path:'/home'})
        },
        profile(){
            if (this.$router.currentRoute.path !== '/profile')
                this.$router.push({path:'/profile'})
        },
        quit(){
            this.logout()
            //this.$router.replace(this.$route.query.redirect || '/login')
            this.$router.push({path:'/login'})
        },
        _callFunction(function_name){
            this[function_name]()
        }
    }
}

export default menu