<template lang="html">
  <v-expansion-panel>
    <v-expansion-panel-header>
      <template v-slot:default="{ open }">
        <v-subheader class="subtitle-1 text-uppercase primary--text" v-if="open">Descriptif technique</v-subheader>
        <v-subheader class="subtitle-1 text-uppercase" v-else>Descriptif technique</v-subheader>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <div class="row">
        <div class="col-6 col-md-3">
          <p class="text-right">Nombre d'étages:</p>
        </div>
        <div class="col-6 col-md-1">
          <p class="value tertiary text-right">{{offer.I6}}</p>
        </div>
        <div class="col-6 col-md-2">
          <p class="text-right">Nombre de niveaux:</p>
        </div>
        <div class="col-6 col-md-1">
          <p class="value tertiary text-right">{{offer.NombreNiveaux}}</p>
        </div>
        <div class="col-6 col-md-2">
          <p class="text-right">Nombre de lots :</p>
        </div>
        <div class="col-6 col-md-1">
          <p class="value tertiary text-right">{{offer.NombrePieces}}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-6 col-md-3">
          <p class="text-right">Surface totale :</p>
        </div>
        <div class="col-6 col-md-1">
          <p class="value tertiary text-right">{{offer.F12}} m<sup>2</sup> </p>
        </div>
        <div class="col-6 col-md-2">
          <p class="text-right">Surface H.O :</p>
        </div>
        <div class="col-6 col-md-1">
          <p class="value tertiary text-right">{{offer.F18}} m<sup>2</sup></p>
        </div>
        <div class="col-6 col-md-2">
          <p class="text-right">Surface cadastrée :</p>
        </div>
        <div class="col-6 col-md-1">
          <p class="value tertiary text-right">{{offer.F14}} m<sup>2</sup></p>
        </div>
      </div>
      <div class="row">
        <div class="col-6 col-md-3">
          <p class="text-right">Surface utile:</p>
        </div>
        <div class="col-6 col-md-1">
          <p class="value tertiary text-right">{{offer.F30}} m<sup>2</sup></p>
        </div>
        <div class="col-6 col-md-2">
          <p class="text-right">Surface habitation:</p>
        </div>
        <div class="col-6 col-md-1">
          <p class="value tertiary text-right">{{offer.F16}} m<sup>2</sup></p>
        </div>
        <div class="col-6 col-md-2">
          <p class="text-right">Surface développée:</p>
        </div>
        <div class="col-6 col-md-1">
          <p class="value tertiary text-right">{{offer.F31}} m<sup>2</sup></p>
        </div>
      </div>
      <div class="row">
        <div class="col-6 col-md-3">
          <p class="text-right">Surface commerciale:</p>
        </div>
        <div class="col-6 col-md-1">
          <p class="value tertiary text-right">{{offer.F17}} m<sup>2</sup></p>
        </div>
        <div class="col-6 col-md-2">
          <p class="text-right">Surface terrain:</p>
        </div>
        <div class="col-6 col-md-1">
          <p class="value tertiary text-right">{{offer.F15}} m<sup>2</sup></p>
        </div>
        <div class="col-6 col-md-2">
          <p class="text-right">Surface jardin:</p>
        </div>
        <div class="col-6 col-md-1">
          <p class="value tertiary text-right">{{offer.F29}} m<sup>2</sup></p>
        </div>
      </div>
      <div class="row">
        <div class="col-6 col-md-3">
          <p class="text-right">Type chauffage:</p>
        </div>
        <div class="col-6 col-md-2">
          <p class="value tertiary">{{offer.TypeChauffage}}</p>
        </div>
        <div class="col-6 col-md-2">
          <p class="text-right">Nature chauff.:</p>
        </div>
        <div class="col-6 col-md-2">
          <p class="value tertiary">{{offer.NatureChauffage}}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-6 col-md-3">
          <p class="text-right">Mode chauffage:</p>
        </div>
        <div class="col-6 col-md-2">
          <p class="value tertiary">{{offer.ModeChauffage}}</p>
        </div>
        <div class="col-6 col-md-2">
          <p class="text-right">Eau chaude:</p>
        </div>
        <div class="col-6 col-md-2">
          <p class="value tertiary">{{offer.S48}}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-6 col-md-3">
          <p class="text-right">Parking extérieur:</p>
        </div>
        <div class="col-6 col-md-1">
          <p class="value tertiary text-right">{{offer.NombrePkgExter}}</p>
        </div>
        <div class="col-6 col-md-2">
          <p class="text-right">Parking intérieur:</p>
        </div>
        <div class="col-6 col-md-1">
          <p class="value tertiary text-right">{{offer.NombrePkgInter}}</p>
        </div>
        <div class="col-6 col-md-1">
          <p class="text-right">Box/garages :</p>
        </div>
        <div class="col-6 col-md-1">
          <p class="value tertiary text-right">{{offer.I12}}</p>
        </div>
        <div class="col-6 col-md-1">
          <p class="text-right">Caves:</p>
        </div>
        <div class="col-6 col-md-1">
          <p class="value tertiary text-right">{{offer.NombreCaves}}</p>
        </div>
      </div>
      <div class="row align-center justify-center">
        <div class="ma-4">
          <v-icon class="success--text" v-if="offer.B15 > 0">mdi-checkbox-marked-outline</v-icon>
          <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
          Immeuble collectif
        </div>
        <div class="ma-4">
          <v-icon class="success--text" v-if="offer.B17 > 0">mdi-checkbox-marked-outline</v-icon>
          <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
          Immeuble indépendant
        </div>
        <div class="ma-4">
          <v-icon class="success--text" v-if="offer.IsAscenseur > 0">mdi-checkbox-marked-outline</v-icon>
          <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
          Ascenseur
        </div>
        <div class="ma-4">
          <v-icon class="success--text" v-if="offer.IsDigicode > 0">mdi-checkbox-marked-outline</v-icon>
          <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
          Digicode
        </div>
        <div class="ma-4">
          <v-icon class="success--text" v-if="offer.IsInterphone > 0">mdi-checkbox-marked-outline</v-icon>
          <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
          Interphone
        </div>
        <div class="ma-4">
          <v-icon class="success--text" v-if="offer.B17 > 0">mdi-checkbox-marked-outline</v-icon>
          <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
          Prestige
        </div>
        <div class="ma-4">
          <v-icon class="success--text" v-if="offer.B16 > 0">mdi-checkbox-marked-outline</v-icon>
          <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
          Gardien
        </div>
        <div class="ma-4">
          <v-icon class="success--text" v-if="offer.B14 > 0">mdi-checkbox-marked-outline</v-icon>
          <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
          Piscine
        </div>
        <div class="ma-4">
          <v-icon class="success--text" v-if="offer.IsAccesHandicape > 0">mdi-checkbox-marked-outline</v-icon>
          <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
          Accès handicapés
        </div>
        <div class="ma-4">
          <v-icon class="success--text" v-if="offer.B40 > 0">mdi-checkbox-marked-outline</v-icon>
          <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
          Raccord T.A.E
        </div>
      </div>
      <div class="row">
        <v-subheader class="text-uppercase text-center">Description d'immeuble</v-subheader>
        <div class="text text-center text-uppercase tertiary">
          <br/>
          {{ offer.Description }}
          <br/>
        </div> 
      </div>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
export default {
  props: ['offer'],
  data () {
    return {
      EtatInterieur: ['', 'A restaurer', 'Bon', 'Moyen', 'Passable']
    }
  }
}
</script>
