import { render, staticRenderFns } from "./Immeuble.vue?vue&type=template&id=e824700e&lang=html&"
import script from "./Immeuble.vue?vue&type=script&lang=js&"
export * from "./Immeuble.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VCheckbox,VContainer,VDivider,VSelect,VSubheader,VTabItem,VTextField,VTextarea})
