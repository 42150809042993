// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import {vuetify} from '@/plugins/';

import '@/components/'
import App from './App.vue'

import store from '@/store'
import router from '@/router/'
import createLogger from 'vuex/dist/logger'

import messages from '@/assets/locale/fr'

// sync(store, router)
const debug = process.env.NODE_ENV !== 'production'

// check the secured routes
function guardRoute (to, from, next) {
  if (store.state.auth.connected) {
    next()
  } else {
    next('/login')
  }
}
router.beforeEach((to, from, next) => {
  if (to.matched.some(m => m.meta.needGuard)) {
    guardRoute(to, from, next)
  } else {
    next()
  }
})

/* eslint-disable no-new */

const vm = new Vue({
  router,
  store,
  strict: debug,
  plugins: debug ? [createLogger()] : [],

  mounted () {
    // let vm = this
    window.addEventListener('beforeunload', function (event) {
      // logout programmatically
    })
    window.addEventListener('offline', function (event) {
      window.alert('Vérifier votre connexion internet')
      // logout programmatically
      // vm.$store.commit('LOGOUT')
    })
    window.addEventListener('online', function (event) {
      window.alert('Connexion rétablie')
      // logout programmatically
      // vm.$store.commit('LOGOUT')
    })
  },

  destroyed () {
    this.$store.commit('LOGOUT')
  },

  vuetify,
  render: h => h(App)
}).$mount('#app')

Vue.prototype.$bus = vm
Vue.prototype.$myrouter = vm.$router
