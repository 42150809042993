<template lang="html">
  <v-tab-item value="descriptif-technique">
    <div class="row align-center justify-center">
      <div class="col col-md-2 col-sm-4 col-6">
        <lib-v-number label="Nombre de voitures" v-model="offer.I12"></lib-v-number>
      </div>
    </div>
    <div class="row">
      <v-subheader class="text-uppercase subheader--text">Type de stationnement</v-subheader>
    </div>
    <div class="row align-center justify-center">
      <v-radio-group v-model.number="offer.I7" row class="radio__group">  
        <v-radio color="primary" label="Côte à côte" :value="0"></v-radio>
        <v-radio color="secondary" label="Superposé" :value="1"></v-radio>
        <v-radio color="accent" label="Enfilade" :value="2"></v-radio>
      </v-radio-group>
    </div>
    <div class="row">
      <v-subheader class="text-uppercase subheader--text">Type d'accès</v-subheader>
    </div>
    <div class="row align-center justify-center">
      <v-radio-group v-model.number="offer.I8" row class="radio__group">  
        <v-radio color="primary" label="Rampe" :value="0"></v-radio>
        <v-radio color="secondary" label="Ascenseur" :value="1"></v-radio>
      </v-radio-group>
    </div>
    <div class="row align-center justify-center">
      <div class="col col-lg-1 col-sm-2">
        <lib-v-number label="Niveau" v-model="offer.Etage" ></lib-v-number>
      </div>
      <div class="col col-lg-1 col-sm-2">
        <lib-v-number label="Nbre niveaux" v-model="offer.I6" ></lib-v-number>
      </div>
    </div>
    <div class="row">
      <v-subheader class="text-uppercase subheader--text">Accès piéton</v-subheader>
    </div>
    <div class="row align-center justify-center">
      <div class="col col-sm-5 col-10">
        <v-radio-group v-model.number="offer.I9" row class="radio__group">  
          <v-radio color="primary" label="Escalier" :value="0"></v-radio>
          <v-radio color="secondary" label="Ascenseur" :value="1"></v-radio>
        </v-radio-group>
      </div>
    </div>
    <div class="row align-center justify-center">
      <div class="col col-6">
        <v-text-field label="Numéro" filled v-model="offer.S61"></v-text-field>
      </div>
    </div>
    <div class="row align-center justify-center">
      <div class="col col-lg-2 col-sm-3 col-6">
        <lib-v-currency id="F7" label="Surface totale" v-model="offer.F12" symbol="m2"></lib-v-currency>
      </div>
      <div class="col col-lg-2 col-sm-3 col-6">
        <lib-v-currency id="F15" label="Longueur" v-model="offer.F16" symbol="m"></lib-v-currency>
      </div>
      <div class="col col-lg-2 col-sm-3 col-6">
        <lib-v-currency id="F7" label="Largeur" v-model="offer.F17" symbol="m"></lib-v-currency>
      </div>
      <div class="col col-lg-2 col-sm-3 col-6">
        <lib-v-currency id="F7" label="Hauteur" v-model="offer.F18" symbol="m"></lib-v-currency>
      </div>
    </div>
    <div class="row align-center justify-center">
      <v-checkbox name="B16" v-model.trim="offer.B16" label="Gardiennage" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
      <v-checkbox name="IsAccesHandicape" v-model.trim="offer.IsAccesHandicape" label="Accès handicapé" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
      <v-checkbox name="B5" v-model.trim="offer.B5" label="Vidéo surveillance" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
      <v-checkbox name="IsAscenseur" v-model.trim="offer.IsAscenseur" label="Ascenseur" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>  
      <v-checkbox name="Bip" v-model.trim="offer.IsInterphone" label="Bip" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>  
      <v-checkbox name="IsDigicode" v-model.trim="offer.IsDigicode" label="Carte magnétique" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>  
    </div>
    <div class="row">
      <v-subheader class="text-uppercase subheader--text">Autres informations</v-subheader>
    </div>
    <div class="row">
      <div class="col col-12" >
        <v-textarea
          v-model="offer.NotesInternes" 
          placeholder="Informations complémentaires ..."
          auto-grow
          filled
          rows="10"
        ></v-textarea>
      </div> 
    </div>
  </v-tab-item>
</template>

<script>
export default {
  props: ['offer']
}
</script>
