<template lang="html">
  <v-tab-item value="informations-juridiques">
    <div class="row align-center justify-center">
      <div class="col col-lg-2 col-md-4 col-6">
        <v-text-field label="Gestionnaire" filled v-model="offer.S29"></v-text-field>
      </div>
      <div class="col col-lg-2 col-md-4 col-6">
        <v-text-field label="Syndic" filled v-model="offer.S26"></v-text-field>
      </div>
    </div>
    <div class="row">
      <v-subheader class="text-uppercase subheader--text">N° de lot</v-subheader>
    </div>
    <div class="row align-center justify-center">
      <div class="col col-lg-1 col-md-2 col-sm-4 col-6">
        <v-text-field label="Logement" filled v-model="offer.S60"></v-text-field>
      </div>
      <div class="col col-lg-1 col-md-2 col-sm-4 col-6">
        <v-text-field label="Cave" filled v-model="offer.S61"></v-text-field>
      </div>
      <div class="col col-lg-1 col-md-2 col-sm-4 col-6">
        <v-text-field label="Park. int." filled v-model="offer.S62"></v-text-field>
      </div>
      <div class="col col-lg-1 col-md-2 col-sm-4 col-6">
        <v-text-field label="Park. ext." filled v-model="offer.NumeroLotParkExt"></v-text-field>
      </div>
      <div class="col col-lg-1 col-md-2 col-sm-4 col-6">
        <v-text-field label="Garage" filled v-model="offer.NumeroLotGarage"></v-text-field>
      </div>
    </div>
    <div class="row">
      <v-subheader class="text-uppercase subheader--text">Parties communes</v-subheader>
    </div>
    <div class="row align-center justify-center">
      <v-checkbox name="B16" v-model.trim="offer.B16" label="Gardiennage" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
      <v-checkbox name="B17" v-model.trim="offer.B17" label="Interphone" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
      <v-checkbox name="B18" v-model.trim="offer.B18" label="Vidéophone" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
      <v-checkbox name="B19" v-model.trim="offer.B19" label="Digicode" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
      <v-checkbox name="B21" v-model.trim="offer.B21" label="Ascenseur" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
    </div>
    <div class="row align-center justify-center">  
      <v-checkbox name="B20" v-model.trim="offer.B20" label="Espaces verts" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
      <v-checkbox name="B22" v-model.trim="offer.B22" label="Antenne" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
      <v-checkbox name="B23" v-model.trim="offer.B23" label="Cable" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
      <v-checkbox name="IsPiscine" v-model.trim="offer.IsPiscine" label="Piscine" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
      <v-checkbox name="IsAccesHandicape" v-model.trim="offer.IsAccesHandicape" label="Accès handicapé" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
    </div>
    <div class="row align-center justify-center">
      <v-checkbox name="B24" v-model.trim="offer.B24" label="Chauffage" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
      <v-checkbox name="B25" v-model.trim="offer.B25" label="Eau chaude" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
      <v-checkbox name="B26" v-model.trim="offer.B26" label="Eau froide" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
      
    </div>
  </v-tab-item>
</template>

<script>
export default {
  props: ['offer']
}
</script>
