<template lang="html">
  <v-tab-item value="informations-diagnostics" class="pa-2 diag__wrapper">
    <div class="diagnostic">
      <div class="row align-center justify-start flex-nowrap">
        <div>
          <v-checkbox name="amiante" v-model.trim="offer.B38" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
        </div>
        <div class="picto">
          <img src="@/assets/img/diags/amiante.jpg" alt="" />
          <span>Amiante</span>          
        </div>
        <div class="ml-1">
          <v-text-field filled v-model="offer.D20" v-mask="'##/##/####'" placeholder="JJ/MM/AAAA"></v-text-field>
        </div>
        <div class="ml-1 flex-grow-1">
          <v-text-field filled v-model="offer.S78" placeholder="Commentaires"></v-text-field>
        </div>
      </div>
      <div class="row align-center justify-start flex-nowrap">
        <div>
          <v-checkbox name="amiante" v-model.trim="offer.B32" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
        </div>
        <div class="picto">
          <img src="@/assets/img/diags/ERNMT.jpg" alt="" />
          <span>E.R.N.M.T</span>          
        </div>
        <div class="ml-1">
          <v-text-field filled v-model="offer.DateDiagERNT" v-mask="'##/##/####'" placeholder="JJ/MM/AAAA"></v-text-field>
        </div>
        <div class="ml-1 flex-grow-1">
          <v-text-field filled v-model="offer.PrestaDiagERNT" placeholder="Commentaires"></v-text-field>
        </div>
      </div>
      <div class="row align-center justify-start flex-nowrap">
        <div>
          <v-checkbox name="electricite" v-model.trim="offer.B35" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
        </div>
        <div class="picto">
          <img src="@/assets/img/diags/electricite.jpg" alt="" />
          <span>Eléctricité</span>          
        </div>
        <div class="ml-1">
          <v-text-field filled v-model="offer.DateDiagElectrique" v-mask="'##/##/####'" placeholder="JJ/MM/AAAA"></v-text-field>
        </div>
        <div class="ml-1 flex-grow-1">
          <v-text-field filled v-model="offer.PrestaDiagElectrique" placeholder="Commentaires"></v-text-field>
        </div>
      </div>
      <div class="row align-center justify-start flex-nowrap">
        <div>
          <v-checkbox name="GAZ" v-model.trim="offer.B37" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
        </div>
        <div class="picto">
          <img src="@/assets/img/diags/gaz.jpg" alt="" />
          <span>GAZ</span>          
        </div>
        <div class="ml-1">
          <v-text-field filled v-model="offer.D19" v-mask="'##/##/####'" placeholder="JJ/MM/AAAA"></v-text-field>
        </div>
        <div class="ml-1 flex-grow-1">
          <v-text-field filled v-model="offer.S77" placeholder="Commentaires"></v-text-field>
        </div>
      </div>
      <div class="row align-center justify-start flex-nowrap">
        <div>
          <v-checkbox name="plomb" v-model.trim="offer.B33" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
        </div>
        <div class="picto">
          <img src="@/assets/img/diags/plomb.jpg" alt="" />
          <span>Plomb</span>          
        </div>
        <div class="ml-1">
          <v-text-field filled v-model="offer.D17" v-mask="'##/##/####'" placeholder="JJ/MM/AAAA"></v-text-field>
        </div>
        <div class="ml-1 flex-grow-1">
          <v-text-field filled vname="presta_plomb" v-model.trim="offer.S71" placeholder="Commentaires"></v-text-field>
        </div>
      </div>
      <div class="row align-center justify-start flex-nowrap">
        <div>
          <v-checkbox name="termites" v-model.trim="offer.B39" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
        </div>
        <div class="picto">
          <img src="@/assets/img/diags/termites.jpg" alt="" />
          <span>Termites</span>          
        </div>
        <div class="ml-1">
          <v-text-field filled v-model="offer.D21" v-mask="'##/##/####'" placeholder="JJ/MM/AAAA"></v-text-field>
        </div>
        <div class="ml-1 flex-grow-1">
          <v-text-field filled name="presta_termites" v-model.trim="offer.S81" placeholder="Commentaires"></v-text-field>
        </div>
      </div>
      <component is="offers-dpe-update" :offer="offer"></component>
    </div>
  </v-tab-item>
</template>

<script>
import { mask } from 'vue-the-mask'
export default {
  props: ['offer'],
  directives: {
    mask,
  },
  data: () => ({
    dateMask: '##/##/####',
    yearMask: '####',
    codePostalMask: '#####'
  })
}
</script>
<style lang="scss">
.diag {
  min-height: 480px;
}
  
</style>
