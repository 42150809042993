<template lang="html">
  <v-expansion-panel>
    <v-expansion-panel-header>
      <template v-slot:default="{ open }">
        <v-subheader class="subtitle-1 text-uppercase primary--text" v-if="open">Informations diagnostics</v-subheader>
        <v-subheader class="subtitle-1 text-uppercase" v-else>Informations diagnostics</v-subheader>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content class="diag__wrapper">
      <div class="diagnostic">
      <div class="row align-center justify-start flex-nowrap">
        <div>
          <i class="material-icons" v-if="offer.B6 > 0">check_box</i>
          <i class="material-icons" v-else>check_box_outline_blank</i>
        </div>
        <div class="picto">
          <img src="@/assets/img/diags/amiante.jpg" alt="" />
          <span>Amiante</span>          
        </div>
        <div class="ml-1">
          <p class="value tertiary">{{offer.D19}}</p>
        </div>
        <div class="ml-1 flex-grow-1">
          <p class="value tertiary">{{offer.S78}}</p>
        </div>
      </div>
      <div class="row align-center justify-start flex-nowrap">
        <div>
          <i class="material-icons" v-if="offer.B32 > 0">check_box</i>
          <i class="material-icons" v-else>check_box_outline_blank</i>
        </div>
        <div class="picto">
          <img src="@/assets/img/diags/ERNMT.jpg" alt="" />
          <span>E.R.N.M.T</span>          
        </div>
        <div class="ml-1">
          <p class="value tertiary">{{offer.DateDiagERNT}}</p>
        </div>
        <div class="ml-1 flex-grow-1">
          <p class="value tertiary">{{offer.PrestaDiagERNT}}</p>
        </div>
      </div>
      <div class="row align-center justify-start flex-nowrap">
        <div>
          <i class="material-icons" v-if="offer.B36 > 0">check_box</i>
          <i class="material-icons" v-else>check_box_outline_blank</i>
        </div>
        <div class="picto">
          <img src="@/assets/img/diags/electricite.jpg" alt="" />
          <span>Eléctricité</span>          
        </div>
        <div class="ml-1">
          <p class="value tertiary">{{offer.DateDiagElectrique}}</p>
        </div>
        <div class="ml-1 flex-grow-1">
          <p class="value tertiary">{{offer.PrestaDiagElectrique}}</p>
        </div>
      </div>
      <div class="row align-center justify-start flex-nowrap">
        <div>
          <i class="material-icons" v-if="offer.B33 > 0">check_box</i>
          <i class="material-icons" v-else>check_box_outline_blank</i>
        </div>
        <div class="picto">
          <img src="@/assets/img/diags/gaz.jpg" alt="" />
          <span>GAZ</span>          
        </div>
        <div class="ml-1">
          <p class="value tertiary">{{offer.D17}}</p>
        </div>
        <div class="ml-1 flex-grow-1">
          <p class="value tertiary">{{offer.S71}}</p>
        </div>
      </div>
      <div class="row align-center justify-start flex-nowrap">
        <div>
          <i class="material-icons" v-if="offer.B7 > 0">check_box</i>
          <i class="material-icons" v-else>check_box_outline_blank</i>
        </div>
        <div class="picto">
          <img src="@/assets/img/diags/plomb.jpg" alt="" />
          <span>Plomb</span>          
        </div>
        <div class="ml-1">
          <p class="value tertiary">{{offer.DateRelocation}}</p>
        </div>
        <div class="ml-1 flex-grow-1">
          <p class="value tertiary" name="presta_plomb">{{offer.S79}}</p>
        </div>
      </div>
      <div class="row align-center justify-start flex-nowrap">
        <div>
          <i class="material-icons" v-if="offer.B5 > 0">check_box</i>
          <i class="material-icons" v-else>check_box_outline_blank</i>
        </div>
        <div class="picto">
          <img src="@/assets/img/diags/carrez.jpg" alt="" />
          <span>Carrez</span>          
        </div>
        <div class="ml-1">
          <p class="value tertiary">{{offer.DateEtatLieux}}</p>
        </div>
        <div class="ml-1 flex-grow-1">
          <p class="value tertiary" name="presta_carrez">{{offer.S81}}</p>
        </div>
      </div>
      <div class="row align-center justify-start flex-nowrap">
        <div>
          <i class="material-icons" v-if="offer.B8 > 0">check_box</i>
          <i class="material-icons" v-else>check_box_outline_blank</i>
        </div>
        <div class="picto">
          <img src="@/assets/img/diags/termites.jpg" alt="" />
          <span>Termites</span>          
        </div>
        <div class="ml-1">
          <p class="value tertiary">{{offer.DateFinBail}}</p>
        </div>
        <div class="ml-1 flex-grow-1">
          <p class="value tertiary" name="presta_termites">{{offer.S82}}</p>
        </div>
      </div>
      <component is="offers-dpe-view" :offer="offer"></component>
    </div>

    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
export default {
  props: ['offer']
}
</script>