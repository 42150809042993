//import Swal from 'sweetalert2'
import Vue from 'vue'
//import { API_ROOT, MEDIA_ROOT } from '@/api/config'

const API_ROOT = process.env.VUE_APP_ROOT_API
const MEDIA_ROOT = process.env.VUE_APP_ROOT_MEDIA

export const FirstLetterUpper = (string) => {
    return string[0].toLocaleUpperCase + string.slice(1)
}

export const parseURL = url => {
    var parser = document.createElement('a')
    parser.href = url

    parser.protocol // => "http:"
    parser.hostname // => "example.com"
    parser.port // => "3000"
    parser.pathname // => "/pathname/"
    parser.search // => "?search=test"
    parser.hash // => "#hash"
    parser.host // => "example.com:3000"

    return parser
}

export const debug = (caption, msg) => {
    console.log('%c ' + caption, 'color: #9E9E9E; font-weight: bold', msg)
}

const localStore = {
        setString: (item, value) => window.sessionStorage.setItem(item, value),
        getString: item => {
            let _str = window.sessionStorage.getItem(item)
            if (typeof _str !== 'undefined') {
                return _str
            } else {
                return null
            }
        },
        setObject: (item, obj) => window.sessionStorage.setItem(item, JSON.stringify(obj)),
        getObject: item => {
            let _object = window.sessionStorage.getItem(item)
            if (typeof _object !== 'undefined') {
                return JSON.parse(_object)
            } else {
                return null
            }
        },
        setBoolean: (item, _bool) => window.sessionStorage.setItem(item, _bool),
        getBoolean: item => {
            let _bool = window.sessionStorage.getItem(item)
            if (typeof _bool !== 'undefined') {
                return JSON.parse(_bool)
            } else {
                return false
            }
        },
        removeItem: item => window.sessionStorage.removeItem(item)
    }
    //const getAccountId = () => window.sessionStorage.getItem('account_id')

const tools = {
    getVersion: () => {
        return '2.6.6'
    },
    getCurrentDate: () => (new Date()).toLocaleDateString(),
    getCurrentDateTime: () => {
        var ts = new Date()
        return ts.toLocaleString()
    },
    getCurrentCountry: () => 'FRANCE',
    getImage: (accountId, name) => {
        return `${MEDIA_ROOT}images/${accountId}/${name}`
    },
    getLogo: (accountId) => {
        return `${MEDIA_ROOT}identity/${accountId}.jpg`
    },
    getPdfImage: (accountId, name) => {
        return `${API_ROOT}pdf/${accountId}/${name}` // Important: éviter le problème du CORS
    },
    getIdentityImage: (accountId) => {
        let result = `/static/img/noimage-immorep.png`
        const IdentityImage = `${MEDIA_ROOT}identity/${accountId}.jpg`
        if (IdentityImage) {
            result = IdentityImage
        }
        return result
    },
    loadImageFromURL: async(url) => {
        return new Promise((resolve, abort) => {
            var img = new Image();
            img.onError = function() {
                alert('Cannot load image: "' + url + '"');
            };
            img.onload = function() {
                resolve(img);
            };
            img.src = url;
        })
    },
    getOfferImage(index, offer) {
        let result = '/static/img/white.png'
        if (this.offer.images) {
            const imagesNumber = this.offer.images.length
            if (index < imagesNumber) {
                result = this.$tools.getPdfImage(this.offer.account_id, this.offer.images[index].name)
            }
        }
        console.log('result:', result)
        return result
    },
}

export const filterFloat = (value) => {
    if (/^(-|\+)?([0-9]+(\.[0-9]+)?|Infinity)$/.test(value)) {
        return Number(value)
    }
    return NaN
}

export const currencyValidator = {
    format: (number, decimals) => {
        return (Math.trunc(number * 100) / 100).toFixed(decimals)
    },
    parse: (newString, oldNumber) => {
        var CleanParse = function(value) {
            return { value: value }
        }
        var CurrencyWarning = (warning, value) => {
            return {
                warning: warning,
                value: value,
                attempt: newString
            }
        }
        var NotAValidDollarAmountWarning = function(value) {
            return new CurrencyWarning(newString + ' is not a valid dollar amount', value)
        }
        var AutomaticConversionWarning = function(value) {
            return new CurrencyWarning(newString + ' was automatically converted to ' + value, value)
        }

        var newNumber = Number(newString)
        var indexOfDot = newString.indexOf('.')
        var indexOfE = newString.indexOf('e')

        if (isNaN(newNumber)) {
            if (
                indexOfDot === -1 &&
                indexOfE > 0 &&
                indexOfE === newString.length - 1 &&
                Number(newString.slice(0, indexOfE)) !== 0
            ) {
                return new CleanParse(oldNumber)
            } else {
                return new NotAValidDollarAmountWarning(oldNumber)
            }
        }

        var newCurrencyString = currencyValidator.format(newNumber)
        var newCurrencyNumber = Number(newCurrencyString)

        if (newCurrencyNumber === newNumber) {
            if (indexOfE !== -1 && indexOfE === newString.length - 2) {
                return new AutomaticConversionWarning(newNumber)
            } else {
                return new CleanParse(newNumber)
            }
        } else {
            return new NotAValidDollarAmountWarning(
                newNumber > newCurrencyNumber ?
                newCurrencyNumber :
                oldNumber
            )
        }
    }
}
const DPE = {
    getDPEDateRef : () => {
        return new Date('07/01/2021')
    },
    compareDPEDate : (dateDPE) => {
        const parseDate = dateDPE.split('/')
        let _dateDPE = new Date(`${parseDate[2]}-${parseDate[1]}-${parseDate[0]}`)
        console.log("_dateDPE :", _dateDPE)
        const dateRef = DPE.getDPEDateRef()
        console.log("dateRef :", dateRef)
        
        return (_dateDPE >= dateRef)

    },
    getDPEClass : (newRule, E, GES) => {
        if (newRule){
            /*
            Classe A : moins de 70 KWh/m² par an et de 6 kg CO2/m² par an
            Classe B : 71 à 110 KWh/m² par an et 7 à 11 kg CO2/m² par an
            Classe C : 111 à 180 KWh/m² par an et 12 à 30 kg CO2/m² par an
            Classe D : 181 à 250 KWh/m² par an et 31 à 50 kg CO2/m² par an
            Classe E : 251 à 330 KWh/m² par an et 51 à 70 kg CO2/m² par an
            Classe F : 331 à 420 KWh/m² par an et 71 à 100 kg CO2/m² par an
            Classe G : plus de 421 KWh/m² par an et de 101 kg CO2/m² par an
            */
            let classDPE = ' '
            if ((E <= 70) && (GES <= 6)) classDPE = 'A'
            if ((E >= 71) && (E <= 110) && (GES >= 7) && (GES <= 11)) classDPE = 'B'
            if ((E >= 111) && (E <= 180) && (GES >= 12) && (GES <= 30)) classDPE = 'C'
            if ((E >= 181) && (E <= 250) && (GES >= 31) && (GES <= 50)) classDPE = 'D'
            if ((E >= 251) && (E <= 330) && (GES >= 51) && (GES <= 70)) classDPE = 'E'
            if ((E >= 331) && (E <= 420) && (GES >= 71) && (GES <= 100)) classDPE = 'F'
            if ((E >= 421) && (GES >= 101)) classDPE = 'G'

            console.log('classDPE :', classDPE)
            
            return {E: classDPE, GES: classDPE}
        } else {
            let classE = 'NC'
            if (E <= 50) classE = 'A'
            if ((E >= 51) && (E <= 90)) classE = 'B'
            if ((E >= 91) && (E <= 150)) classE = 'C'
            if ((E >= 151) && (E <= 230)) classE = 'D'
            if ((E >= 231) && (E <= 330)) classE = 'E'
            if ((E >= 331) && (E <= 450)) classE = 'F'
            if (E > 450) classE = 'G'

            let classGES = 'NC'
            if (GES <= 5) classGES = 'A'
            if ((GES >= 6) && (GES <= 10)) classGES = 'B'
            if ((GES >= 11) && (GES <= 20)) classGES = 'C'
            if ((GES >= 21) && (GES <= 35)) classGES = 'D'
            if ((GES >= 36) && (GES <= 55)) classGES = 'E'
            if ((GES >= 56) && (GES <= 80)) classGES = 'F'
            if (GES > 80) classGES = 'G'
            
            return {E: classE, GES: classGES}

        }
    }
}

Vue.prototype.$localStore = localStore
Vue.prototype.$tools = tools
Vue.prototype.$dpe = DPE