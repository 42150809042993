<template lang="html">
  <v-tab-item value="descriptif-technique">
    <v-container>
      <div class="row align-center justify-center">
        <div class="col col-md-6">
          <v-text-field label="Aspect" filled v-model="offer.S47"></v-text-field>
        </div>
      </div>
      <div class="row align-center justify-center">
        <div class="col col-md-2 col-sm-3 col-4">
          <lib-v-currency id="F7" label="Surface" v-model="offer.F15" symbol="m2"></lib-v-currency>
        </div>
        <div class="col col-md-2 col-sm-3 col-4">
          <lib-v-currency id="F7" label="Surface de plancher" v-model="offer.F18" symbol="m2"></lib-v-currency>
        </div>
        <div class="col col-md-2 col-sm-3 col-4">
          <lib-v-number label="C.O.S" v-model="offer.F14" ></lib-v-number>
        </div>
      </div>
      <div class="row align-center justify-center">
        <div class="col col-md-2 col-sm-3 col-4">
          <lib-v-currency id="F15" label="Façade" v-model="offer.F12" symbol="m2"></lib-v-currency>
        </div>
        <div class="col col-md-2 col-sm-3 col-4">
          <lib-v-currency id="F7" label="Profondeur" v-model="offer.F16" symbol="m2"></lib-v-currency>
        </div>
        <div class="col col-md-2 col-sm-3 col-4">
          <lib-v-currency id="F7" label="Hauteur max" v-model="offer.F17" symbol="m"></lib-v-currency>
        </div>
      </div>
      <v-divider></v-divider>
      
      <div class="row justify-center align-center">
        <v-checkbox v-model.trim="offer.B6" label="Clôturé" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
        <v-checkbox v-model.trim="offer.IsAscenseur" label="Lotissement" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
        <v-checkbox v-model.trim="offer.B2" label="Viabilité" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
        <v-checkbox v-model.trim="offer.B5" label="Alignement" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
        <v-checkbox v-model.trim="offer.IsInterphone" label="Gaz" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
        <v-checkbox v-model.trim="offer.IsDigicode" label="Assainissement" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
      </div>

      <div class="row">
        <v-subheader class="text-uppercase subheader--text">Description du terrain</v-subheader>
      </div>
      <div class="row">
        <div class="col col-12" >
          <v-textarea
            v-model="offer.NotesInternes" 
            placeholder="Description du terrain ..."
            auto-grow
            filled
            rows="10"
          ></v-textarea>
        </div> 
      </div>
    </v-container>
  </v-tab-item>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  props: ['offer'],
  computed: {
    ...mapGetters([
      'getTypeCuisine',
      'getTypeChauffage',
      'getNatureChauffage',
      'getModeChauffage'
    ])
  },
  data () {
    return {
      category: ['Appartement', 'Maison', 'Terrain', 'Immeuble', 'Local', 'FDC', 'Parking'],
      EtatInterieur: ['', 'A restaurer', 'Bon', 'Moyen', 'Passable'],
      EtageType: ['', 'RJ', 'RC', 'DE']
    }
  }
}
</script>
