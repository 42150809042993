import Vue from 'vue'
import api from '@/api'

const state = {
    users: [],
    user: {},
    currentUser: {},
    account: {}
}

// getters
const getters = {
    getUsers: (state) => {
        return state.users
    },
    getUser: (state) => {
        return state.user
    },
    getCurrentUser: (state) => {
        return state.currentUser
    },
    getAccount: (state) => {
        return state.account
    }
}

// actions
const actions = {
    updateUser: ({ commit }, user) => {

        return api.updateUser(user).then((response) => {
            commit('UPDATE_USER', user)
            return response
        }, (response) => {
            return response
        })
    },
    removeUser: async({ commit }, payload) => {
        try {
            const response = await api.removeUser(payload)
            commit('REMOVE_USER', payload.offer)
            return response
        } catch (error) {
            return error
        }
    },
    fetchUser: async({ commit }, payload) => {
        //console.log('fetchUser - payload :', payload)
        try {
            payload['collection'] = 'users'
            const response = await api.getUser(payload)
            commit('GET_USER', response)
            return response
        } catch (error) {
            return error
        }
    },
    fetchUsers: async({ commit }, payload) => {
        try {
            const response = await api.getUsers(payload)
            commit('GET_USERS', response)
            return response
        } catch (error) {
            return error
        }
    },
    setCurrentUser: ({ commit }, user) => {
        // console.log('commit update - user :', user)
        commit('CURRENT_USER', user)
    },
    fetchAccount: async({ commit }, payload) => {
        //console.log('fetchAccount :', payload)
        try {
            payload['collection'] = 'accounts'
            const response = await api.getAccount(payload)
            commit('GET_ACCOUNT', response)
            return response
        } catch (error) {
            return error
        }
    }

}

// mutations
const mutations = {
    GET_USERS(state, response) {
        let data = response
        state.users = data
    },
    GET_USER(state, user) {
        state.user = user
    },
    ADD_USER(state, user) {
        state.users.push(user)
    },
    UPDATE_USER(state, user) {
        let index = state.users.indexOf(user)
        state.users.splice(index, 1, user)
    },
    REMOVE_USER(state, user) {
        let index = state.users.indexOf(user)
        state.users.splice(index, 1)
    },
    TAB_USER_LIST(state, _state) {
        state.tabUserList = _state
    },
    TAB_USER_DETAILS(state, _state) {
        state.tabUserDetails = _state
    },
    CURRENT_USER(state, user) {
        state.currentUser = user
    },
    GET_ACCOUNT(state, account) {
        Vue.prototype.$localStore.setString('slogan', account.header)
        state.account = account
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}