<template lang="html">
  <v-tab-item value="conditions-financieres">
    <div class="row align-center justify-center">
      <v-radio-group v-model.number="offer.I29" row class="radio__group"> 
        <v-radio color="primary" label="Charge honoraires vendeur" :value="0"></v-radio>
        <v-radio color="secondary" label="Charge honoraires acquéreur" :value="1"></v-radio>
      </v-radio-group>
    </div>
    <div class="row align-center justify-center">
      <div class="col col-lg-2 col-md-3 col-sm-6 col-6">
        <!-- offer.F32 -->
        <lib-v-currency id="F32" label="Prix vente" v-model="offer.F32"></lib-v-currency>
      </div>
      <div class="col col-lg-2 col-md-3 col-sm-6 col-6">
        <!-- offer.Price -->
        <lib-v-currency id="Price" label="Prix vente FAI" v-model="offer.Price" ></lib-v-currency>
      </div>
      <div class="col col-lg-2 col-md-3 col-sm-6 col-6">
        <!-- offer.F3 -->
        <lib-v-currency id="F3" label="Honoraires TTC" v-model="offer.F3"></lib-v-currency>
      </div>
      <div class="col col-lg-2 col-md-3 col-sm-6 col-6">
        <!-- offer.TauxHonoraires -->
        <lib-v-currency id="TauxHonoraires" label="% honoraires" v-model="offer.TauxHonoraires" symbol="%"></lib-v-currency>
      </div>
    </div>
    <div class="row align-center justify-center">
      <div class="col col-lg-2 col-md-3 col-sm-6 col-6">
        <!-- offer.F2 -->
        <lib-v-currency id="F2" label="Net vendeur" v-model="offer.F2"></lib-v-currency>
      </div>
      <div class="col col-lg-2 col-md-3 col-sm-6 col-6">
        <!-- offer.F6 -->
        <lib-v-currency id="F6" label="Prix négociable" v-model="offer.F6" ></lib-v-currency>
      </div>
      <div class="col col-lg-2 col-md-3 col-sm-6 col-6">
        <!-- offer.F5 -->
        <lib-v-currency id="F5" label="Prix estimé (min)" v-model="offer.F5"></lib-v-currency>
      </div>
      <div class="col col-lg-2 col-md-3 col-sm-6 col-6">
        <!-- offer.F35 -->
        <lib-v-currency id="F35" label="Prix estimé (max)" v-model="offer.F35" ></lib-v-currency>
      </div>
    </div>
    <div class="row align-center justify-center">
      <div class="col col-lg-2 col-md-3 col-sm-6 col-6">
        <!-- offer.F13 -->
        <lib-v-currency id="F13" label="Prix de départ" v-model="offer.F13"></lib-v-currency>
      </div>
      <div class="col col-lg-2 col-md-3 col-sm-6 col-6">
        <v-text-field label="Date modif. prix" filled v-model="offer.DateLoyer" v-mask="'##/##/####'"></v-text-field>
      </div>
    </div>
    <v-divider></v-divider>
    <div class="row align-center justify-center">
      <div class="col col-lg-2 col-md-3 col-sm-6 col-6">
        <!-- offer.F7 -->
        <lib-v-currency id="F7" label="Travaux" v-model="offer.F7"></lib-v-currency>
      </div>
      <div class="col col-lg-2 col-md-3 col-sm-6 col-6">
        <!-- offer.F8 -->
        <lib-v-currency id="F8" label="Taxe habitation" v-model="offer.F8" ></lib-v-currency>
      </div>
      <div class="col col-lg-2 col-md-3 col-sm-6 col-6">
        <!-- offer.F10 -->
        <lib-v-currency id="F10" label="Charges annuelles" v-model="offer.F10"></lib-v-currency>
      </div>
      <div class="col col-lg-2 col-md-3 col-sm-6 col-6">
        <!-- offer.F9 -->
        <lib-v-currency id="F9" label="Taxe foncière" v-model="offer.F9" ></lib-v-currency>
      </div>
    </div>
    <div class="row align-center justify-center">
      <div class="col col-lg-3 col-md-3 col-4">
        <v-text-field label="Nom apporteur" filled v-model="offer.S67"></v-text-field>
      </div>
      <div class="col col-lg-2 col-md-3 col-sm-6 col-6">
        <!-- offer.28 -->
        <lib-v-currency id="F28" label="Taux Apporteur" v-model="offer.F28" symbol="%"></lib-v-currency>
      </div>
    </div>
  </v-tab-item>
</template>

<script>
import { mask } from 'vue-the-mask'

export default {
  props: ['offer'],
  directives: {
    mask,
  }
}
</script>
