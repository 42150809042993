<template>
  <v-app class="ma-0 " dark>
    <!-- dialogue de confirmation -->
    <lib-v-confirm ref="confirm"></lib-v-confirm>
    <!-- message de notfication -->
    <lib-v-alert ref="alert"></lib-v-alert>

    <!-- Application -->
    <core-header :connected="connected"></core-header>
    <core-sidebar :connected="connected"></core-sidebar>
    
   <router-view v-if="connected" class="main-wrapper"></router-view>
  <login v-else></login>
  <footer class="v-footer v-sheet theme--light v-footer--absolute v-footer-padless" 
      style="border-top: 1px solid #D4D4D4;">
      <div class="col-12 text-center">
        (C) {{ new Date().getFullYear() }} — <strong>immorep</strong> - Version {{$tools.getVersion()}}
      </div>
  </footer>
  </v-app>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
export default {
  name: 'App',
  components: {
    Login: () => import(/* webpackChunkName: "Header" */ '@/views/Login/Login')
  },
  computed: mapGetters([
    'connected'
  ]),
  mounted(){
    this.$root.$confirm = this.$refs.confirm.open
    this.$root.$alert = this.$refs.alert.show
    Vue.prototype.$alert = this.$refs.alert.show
  }
};
</script>