<template lang="html">
  <v-expansion-panel>
    <v-expansion-panel-header>
      <template v-slot:default="{ open }">
        <v-subheader class="subtitle-1 text-uppercase primary--text" v-if="open">Informations agence</v-subheader>
        <v-subheader class="subtitle-1 text-uppercase" v-else>Informations agence</v-subheader>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-container>
        <div class="row">
          <v-subheader class="text-uppercase subheader--text">Informations vendeur</v-subheader>
          <div class="col-4 col-md-2">
            <p  class="text-right">Vendeur:</p>
          </div>
          <div class="col-8 col-md-8">
            <p class="value tertiary">{{offer.NomProprietaire}}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-4 col-md-2">
            <p  class="text-right">Téléphone :</p>
          </div>
          <div class="col-8 col-md-2">
            <p class="value tertiary">{{offer.TelBailleur}}</p>
          </div>
          <div class="col-4 col-md-1">
            <p  class="text-right">Email :</p>
          </div>
          <div class="col-8 col-md-5">
            <p class="value tertiary">{{offer.EmailBailleur}}</p>
          </div>
        </div>
        <div class="row">
          <v-subheader class="text-uppercase subheader--text">Notes</v-subheader>
          <div class="pl-3 text text-left text-uppercase tertiary">
            <br/>
            {{ offer.NotesProprietaire }}
            <br/>
          </div> 
        </div>
        <div class="row">
          <v-subheader class="text-uppercase subheader--text">Consignes de visite</v-subheader>
          <div class="col-4 col-md-2">
            <p  class="text-right">N° Clé :</p>
          </div>
          <div class="col-8 col-md-2">
            <p  class="value tertiary">{{ offer.NombreCles }}</p>
          </div>
          <div class="col-4 col-md-2">
            <p  class="text-right">Digicode :</p>
          </div>
          <div class="col-8 col-md-2">
            <p  class="value tertiary">{{ offer.Digicode }}</p>
          </div>
          <div class="col-4 col-md-2">
            <p  class="text-right">Accès :</p>
          </div>
          <div class="col-8 col-md-2">
            <p  class="value tertiary">{{ offer.AccesVisite }}</p>
          </div>
        </div>
        <div class="row">
          <v-subheader class="text-uppercase subheader--text">Commentaire visite</v-subheader>
          <div class="pl-3 text text-left text-uppercase tertiary">
            <br/>
            {{ offer.CommentaireVisite }}
            <br/>
          </div> 
        </div>
      </v-container>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
export default {
  props: ['offer']
}
</script>
