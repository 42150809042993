<template lang="html">
  <v-tab-item value="descriptif-technique">
    <div class="row align-center justify-center">
      <div class="col col-md-2 col-sm-3 col-6">
        <lib-v-number label="Nombre de pièces" v-model="offer.NombrePieces" ></lib-v-number>
      </div>
      <div class="col col-md-2 col-sm-3 col-6">
        <lib-v-number label="Nombre de chambres" v-model="offer.NombreChambres" ></lib-v-number>
      </div>
      <div class="col col-md-2 col-sm-3 col-6">
        <lib-v-number label="Etage" v-model="offer.Etage" ></lib-v-number>
      </div>
      <div class="col col-md-2 col-sm-3 col-6">
        <lib-v-number label="Nombre niveaux" v-model="offer.NombreNiveaux" ></lib-v-number>
      </div>
    </div>
    <div class="row align-center justify-center">
      <div class="col col-md-2 col-sm-4 col-6">
        <lib-v-currency id="F7" label="Surface habitable" v-model="offer.F12" symbol="m2"></lib-v-currency>
      </div>
      <div class="col col-md-2 col-sm-4 col-6">
        <lib-v-currency id="F15" label="Surface terrain" v-model="offer.F15" symbol="m2"></lib-v-currency>
      </div>
      <div class="col col-md-2 col-sm-4 col-6">
        <lib-v-currency id="F29" label="Surface jardin" v-model="offer.F29" symbol="m2"></lib-v-currency>
      </div>
      <div class="col col-md-2 col-sm-4 col-6">
        <lib-v-currency id="F16" label="Surface séjour" v-model="offer.F16" symbol="m2"></lib-v-currency>
      </div>
      <div class="col col-md-2 col-sm-4 col-6">
        <lib-v-currency id="F17" label="Surface dépendance" v-model="offer.F17" symbol="m2"></lib-v-currency>
      </div>
    </div>
    <div class="row align-center justify-center">
      <div class="col col-lg-2 col-md-2 col-sm-4 col-6">
        <v-text-field label="Type chauffage" filled v-model="offer.TypeChauffage"></v-text-field>
      </div>
      <div class="col col-lg-2 col-md-2 col-sm-4 col-6">
        <v-text-field label="Nature chauffage" filled v-model="offer.NatureChauffage"></v-text-field>
      </div>
      <div class="col col-lg-2 col-md-2 col-sm-4 col-6">
        <v-text-field label="Mode chauffage" filled v-model="offer.ModeChauffage"></v-text-field>
      </div>
      <div class="col col-md-2 col-sm-2">
        <v-text-field label="Eau chaude" filled v-model="offer.S48"></v-text-field>
      </div>
      <div class="col col-lg-3 col-md-3 col-sm-3 col-3">
        <v-text-field label="Etat intérieur" filled v-model="offer.EtatInterieur"></v-text-field>
      </div>
    </div>
    <div class="row align-center justify-center">
      <v-checkbox name="IsInterphone" v-model.trim="offer.IsInterphone" label="Interphone" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
      <v-checkbox name="IsDigicode" v-model.trim="offer.IsDigicode" label="Alarme" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
      <v-checkbox name="B15" v-model.trim="offer.B15" label="Meublé" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
    </div>
    <div class="row align-center justify-center">
      <div class="col col-md-2 col-sm-4 col-6">
        <lib-v-number label="WC" filled v-model="offer.I7" ></lib-v-number>
      </div>
      <div class="col col-md-2 col-sm-4 col-6">
        <lib-v-number label="Park. int." v-model="offer.NombrePkgInter" ></lib-v-number>
      </div>
      <div class="col col-md-2 col-sm-4 col-6">
        <lib-v-number label="Caves" v-model="offer.NombreCaves" ></lib-v-number>
      </div>
    </div>
    <div class="row align-center justify-center">
      <div class="col col-md-2 col-sm-4 col-6">
        <lib-v-number label="Salles de bains" filled v-model="offer.I8" ></lib-v-number>
      </div>
      <div class="col col-md-2 col-sm-4 col-6">
        <lib-v-number label="Park. ext." v-model="offer.NombrePkgExter" ></lib-v-number>
      </div>
      <div class="col col-md-2 col-sm-4 col-6">
        <lib-v-number label="Balcons" v-model="offer.NombreBalcons" ></lib-v-number>
      </div>
    </div>
    <div class="row align-center justify-center">
      <div class="col col-md-2 col-sm-4 col-6">
        <lib-v-number label="Salles d'eau" filled v-model="offer.I9" ></lib-v-number>
      </div>
      <div class="col col-md-2 col-sm-4 col-6">
        <lib-v-number label="Garages" v-model="offer.I12" ></lib-v-number>
      </div>
      <div class="col col-md-2 col-sm-4 col-6">
        <lib-v-number label="Terrasses" v-model="offer.NombreTerrasses" ></lib-v-number>
      </div>
    </div>
    <div class="row align-center justify-center">
      <v-checkbox name="IsAscenseur" v-model.trim="offer.IsAscenseur" label="Lotissement" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
      <v-checkbox name="B14" v-model.trim="offer.B14" label="Piscine" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
      <v-checkbox name="IsAccesHandicape" v-model.trim="offer.IsAccesHandicape" label="Accès handicapé" :true-value="'1'" :false-value="'0'" class="mx-2"></v-checkbox>
    </div>
    <div class="row">
      <v-subheader class="text-uppercase subheader--text">Description construction</v-subheader>
    </div>
    <div class="row">
      <div class="col col-12" >
        <v-textarea
          v-model="offer.NotesInternes" 
          placeholder="Description de la construction ..."
          auto-grow
          filled
          rows="10"
        ></v-textarea>
      </div> 
    </div>
  </v-tab-item>
</template>

<script>

export default {
  props: ['offer']
}
</script>
