<template lang="html">
  <v-expansion-panel>
      <v-expansion-panel-header>
        <template v-slot:default="{ open }">
          <v-subheader class="subtitle-1 text-uppercase primary--text" v-if="open">Descriptif technique</v-subheader>
          <v-subheader class="subtitle-1 text-uppercase" v-else>Descriptif technique</v-subheader>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <div class="row">
          <div class="col-6 col-md-3">
            <p class="text-right">Nombre de voitures :</p>
          </div>
          <div class="col-6 col-md-2">
            <p class="value tertiary text-right">{{offer.I12}}</p>
          </div>
        </div>
        <div class="row">
          <v-subheader class="text-uppercase text-center">Type de stationnement</v-subheader>
          <div class="ma-4">
            <v-icon class="success--text" v-if="offer.I7 === 0">mdi-checkbox-marked-outline</v-icon>
            <v-icon  v-else>mdi-checkbox-blank-outline</v-icon>
            Cote à cote
          </div>
          <div class="ma-4">
            <v-icon class="success--text" v-if="offer.I7 === 1">mdi-checkbox-marked-outline</v-icon>
            <v-icon  v-else>mdi-checkbox-blank-outline</v-icon>
            Superposé
          </div>
          <div class="ma-4">
            <v-icon class="success--text" v-if="offer.I7 === 2">mdi-checkbox-marked-outline</v-icon>
            <v-icon  v-else>mdi-checkbox-blank-outline</v-icon>
            Enfilade
          </div>
        </div>
        <div class="row">
          <v-subheader class="text-uppercase text-center">Type d'accès</v-subheader>
          <div class="ma-4">
            <v-icon class="success--text" v-if="offer.I8 === 0">mdi-checkbox-marked-outline</v-icon>
            <v-icon  v-else>mdi-checkbox-blank-outline</v-icon>
            Rampe
          </div>
          <div class="ma-4">
            <v-icon class="success--text" v-if="offer.I8 === 1">mdi-checkbox-marked-outline</v-icon>
            <v-icon  v-else>mdi-checkbox-blank-outline</v-icon>
            Ascenseur
          </div>
          <div class="ma-4">
            <p class="text-right">Niveau :</p>
          </div>
          <div class="ma-4">
            <p class="value tertiary">{{offer.Etage}} / {{offer.I6}}</p>
          </div>
        </div>
        <div class="row">
          <v-subheader class="text-uppercase text-center">Accès piéton</v-subheader>
          <div class="ma-4">
            <v-icon class="success--text" v-if="offer.I9 === 0">mdi-checkbox-marked-outline</v-icon>
            <v-icon  v-else>mdi-checkbox-blank-outline</v-icon>
            Escalier
          </div>
          <div class="ma-4">
            <v-icon class="success--text" v-if="offer.I9 === 1">mdi-checkbox-marked-outline</v-icon>
            <v-icon  v-else>mdi-checkbox-blank-outline</v-icon>
            Ascenseur
          </div>
          <div class="ma-4">
            <p class="text-right">Numéro :</p>
          </div>
          <div class="ma-4">
            <p class="value tertiary">{{offer.S61}}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-6 col-md-3">
            <p class="text-right">Surface :</p>
          </div>
          <div class="col-6 col-md-2">
            <p class="value tertiary text-right">{{offer.F12}} m<sup>2</sup></p>
          </div>
          <div class="col-6 col-md-2">
            <p class="text-right">Longueur :</p>
          </div>
          <div class="col-6 col-md-2">
            <p class="value tertiary text-right">{{offer.F16}} m</p>
          </div>
        </div>
        <div class="row">
          <div class="col-6 col-md-3">
            <p class="text-right">Largeur :</p>
          </div>
          <div class="col-6 col-md-2">
            <p class="value tertiary text-right">{{offer.F17}} m</p>
          </div>
          <div class="col-6 col-md-2">
            <p class="text-right">Hauteur :</p>
          </div>
          <div class="col-6 col-md-2">
            <p class="value tertiary text-right">{{offer.F18}} m</p>
          </div>
        </div>
        <div class="row align-center justify-center">
          <div class="ma-4">
            <v-icon class="success--text" v-if="offer.B16 > 0">mdi-checkbox-marked-outline</v-icon>
            <v-icon  v-else>mdi-checkbox-blank-outline</v-icon>
            Gardiennage
          </div>
          <div class="ma-4">
            <v-icon class="success--text" v-if="offer.IsAccesHandicape > 0">mdi-checkbox-marked-outline</v-icon>
            <v-icon  v-else>mdi-checkbox-blank-outline</v-icon>
            Accès handicapé
          </div>
          <div class="ma-4">
            <v-icon class="success--text" v-if="offer.B5 > 0">mdi-checkbox-marked-outline</v-icon>
            <v-icon  v-else>mdi-checkbox-blank-outline</v-icon>
            Vidéo surveillance
          </div>
          <div class="ma-4">
            <v-icon class="success--text" v-if="offer.IsAscenseur > 0">mdi-checkbox-marked-outline</v-icon>
            <v-icon  v-else>mdi-checkbox-blank-outline</v-icon>
            Ascenseur
          </div>
          <div class="ma-4">
            <v-icon class="success--text" v-if="offer.IsInterphone > 0">mdi-checkbox-marked-outline</v-icon>
            <v-icon  v-else>mdi-checkbox-blank-outline</v-icon>
            Bip
          </div>
          <div class="ma-4">
            <v-icon class="success--text" v-if="offer.IsDigicode > 0">mdi-checkbox-marked-outline</v-icon>
            <v-icon  v-else>mdi-checkbox-blank-outline</v-icon>
            Carte magnétique
          </div>
        </div>
        <div class="row">
          <v-subheader class="text-uppercase text-center">Autres informations</v-subheader>
          <div class="pl-3 text text-left  text-uppercase tertiary">
            <br/>
            {{ offer.NotesInternes }}
            <br/>
          </div> 
        </div>
      </div>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
export default {
  props: ['offer'],
  data () {
    return {
      category: ['Appartement', 'Maison', 'Local', 'Parking'],
      EtatInterieur: ['', 'A restaurer', 'Bon', 'Moyen', 'Passable']
    }
  }
}
</script>
