<template lang="html">
  <div class="top-panel">
    <ul class="">
      <li>
          <div class="dropdown  menu-plus">
            <a href="#" class="btn dropdown-toggle btn-round" data-toggle="dropdown">
                <i class="material-icons">add</i>
            </a>
            <ul class="dropdown-menu">
              <li>
                <a href="#">
                  <i class="material-icons">domain</i>
                  Nouvelle offre de location
                </a>
              </li>
              <li>
                <a href="#">
                  <i class="material-icons">home</i>
                  Nouvelle offre de vente
                </a>
              </li>
              <li class="divider"></li>
              <li>
                <!--
                <router-link :to="{name:'newCustomer'}" class="nav--hover">
                  <i class="material-icons">person_add</i>
                  Ajouter un client
                </router-link>
              -->
              </li>
              <li>
                <a href="#">
                  <i class="material-icons">contact_mail</i>
                  Ajouter un contact
                </a>
              </li>
            </ul>
          </div>
      </li>
      <li>
        <div class="dropdown menu-search">
          <a href="#" class="btn dropdown-toggle btn-round" data-toggle="dropdown">
            <i class="material-icons">search</i>
            Search
          </a>
          <ul class="dropdown-menu">
            <li>
              <a href="#"
                class="btn btn-raised btn-round btn-info"
                data-toggle="modal" data-target="#noticeModal">
                <i class="material-icons">add</i>
                Nouvelle offre de location
              </a>
            </li>
            <li>
              <a href="#">
                <i class="material-icons">add</i>
                Nouvelle offre de vente
              </a>
            </li>

            <li class="divider"></li>
            <li><a href="#">Ajouter un client</a></li>
            <li><a href="#">Ajouter un contact</a></li>
            <li class="divider"></li>
            <li><a href="#">One more separated link</a></li>
          </ul>
        </div>
      </li>
    </ul>

  </div>
</template>

<script>
export default {
}
</script>
