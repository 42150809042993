<template>
    <v-dialog v-model="dialog" persistent max-width="650px">
        <v-card class="mw-dialog">
            <v-toolbar dark color="primary" dense flat >
                <v-toolbar-title class="white--text" >Vitrine</v-toolbar-title>
            </v-toolbar>
            <v-card-text class="row align-center justify-center" v-if="transaction === 'rents'">
                <v-radio-group v-model="printModel" row>
                    <v-radio label="A4 Portrait" value='{"trans": "rents", "orientation" : "p", "format":"A4"}'></v-radio>
                    <v-radio label="A4 Paysage" value='{"trans": "rents", "orientation" : "l", "format":"A4"}'></v-radio>
                </v-radio-group> 
            </v-card-text>
            <v-card-text class="row align-center justify-center" v-else>
                <v-radio-group v-model="printModel" row>
                    <v-radio label="A4 Portrait" value='{"trans": "sales", "orientation" : "p", "format":"A4"}'></v-radio>
                    <v-radio label="A4 Paysage" value='{"trans": "sales", "orientation" : "l", "format":"A4"}'></v-radio>
                    <v-radio label="A4 Paysage (x2)" value='{"trans": "sales", "orientation" : "l2", "format":"A4"}'></v-radio>
                    <v-radio label="A3 Portrait" value='{"trans": "sales", "orientation" : "p", "format":"A3"}'></v-radio>
                </v-radio-group>
            </v-card-text>
            <v-card-actions class="pt-0">
                <v-spacer></v-spacer>
                <v-btn color="primary darken-1" text @click.native="agree">Oui</v-btn>
                <v-btn color="grey" text @click.native="cancel">Non</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    props:['transaction'],
    data:()=>({
        printModel : null,
        dialog: false,
        resolve: null,
        reject: null,
        color: null
    }),
    methods: {
        open(color="primary") {
            this.dialog = true
            this.color = color
            return new Promise((resolve, reject) => {
                this.resolve = resolve
                this.reject = reject
            })
        },
        agree(){
            this.resolve(this.printModel)
            this.dialog = false
        },
        cancel(){
            this.resolve(false)
            this.dialog = false
        }
    }
}
</script>