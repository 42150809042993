<template lang="html">
  <v-tab-item value="descriptif-technique">
    <v-container>
      <div class="row align-center justify-center" >
        <div class="col col-md-2 col-sm-3 col-6">
          <v-text-field label="Raison sociale" filled v-model="offer.NatureChauffage" class="vtext-left"></v-text-field>
        </div>
        <div class="col col-md-2 col-sm-3 col-6">
          <v-text-field label="Enseigne" filled v-model="offer.TypeCuisine" class="vtext-left"></v-text-field>
        </div>
        <div class="col col-md-2 col-sm-3 col-6">
          <v-text-field label="Forme juridique" filled v-model="offer.FormeJuridique" class="vtext-left"></v-text-field>
        </div>
        <div class="col col-md-2 col-sm-3 col-6">
          <v-text-field label="Activité" filled v-model="offer.TypeChauffage" class="vtext-left"></v-text-field>
        </div>
      </div>
      <div class="row align-center justify-center" >
        <div class="col col-md-2 col-sm-4 col-6">
          <lib-v-currency id="F7" label="Capital" v-model="offer.F7" symbol="m2"></lib-v-currency>
        </div>
        <div class="col col-md-2 col-sm-4 col-6">
          <v-text-field label="Date création" filled v-model="offer.DateLiberation" class="vtext-left"></v-text-field>
        </div>
        <div class="col col-md-2 col-sm-4 col-6">
          <lib-v-number label="Nombre employés" filled v-model="offer.I6" ></lib-v-number>
        </div>
      </div>
      <div class="row align-center justify-center" >
        <div class="col col-md-2 col-sm-4 col-6">
          <v-text-field label="Type bail" filled v-model="offer.Standing" class="vtext-left"></v-text-field>
        </div>
        <div class="col col-md-2 col-sm-4 col-6">
          <v-text-field label="Echéance bail" filled v-model="offer.DateConge" class="vtext-left" v-mask="'##/##/####'"></v-text-field>
        </div>
      </div>
      <v-divider></v-divider>
      <div class="row align-center justify-center" >
        <div class="col col-md-2 col-sm-4 col-6">
          <lib-v-currency id="F7" label="Surface totale" v-model="offer.F12" symbol="m2"></lib-v-currency>
        </div>
        <div class="col col-md-2 col-sm-4 col-6">
          <lib-v-currency id="F15" label="Surface annexe" v-model="offer.F14" symbol="m2"></lib-v-currency>
        </div>
        <div class="col col-md-2 col-sm-4 col-6">
          <lib-v-currency id="F7" label="Surface terrain" v-model="offer.F15" symbol="m2"></lib-v-currency>
        </div>
      </div>
      <div class="row align-center justify-center" >
        <div class="col col-md-2 col-sm-4 col-6">
          <lib-v-currency id="F16" label="Surface logement" v-model="offer.F16" symbol="m2"></lib-v-currency>
        </div>
        <div class="col col-md-2 col-sm-4 col-6">
          <lib-v-currency id="F17" label="longueur vitrine" v-model="offer.F17" symbol="m"></lib-v-currency>
        </div>
        <div class="col col-md-2 col-sm-4 col-6">
          <lib-v-number id="NombrePieces" label="Nbr pièces" v-model="offer.NombrePieces"></lib-v-number>
        </div>
      </div>
      <div class="row align-center justify-center" >
        <div class="col col-md-2 col-sm-4 col-6">
          <lib-v-number label="Park. intérieurs" v-model="offer.NombrePkgInter" ></lib-v-number>
        </div>
        <div class="col col-md-2 col-sm-4 col-6">
          <lib-v-number label="Park. extérieurs" v-model="offer.NombrePkgExter" ></lib-v-number>
        </div>
        <div class="col col-md-2 col-sm-4 col-6">
          <lib-v-number label="Box/Garages" v-model="offer.I12" ></lib-v-number>
        </div>
      </div>
      <v-divider></v-divider>
      <div class="row align-center justify-center" >
        <div class="col col-md-2 col-sm-4 col-6">
          <lib-v-currency id="F9" label="Masse salariale" v-model="offer.F9" ></lib-v-currency>
        </div>
        <div class="col col-md-2 col-sm-4 col-6">
          <lib-v-currency id="F25" label="Salaire exploitant" v-model="offer.F25" ></lib-v-currency>
        </div>
      </div>
      <div class="row align-center justify-center" >
        <div class="col col-md-2 col-sm-4 col-6">
          <lib-v-currency id="F26" label="Stock" v-model="offer.F26" ></lib-v-currency>
        </div>
        <div class="col col-md-2 col-sm-4 col-6">
          <lib-v-currency id="F27" label="Cash flow" v-model="offer.F27" ></lib-v-currency>
        </div>
      </div>
      <div class="row align-center justify-center" >
        <div class="col col-md-2 col-sm-4 col-6">
          <lib-v-currency id="F8" label="Taxe professionnelle" v-model="offer.F8" ></lib-v-currency>
        </div>
        <div class="col col-md-2 col-sm-4 col-6">
          <lib-v-currency id="F18" label="Taux taxe" v-model="offer.F18" symbol="%"></lib-v-currency>
        </div>
      </div>
      <div class="row" >
        <v-subheader class="text-uppercase subheader--text">Chiffres d'affaires</v-subheader>
      </div>
      <div class="row align-center justify-center" >
        <div class="col col-md-6 col-sm-8 col-12">
          <table class="table">
            <thead>
              <tr>
                <th width="20%" class="secondary--text">Année</th>
                <th width="45%" class="secondary--text">Chiffre d'affaires</th>
                <th width="30%" class="secondary--text">B.I.C</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td><lib-v-number label="" filled v-model="offer.I7" class="vtext-right" v-mask="'####'" ></lib-v-number></td>
                <td><lib-v-currency id="F19" label="" v-model="offer.F19" ></lib-v-currency></td>
                <td><lib-v-currency id="F22" label="" v-model="offer.F22" ></lib-v-currency></td>
              </tr>
              <tr>
                <td><lib-v-number label="" filled v-model="offer.I8" class="vtext-right" v-mask="'####'" ></lib-v-number></td>
                <td><lib-v-currency id="F20" label="" v-model="offer.F20" ></lib-v-currency></td>
                <td><lib-v-currency id="F23" label="" v-model="offer.F23" ></lib-v-currency></td>
              </tr>
              <tr>
                <td><lib-v-number label="" filled v-model="offer.I9" class="vtext-right" v-mask="'####'" ></lib-v-number></td>
                <td><lib-v-currency id="F21" label="" v-model="offer.F21" ></lib-v-currency></td>
                <td><lib-v-currency id="F24" label="" v-model="offer.F24" ></lib-v-currency></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row" >
        <v-subheader class="text-uppercase subheader--text">Notes client</v-subheader>
      </div>
      <div class="row align-center justify-center" >
        <div class="col col-12" >
          <v-textarea
            v-model="offer.NotesClient" 
            placeholder="Notes client ..."
            auto-grow
            filled
            rows="4"
          ></v-textarea>
        </div> 
      </div>
      <div class="row" >
        <v-subheader class="text-uppercase subheader--text">Informations complémentaires</v-subheader>
      </div>
      <div class="row align-center justify-center" >
        <div class="col col-12" >
          <v-textarea
            v-model="offer.CriteresComplementaires" 
            placeholder="Informations complémentaires ..."
            auto-grow
            filled
            rows="4"
          ></v-textarea>
        </div> 
      </div> 
    </v-container>
  </v-tab-item>
</template>

<script>
import { mask } from 'vue-the-mask'

export default {
  props: ['offer'],
  directives: {
    mask,
  }
}
</script>
