<template lang="html">
  <v-tab-item value="conditions-financieres">
    <v-container>
      <div class="row">
        <p style="opacity:0">{{ CalculTVA }} {{ LoyerHC_TTC }} {{ CalculCharges }} {{ CalculTotalTTC }}</p>
      </div>
      <div class="row">
        <div class="col offset-lg-2 col-lg-4 offset-md-1 col-md-4 col-sm-6 col-12">
          <v-subheader class="subtitle-1 font-weight-medium text-uppercase secondary--text">Loyer H.C.</v-subheader>
          <div class="row">
            <div class="col col-12">
              <v-radio-group v-model.number="offer.I15" row class="radio__group">
                <v-radio :value="0" color="primary" label="Loyer mensuel"></v-radio>
                <v-radio :value="1" color="secondary" label="Loyer trimestriel"></v-radio>
              </v-radio-group>
            </div>
          </div>
          <div class="row">
            <div class="col col-12">
              <!-- offer.F11 -->
              <lib-v-currency id="F1" label="Loyer H.C/H.T" v-model="offer.F1"></lib-v-currency>
            </div>
          </div>
          <div class="row">
            <div class="col col-12">
              <!-- offer.F23 -->
              <lib-v-currency id="F14" label="Taux T.V.A" v-model="offer.F14" symbol="%"></lib-v-currency>
            </div>
          </div>
          <div class="row">
            <div class="col col-12">
              <!-- offer.F23 -->
              <lib-v-currency id="F5" label="T.V.A" v-model="offer.F5"></lib-v-currency>
            </div>
          </div>
          <div class="row">
            <div class="col col-12">
              <!-- offer.Price -->
              <lib-v-currency id="Price" label="Loyer H.C/T.T.C" v-model="offer.Price" :disabled="true"></lib-v-currency>
            </div>
          </div>
        </div>
        <div class="col col-lg-4 col-md-5 col-sm-6 col-12">
          <v-subheader class="subtitle-1 font-weight-medium text-uppercase secondary--text">Charges</v-subheader>
          <div class="row">
            <div class="col col-12">
              <v-radio-group v-model.number="offer.I28" row class="radio__group">
                <v-radio :value="0" color="primary" label="Charges mensuelles"></v-radio>
                <v-radio :value="1" color="secondary" label="Charges trimestrielles"></v-radio>
              </v-radio-group>
            </div>
          </div>
          <div class="row">
            <div class="col col-12">
              <!-- offer.F3 -->
              <lib-v-currency id="F3" label="Provisions charges" v-model="offer.F3" ></lib-v-currency>
            </div>
          </div>
          <div class="row">
            <div class="col col-12">
              <!-- offer.F10 -->
              <lib-v-currency id="F10" label="Dépôt de garantie" v-model="offer.F10"></lib-v-currency>
            </div>
          </div>
          <div class="row">
            <div class="col col-12">
              <!-- offer.F23 -->
              <lib-v-currency id="F8" label="Caution pour BIP" v-model="offer.F8"></lib-v-currency>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-center align-center">
        <v-subheader class="subtitle-1 font-weight-medium text-uppercase secondary--text">Honoraires locataire</v-subheader>
      </div>
      <div class="row justify-center align-center">
        <div class="col col-lg-4 col-md-5 col-sm-6 col-12">
          <!-- offer.F11 -->
          <lib-v-currency id="F11" label="Loyer T.T.C" v-model="offer.F11" :disabled="true"></lib-v-currency>
        </div>
      </div>
      <div class="row justify-center align-center">
        <div class="col col-lg-4 col-md-5 col-sm-6 col-12">
          <!-- offer.F13 -->
          <lib-v-currency id="F13" label="Rédaction acte T.T.C" v-model="offer.F13"></lib-v-currency>
        </div>
      </div>
      <div class="row justify-center align-center">
        <div class="col col-lg-4 col-md-5 col-sm-6 col-12">
          <!-- offer.F25 -->
          <lib-v-currency id="F25" label="Total T.T.C" v-model="offer.F25" :disabled="true"></lib-v-currency>
        </div>
      </div>
      </div>
    </v-container>
  </v-tab-item>
</template>

<script>
import { currencyValidator } from '@/utils/utils'

export default {
  props: ['offer'],
  data () {
    return {
      LoyerTTC: 0,
      TotalTTC: 0,
      I15: this.offer.I15
    }
  },
  computed: {
    CalculTVA () {
      this.offer.F5 = 1 * Number.parseFloat((1 * this.offer.F1 * this.offer.F14) / 100).toFixed(2);
      return this.offer.F5
    },
    LoyerHC_TTC () {
      this.offer.Price = 1 * Number.parseFloat(1 * this.offer.F1 + 1 * this.offer.F5).toFixed(2);
      return this.offer.Price
    },
    CalculCharges () {
      this.offer.F11 = 1 * Number.parseFloat(1 * this.offer.Price + 1 * this.offer.F3).toFixed(2);
      return this.offer.F11
    },
    CalculTotalTTC () {
      this.offer.F25 = 1 * Number.parseFloat(1 * this.offer.F13 + 1 * this.offer.F11).toFixed(2);
      return this.offer.F25
    }
  }
}
</script>
