<template lang="html">
  <v-expansion-panel>
      <v-expansion-panel-header>
        <template v-slot:default="{ open }">
          <v-subheader class="subtitle-1 text-uppercase primary--text" v-if="open">Conditions financières</v-subheader>
          <v-subheader class="subtitle-1 text-uppercase" v-else>Conditions financières</v-subheader>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-container>
          <div class="row align-center justify-center">
            <div class="ma-4">
              <v-icon class="success--text" v-if="offer.I29 === 0">mdi-radiobox-marked</v-icon>
              <v-icon v-else>mdi-radiobox-blank</v-icon>
              Charge honoraires vendeur
            </div>
            <div class="ma-4">
              <v-icon class="success--text" v-if="offer.I29 === 1">mdi-radiobox-marked</v-icon>
              <v-icon v-else>mdi-radiobox-blank</v-icon>
              Charge honoraires acquéreur
            </div>
          </div>
          <div class="row">
            <div class="col-6 col-md-3">
              <p class="text-right">Prix vente:</p>
            </div>
            <div class="col-6 col-md-2">
              <p class="value tertiary text-right">{{offer.F32.toLocaleString()}} €</p>
            </div>
            <div class="col-6 col-md-3">
              <p class="text-right">Prix vente FAI:</p>
            </div>
            <div class="col-6 col-md-2">
              <p class="value tertiary text-right error--text">{{offer.Price.toLocaleString()}} €</p>
            </div>
          </div>
          <div class="row">
            <div class="col-6 col-md-3">
              <p class="text-right">Honoraires TTC :</p>
            </div>
            <div class="col-6 col-md-2">
              <p class="value tertiary text-right">{{offer.F3.toLocaleString()}} €</p>
            </div>
            <div class="col-6 col-md-3">
              <p class="text-right">% honoraires:</p>
            </div>
            <div class="col-6 col-md-2">
              <p class="value tertiary text-right">{{offer.TauxHonoraires.toLocaleString()}} %</p>
            </div>
          </div>
          <div class="row">
            <div class="col-6 col-md-3">
              <p class="text-right">Net vendeur:</p>
            </div>
            <div class="col-6 col-md-2">
              <p class="value tertiary text-right">{{offer.F2.toLocaleString()}} €</p>
            </div>
            <div class="col-6 col-md-3">
              <p class="text-right">Prix négociable:</p>
            </div>
            <div class="col-6 col-md-2">
              <p class="value tertiary text-right">{{offer.F6.toLocaleString()}} €</p>
            </div>
          </div>
          <div class="row">
            <div class="col-6 col-md-3">
              <p class="text-right">Prix estimé (min):</p>
            </div>
            <div class="col-6 col-md-2">
              <p class="value tertiary text-right">{{offer.F5.toLocaleString()}} €</p>
            </div>
            <div class="col-6 col-md-3">
              <p class="text-right">Prix estimé (max):</p>
            </div>
            <div class="col-6 col-md-2">
              <p class="value tertiary text-right">{{offer.F35.toLocaleString()}} €</p>
            </div>
          </div>
          <div class="row">
            <div class="col-6 col-md-3">
              <p class="text-right">Prix de départ:</p>
            </div>
            <div class="col-6 col-md-2">
              <p class="value tertiary text-right">{{offer.F13.toLocaleString()}} €</p>
            </div>
            <div class="col-6 col-md-3">
              <p class="text-right">Date modif. prix:</p>
            </div>
            <div class="col-6 col-md-2">
              <p class="value tertiary">{{offer.DateLoyer}}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-6 col-md-3">
              <p class="text-right">Travaux:</p>
            </div>
            <div class="col-6 col-md-2">
              <p class="value tertiary text-right">{{offer.F7.toLocaleString()}} €</p>
            </div>
            <div class="col-6 col-md-3">
              <p class="text-right">Taxe habitation:</p>
            </div>
            <div class="col-6 col-md-2">
              <p class="value tertiary text-right">{{offer.F8.toLocaleString()}} €</p>
            </div>
          </div>
          <div class="row">
            <div class="col-6 col-md-3">
              <p class="text-right">Charges annuelles:</p>
            </div>
            <div class="col-6 col-md-2">
              <p class="value tertiary text-right">{{offer.F10.toLocaleString()}} €</p>
            </div>
            <div class="col-6 col-md-3">
              <p class="text-right">Taxe foncière:</p>
            </div>
            <div class="col-6 col-md-2">
              <p class="value tertiary text-right">{{offer.F9.toLocaleString()}} €</p>
            </div>
          </div>
          <div class="row">
            <div class="col-6 col-md-3">
              <p class="text-right">Nom Apporteur:</p>
            </div>
            <div class="col-6 col-md-2">
              <p class="value tertiary text-right">{{offer.S67}}</p>
            </div>
            <div class="col-6 col-md-3">
              <p class="text-right">Taux Apporteur:</p>
            </div>
            <div class="col-6 col-md-2">
              <p class="value tertiary text-right">{{offer.F28.toLocaleString()}} %</p>
            </div>
          </div>
          <div class="row">
            <v-subheader class="text-uppercase subheader--text">Fiscalité</v-subheader>
          </div>
          <div class="row">
            <div class="col-6 col-md-3">
              <p class="text-right">Régime fiscal :</p>
            </div>
            <div class="col-6 col-md-3">
              <p class="value tertiary">{{ offer.S29}}</p>
            </div>
            <div class="col-6 col-md-2">
              <p class="text-right">Forme juridique :</p>
            </div>
            <div class="col-6 col-md-3">
              <p class="value tertiary">{{ offer.FormeJuridique}}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-6 col-md-3">
              <p class="text-right">Taxe profess. :</p>
            </div>
            <div class="col-6 col-md-3">
              <p class="value tertiary" md="2">{{ offer.F16}}%</p>
            </div>
            <div class="col-6 col-md-2">
              <p class="text-right">Taxe bureaux :</p>
            </div>
            <div class="col-6 col-md-2">
              <p class="value tertiary text-right">{{offer.F8.toLocaleString()}} €/m<sup>2</sup>/an</p>
            </div>
            <div class="col-6 col-md-3">
              <p class="text-right">Taxe foncière :</p>
            </div>
            <div class="col-6 col-md-3">
              <p class="value tertiary text-right">{{offer.F9.toLocaleString()}} €/m<sup>2</sup>/an</p>
            </div>
          </div>
        </v-container>
      </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>

export default {
  props: ['offer']
}
</script>
