<template lang="html">
  <v-tab-item value="conditions-financieres">
    <v-container>
      <div class="row">
        <p style="opacity:0">{{ LocataireTotalTTC }} {{ ProprietaireTotalTTC }} {{ TotalTTC }}</p>
      </div>
      <div class="row justify-center align-center">
        <div class="col-6 col-md-4">
          <!-- offer.F1 -->
          <lib-v-currency id="F1" label="Loyer mensuel H.C" v-model="offer.F1" ></lib-v-currency>
        </div>
        <div class="col-6 col-md-4">
          <!-- offer.Price -->
          <lib-v-currency id="Price" label="Loyer mensuel C.C" v-model="offer.Price"></lib-v-currency>
        </div>
        <div class="col-6 col-md-4">
          <!-- offer.F10 -->
          <lib-v-currency id="F10" label="Dépôt de garantie" v-model="offer.F10" ></lib-v-currency>
        </div>
        <div class="col-6 col-md-4">

          <!-- offer.F9 -->
          <lib-v-currency id="F9" label="Taxe habitation" v-model="offer.F9" ></lib-v-currency>
        </div>
        <div class="col-6 col-md-4">

          <!-- offer.F3 -->
          <lib-v-currency id="F3" label="Provision charges" v-model="offer.F3"></lib-v-currency>
        </div>
        <div class="col-6 col-md-4">
          <!-- offer.F2 -->
          <lib-v-currency id="F2" label="Garage / Parking" v-model="offer.F2" ></lib-v-currency>
        </div>
      </div>
      <div class="col-6 col-md-4">
        <!-- offer.F6 -->
        <lib-v-currency id="F6" label="Divers" v-model="offer.F6"></lib-v-currency>
      </div>
      <div class="row ">
        <div class="col col-12 col-sm-6 ">
          <div class="text-center subtitle-1 text-uppercase secondary--text"><strong>Honoraire locataire</strong></div>
          <div class="row align-center justfiy-center">
            <div class="col-6 offset-sm-2 col-sm-8">
              <!-- offer.F11 -->
              <lib-v-currency id="F11" label="Rédaction acte TTC" v-model="offer.F11"></lib-v-currency>
            </div>
            <div class="col-6 offset-sm-2 col-sm-8">
              <!-- offer.F21 -->
              <lib-v-currency id="F21" label="Etats des lieux TTC" v-model="offer.F21" ></lib-v-currency>
            </div>
            <div class="col-6 offset-sm-2 col-sm-8">
              <!-- offer.F23 -->
              <lib-v-currency id="F23" label="Frais divers TTC" v-model="offer.F23" ></lib-v-currency>
            </div>
          </div>
          <div class="row justify-center">
            <div class="col col-6 col-sm-8">
              <!-- offer.F25 -->
              <lib-v-currency id="F25" label="Total TTC" v-model="offer.F25" :disabled="true"></lib-v-currency>
            </div>
          </div>
        </div>
        <div class="col col-12 col-sm-6">
          <div class="text-center subtitle-1 text-uppercase secondary--text"><strong>Honoraire Propriétaire</strong></div>
          <div class="row">
            <div class="col col-6 col-sm-8">
              <!-- offer.F13 -->
              <lib-v-currency id="F13" label="Rédaction acte TTC" v-model="offer.F13"></lib-v-currency>
            </div>
            <div class="col col-6 col-sm-8">
              <!-- offer.F22 -->
              <lib-v-currency id="F22" label="Etats des lieux TTC" v-model="offer.F22" ></lib-v-currency>
            </div>
            <div class="col-6 col-sm-8">
              <!-- offer.F24 -->
              <lib-v-currency id="F24" label="Frais divers TTC" v-model="offer.F24" ></lib-v-currency>
            </div>
          </div>
          <div class="row justify-center">
            <div class="col col-6 col-sm-8">
              <!-- offer.F26 -->
              <lib-v-currency id="F26" label="Total TTC" v-model="offer.F26" :disabled="true"></lib-v-currency>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-center align-center">
        <div class="col col-md-2 col-6">
          <!-- offer.F32 -->
          <lib-v-currency id="F32" label="Total honoraire (TTC)" v-model="offer.F32" :disabled="true"></lib-v-currency>
        </div>
      </div>
      <div class="row justify-center align-top">
        <div class="col col-6">
            <v-checkbox name="EncadrementLoyer" v-model="offer.EncadrementLoyer" label="Soumis à l'encadrement des Loyers" :true-value="1" :false-value="0" class="mx-2"></v-checkbox>
            <div class="row justify-start align-center" v-if="offer.EncadrementLoyer === 1">
              <div class="col col-6">  
                <lib-v-number  id="LoyerMoyenReference" label="Loyer moyen de référence" v-model="offer.LoyerMoyenReference" symbol="€/m2"></lib-v-number >
                <lib-v-number  id="LoyerReferenceMajore" label="Loyer de référence majoré" v-model="offer.LoyerReferenceMajore" symbol="€/m2"></lib-v-number >
                <lib-v-currency id="ComplementLoyer" label="Complément de loyer" v-model="offer.ComplementLoyer"></lib-v-currency>
              </div>
            </div>
        </div>
        <div class="col col-4 justify-top">
            <v-checkbox name="PermisLouer" v-model.trim="offer.PermisLouer" label="Soumis au permis de louer" :true-value="1" :false-value="0" class="mx-2"></v-checkbox>
        </div>
      </div>
    </v-container>
  </v-tab-item>
</template>

<script>

export default {
  props: ['offer'],
  computed: {
    LocataireTotalTTC: function () {
      this.offer.F25 = 1 * this.offer.F11 + 1 * this.offer.F21 + this.offer.F23
      this.offer.F13 = this.offer.F11
      this.offer.F22 = this.offer.F21
      this.offer.F24 = this.offer.F23
      return this.offer.F25.toLocaleString()
    },
    ProprietaireTotalTTC: function () {
      this.offer.F26 = 1 * this.offer.F13 + 1 * this.offer.F22 + 1 * this.offer.F24
      return this.offer.F26.toLocaleString()
    },
    TotalTTC: function () {
      this.offer.F32 = 1 * this.offer.F25 + 1 * this.offer.F26
      return this.offer.F32.toLocaleString()
    }
  }
}
</script>
