<template lang="html">
    <v-expansion-panel>
      <v-expansion-panel-header>
        <template v-slot:default="{ open }">
          <v-subheader class="subtitle-1 text-uppercase primary--text" v-if="open">Conditions financières</v-subheader>
          <v-subheader class="subtitle-1 text-uppercase" v-else>Conditions financières</v-subheader>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-container>
          <div class="row">
            <div class="col-6 col-sm-4 col-md-3 pl-0">
              <p class="text-right">Loyer mensuel H.C.:</p>
            </div>
            <div class="col-6 col-sm-2 col-md-2">
              <p class="value text-right tertiary">{{offer.F1.toLocaleString()}} €</p>
            </div>
              
            <div class="col-6 col-sm-4 col-md-3 pl-0">
              <p class="text-right">Loyer mensuel C.C.:</p>
            </div>
            <div class="col-6 col-sm-2 col-md-2">
              <p class="value text-right tertiary error--text">{{offer.Price.toLocaleString()}} €</p>
            </div>
          </div>
          <div class="row">
            <div class="col-6 col-sm-4 col-md-3">
              <p class="text-right">Dépôt de garantie:</p>
            </div>
            <div class="col-6 col-sm-2 col-md-2">
              <p class="value text-right tertiary">{{offer.F10.toLocaleString()}} €</p>
            </div>
            <div class="col-6 col-sm-4 col-md-3">
              <p class="text-right">Taxe habitation:</p>
            </div>
            <div class="col-6 col-sm-2 col-md-2">
              <p class="value text-right tertiary">{{offer.F9.toLocaleString()}} €</p>
            </div>
          </div>
          <div class="row">
            <div class="col-6 col-sm-4 col-md-3">
              <p class="text-right">Provision charges:</p>
            </div>
            <div class="col-6 col-sm-2 col-md-2">
              <p class="value text-right tertiary">{{offer.F3.toLocaleString()}} €</p>
            </div>
            <div class="col-6 col-sm-4 col-md-3">
              <p class="text-right">Garage / Parking:</p>
            </div>
            <div class="col-6 col-sm-2 col-md-2">
              <p class="value text-right tertiary">{{offer.F2.toLocaleString()}} €</p>
            </div>
          </div>
          <div class="row">
            <div class="col-6 col-sm-4 col-md-3">
              <p class="text-right">Divers:</p>
            </div>
            <div class="col-6 col-sm-2 col-md-2">
              <p class="value text-right tertiary">{{offer.F6.toLocaleString()}} €</p>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-12 col-md-6">
              <v-subheader class="subtitle-1 text-uppercase text-center secondary--text">Honoraire locataire TTC</v-subheader>
              <div class="row">
                <div class="col-6">
                  <p class="text-right">Rédaction acte:</p>
                </div>
                <div class="col-6 col-md-4">
                  <p class="value text-right tertiary">{{offer.F11.toLocaleString()}} €</p>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <p class="text-right">Etats des lieux:</p>
                </div>
                <div class="col-6 col-md-4">
                  <p class="value text-right tertiary">{{offer.F21.toLocaleString()}} €</p>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <p class="text-right">Frais divers:</p>
                </div>
                <div class="col-6 col-md-4">
                  <p class="value text-right tertiary">{{offer.F23.toLocaleString()}} €</p>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <p class="text-right"><strong>Total:</strong></p>
                </div>
                <div class="col-6 col-md-4">
                  <p class="value number error--text text-right">{{offer.F25.toLocaleString()}} €</p>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <v-subheader class="subtitle-1 text-uppercase text-center secondary--text">Honoraire propriétaire TTC</v-subheader>
              <div class="row">
                <div class="col-6 col-md-4">
                  <p class="text-right">Rédaction acte:</p>
                </div>
                <div class="col-6 col-md-4">
                  <p class="value text-right tertiary">{{offer.F13.toLocaleString()}} €</p>
                </div>
              </div>
              <div class="row">
                <div class="col-6 col-md-4">
                  <p class="text-right">Etats des lieux:</p>
                </div>
                <div class="col-6 col-md-4">
                  <p class="value text-right tertiary">{{offer.F22.toLocaleString()}} €</p>
                </div>
              </div>
              <div class="row">
                <div class="col-6 col-md-4">
                  <p class="text-right">Frais divers:</p>
                </div>
                <div class="col-6 col-md-4">
                  <p class="value text-right tertiary">{{offer.F24.toLocaleString()}} €</p>
                </div>
              </div>
              <div class="row">
                <div class="col-6 col-md-4">
                  <p class="text-right"><strong>Total:</strong></p>
                </div>
                <div class="col-6 col-md-4">
                  <p class="value number error--text text-right">{{offer.F26.toLocaleString()}} €</p>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-12">
              <div class="text-center subtitle-1">
                <strong>Total honoraire TTC : <span class="success--text">{{ TotalTTC.toLocaleString({maximumSignificantDigits: 2}) }} €</span></strong>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="ma-3">
                <v-icon v-if="offer.EncadrementLoyer > 0">mdi-checkbox-marked-outline</v-icon>
                <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
                  Soumis à l'encadrement des Loyers
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="ma-3">
                <v-icon v-if="offer.PermisLouer > 0">mdi-checkbox-marked-outline</v-icon>
                <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
                  Soumis au permis de louer
              </div>
            </div>
          </div>
          <div class="row" v-if="offer.EncadrementLoyer > 0">
            <div class="col-12 col-md-6">
              <div class="row">
                <div class="col-6 col-md-4">
                  <p class="text-right">Loyer moyen de référence:</p>
                </div>
                <div class="col-6 col-md-4">
                  <p class="value text-right tertiary">{{offer.LoyerMoyenReference.toLocaleString()}} €/m2</p>
                </div>
              </div>
              <div class="row">
                <div class="col-6 col-md-4">
                  <p class="text-right">Loyer de référence majoré:</p>
                </div>
                <div class="col-6 col-md-4">
                  <p class="value text-right tertiary">{{offer.LoyerReferenceMajore.toLocaleString()}} €/m2</p>
                </div>
              </div>
              <div class="row">
                <div class="col-6 col-md-4">
                  <p class="text-right">Complément de loyer:</p>
                </div>
                <div class="col-6 col-md-4">
                  <p class="value text-right tertiary">{{offer.ComplementLoyer.toLocaleString()}} €/m2</p>
                </div>
              </div>
            </div>
          </div>
        </v-container>
      </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
export default {
  props: ['offer'],
  computed: {
    TotalTTC: function () {
      return (1 * this.offer.F25 + 1 * this.offer.F26)
    }
  }
}
</script>
