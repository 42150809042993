<template>
    <v-snackbar
      v-model="snackbar"
      :bottom="y === 'bottom'"
      :color="color"
      :left="x === 'left'"
      :multi-line="mode === 'multi-line'"
      :right="x === 'right'"
      :timeout="timeout"
      :top="y === 'top'"
      :vertical="mode === 'vertical'"
    >
      {{ text }}
      <v-btn
        dark
        text
        @click="snackbar = false"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
</template>
<script>
export default {
  data: () => ({
    color: '',
    mode: '',
    snackbar: false,
    text: 'immorep - Logiciel de gestion immobilière.',
    timeout: 3000,
    x: null,
    y: 'top',
  }),
  methods: {
    show(message, color, timeout=3000) {
        this.snackbar = true
        this.text = message
        this.color = color
        this.timeout = timeout
    }
  }
}
</script>